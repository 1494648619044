import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import styled from "styled-components";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { RiAccountCircleFill } from "react-icons/ri";
import { IoLogOutOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { auth } from "../../../firebase";
import { SELECTED_BUSINESS_ID } from "../../../utils/globalConstants";
import { setSelectedBusiness } from "../../../context/reducers/layout";
import { useDispatch } from "react-redux";

export default function UserInfo() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const user = getAuth().currentUser;
  const dispatch = useDispatch();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToAccountPage = () => {
    navigate({
      pathname: "/my-account",
    });
    handleClose();
  };

  const handleLogout = async () => {
    handleClose();
    try {
      await signOut(auth).then(() => {
        localStorage.removeItem(SELECTED_BUSINESS_ID);
        dispatch(
          setSelectedBusiness({
            selectedBusiness: {
              id: "",
              ownerId: "",
              ownerName: "",
              url: "",
            },
          })
        );
      });
    } catch (err) {}
  };

  const getDisplayName = () => {
    const displayName = user?.displayName;
    return displayName || "";
  };

  return (
    <>
      <Container onClick={(event: any) => handleClick(event)}>
        <Avatar>
          <AvatarText>{user?.email?.charAt(0)?.toUpperCase()}</AvatarText>
        </Avatar>
        <InformationContainer>
          <Email>{user?.email}</Email>
          <Name>{getDisplayName()}</Name>
        </InformationContainer>
        <FaChevronDown style={{ color: "black", fontSize: "12px" }} />
      </Container>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <StyledMenuItem onClick={goToAccountPage}>
          <RiAccountCircleFill />
          <MenuItemText>Ver mi cuenta</MenuItemText>
        </StyledMenuItem>
        <StyledMenuItem onClick={handleLogout}>
          <IoLogOutOutline />
          <MenuItemText>Cerrar sesión</MenuItemText>
        </StyledMenuItem>
      </Menu>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-right: 15px;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  :hover {
    background-color: rgba(155, 155, 155, 0.2);
    border-radius: 12px;
  }
`;

const Avatar = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #00a680;
`;

const AvatarText = styled.p`
  color: white;
`;

const InformationContainer = styled.div``;

const Email = styled.p`
  font-weight: 500;
  font-size: 12px;
`;

const Name = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: #9c9c9c;
`;

const StyledMenuItem = styled(MenuItem)`
  width: 200px;
`;

const MenuItemText = styled.p`
  font-size: 13px;
  font-weight: 400;
  &&& {
    font-family: "Montserrat";
    margin-left: 6px;
  }
`;
