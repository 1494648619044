import { createSlice } from "@reduxjs/toolkit";

interface InitialStateI {
  expenses: any[] | null;
  canLoadMore: boolean;
  isLoadingMoreData: boolean;
  lastExpense: any | null;
  isCreating: boolean;
}

const initialState: InitialStateI = {
  expenses: null,
  canLoadMore: true,
  isLoadingMoreData: false,
  lastExpense: null,
  isCreating: false,
};

const expensesSlice = createSlice({
  name: "expenses",
  initialState,
  reducers: {
    setExpenses: (state, action) => {
      state.expenses = action.payload.expenses;
    },
    setCanLoadMore: (state, action) => {
      state.canLoadMore = action.payload.canLoadMore;
    },
    setIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
    setIsCreating: (state, action) => {
      state.isCreating = action.payload.isCreating;
    },
    setLastExpense: (state, action) => {
      state.lastExpense = action.payload.lastExpense;
    },
  },
});

export const {
  setCanLoadMore,
  setExpenses,
  setIsCreating,
  setIsLoadingMoreData,
  setLastExpense,
} = expensesSlice.actions;
export default expensesSlice.reducer;
