import { createSlice } from "@reduxjs/toolkit";

interface InitialStateI {
  purchasesPayable: any[] | null;
  canLoadMore: boolean;
  isLoadingMoreData: boolean;
  lastPurchasePayable: any | null;
  isCreating: boolean;
}

const initialState: InitialStateI = {
  purchasesPayable: null,
  canLoadMore: true,
  isLoadingMoreData: false,
  lastPurchasePayable: null,
  isCreating: false,
};

const purchasesPayableSlice = createSlice({
  name: "purchasesPayable",
  initialState,
  reducers: {
    setPurchasesPayable: (state, action) => {
      state.purchasesPayable = action.payload.purchasesPayable;
    },
    setLastPurchasePayable: (state, action) => {
      state.lastPurchasePayable = action.payload.lastPurchasePayable;
    },
    setCanLoadMore: (state, action) => {
      state.canLoadMore = action.payload.canLoadMore;
    },
    setIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
    setIsCreating: (state, action) => {
      state.isCreating = action.payload.isCreating;
    },
  },
});

export const {
  setCanLoadMore,
  setIsCreating,
  setIsLoadingMoreData,
  setLastPurchasePayable,
  setPurchasesPayable,
} = purchasesPayableSlice.actions;
export default purchasesPayableSlice.reducer;
