import { createSlice } from "@reduxjs/toolkit";

interface InitialStateI {
  categories: any[] | null;
  canLoadMore: boolean;
  isLoadingMoreData: boolean;
  lastCategory: any | null;
  isNewCategoryModalVisible: boolean;
  isCreating: boolean;
}

const initialState: InitialStateI = {
  categories: null,
  canLoadMore: true,
  isLoadingMoreData: false,
  lastCategory: null,
  isNewCategoryModalVisible: false,
  isCreating: false,
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload.categories;
    },
    setLastCategory: (state, action) => {
      state.lastCategory = action.payload.lastCategory;
    },
    setCanLoadMore: (state, action) => {
      state.canLoadMore = action.payload.canLoadMore;
    },
    setIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
    setIsNewCategoryModalVisible: (state, action) => {
      state.isNewCategoryModalVisible =
        action.payload.isNewCategoryModalVisible;
    },
    setIsCreating: (state, action) => {
      state.isCreating = action.payload.isCreating;
    },
  },
});

export const {
  setCanLoadMore,
  setCategories,
  setIsLoadingMoreData,
  setLastCategory,
  setIsNewCategoryModalVisible,
  setIsCreating,
} = categoriesSlice.actions;
export default categoriesSlice.reducer;
