import React, { useState } from "react";
import Close from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import styled from "styled-components";
import TopTab from "./businessLinkModal/TopTab";
import DefaultLink from "./businessLinkModal/DefaultLink";
import CustomDomain from "./businessLinkModal/CustomDomain";

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const DEFAULT_LINK = "DEFAULT_LINK";
const CUSTOM_DOMAIN = "CUSTOM_DOMAIN";
const DOMAIN = "https://ganvent.shop/";

export default function BusinessLinkModal({ isVisible, setIsVisible }: Props) {
  const [selectedTab, setSelectedTab] = useState<string>(DEFAULT_LINK);

  const closeModal = () => {
    setIsVisible(false);
  };

  return (
    <CustomDrawer
      open={isVisible}
      onClose={closeModal}
      anchor="right"
      variant="temporary"
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Container>
        <Header>
          <Title>Selecciona una opción</Title>
          <IconButton onClick={closeModal}>
            <Close />
          </IconButton>
        </Header>
        <TopTab selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        {selectedTab === DEFAULT_LINK ? <DefaultLink /> : <CustomDomain />}
      </Container>
    </CustomDrawer>
  );
}

const CustomDrawer = styled(Drawer)``;

const Container = styled.div`
  width: 400px;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 18px;
  padding-top: 18px;
  padding-right: 10px;
`;

const Title = styled.p`
  flex: 1;
  font-weight: 600;
`;
