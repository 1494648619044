import { createSlice } from "@reduxjs/toolkit";

interface InitialStateI {
  clients: any[] | null;
  canLoadMore: boolean;
  isLoadingMoreData: boolean;
  lastClient: any | null;
  isCreating: boolean;
}

const initialState: InitialStateI = {
  clients: null,
  canLoadMore: true,
  isLoadingMoreData: false,
  lastClient: null,
  isCreating: false,
};

const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setClients: (state, action) => {
      state.clients = action.payload.clients;
    },
    setLastClient: (state, action) => {
      state.lastClient = action.payload.lastClient;
    },
    setCanLoadMore: (state, action) => {
      state.canLoadMore = action.payload.canLoadMore;
    },
    setIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
    setIsCreating: (state, action) => {
      state.isCreating = action.payload.isCreating;
    },
  },
});

export const {
  setCanLoadMore,
  setClients,
  setIsCreating,
  setIsLoadingMoreData,
  setLastClient,
} = clientsSlice.actions;
export default clientsSlice.reducer;
