import { useState } from "react";
import styled from "styled-components";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../context/store";
import { collection, db, doc, updateDoc } from "../../../../firebase";
import {
  setGeneralSnackBarMessage,
  setShowGeneralSnackbar,
} from "../../../../context/reducers/layout";

interface Props {
  title: string;
}

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({}) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#c1c1c1",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
  },
}));

export default function ToggleLink({ title }: Props) {
  const selectedBusiness = useSelector(
    (state: RootState) => state.layout.selectedBusiness
  );
  const [updating, setUpdating] = useState(false);
  const getIsDomainActive = () => {
    return selectedBusiness?.isDomainActive === undefined
      ? true
      : !selectedBusiness?.isDomainActive
      ? true
      : false;
  };

  const [isDefaultLinkActive, setIsDefaultLinkActive] = useState(
    getIsDomainActive()
  );
  const dispatch = useDispatch();

  const updateDomainActiveInFirestore = (newValue: boolean) => {
    const businessCollection = collection(db, "business");

    const businessDoc = doc(db, "business", selectedBusiness?.id);

    updateDoc(businessDoc, {
      isDomainActive: newValue,
    })
      .then(() => {
        setUpdating(false);
        dispatch(
          setShowGeneralSnackbar({
            showSnackbar: true,
          })
        );
        dispatch(
          setGeneralSnackBarMessage({
            snackBarMessage: "Actualizado correctamente",
          })
        );
        setIsDefaultLinkActive(!newValue);
      })
      .catch(() => {
        setUpdating(false);
        dispatch(
          setShowGeneralSnackbar({
            showSnackbar: true,
          })
        );
        dispatch(
          setGeneralSnackBarMessage({
            snackBarMessage: "Ocurrió un error",
          })
        );
      });
  };

  const handleChange = () => {
    let currentValue = isDefaultLinkActive;
    setUpdating(true);
    updateDomainActiveInFirestore(currentValue);
  };

  return (
    <Container>
      <Title>{title}</Title>
      <IOSSwitch
        checked={isDefaultLinkActive == undefined ? false : isDefaultLinkActive}
        onChange={handleChange}
        style={{ color: "#00a680" }}
        disabled={updating}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid #c1c1c1;
  border-bottom: 1px solid #c1c1c1;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 18px;
`;

const Title = styled.p`
  flex: 1;
  margin-right: 20px;
  font-size: 13px;
`;
