import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IoLinkOutline } from "react-icons/io5";
import StepBar from "./StepBar";
import InputWithLabel from "../login/InputWithLabel";
import { Button, CircularProgress } from "@mui/material";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ganvent_urls from "../../utils/ganvent_urls";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { getAuth } from "firebase/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedBusiness } from "../../context/reducers/layout";
import { SELECTED_BUSINESS_ID } from "../../utils/globalConstants";

const CATEGORY_NOT_SELECTED = "category not selected";

interface Props {
  stepNumber: number;
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  formError: any;
  setFormError: React.Dispatch<React.SetStateAction<any>>;
  currencyType: string;
  currencyTypeSymbol: string;
  isVisible: boolean;
}

export default function Step4({
  stepNumber,
  formData,
  setFormData,
  currentStep,
  setCurrentStep,
  formError,
  setFormError,
  currencyType,
  currencyTypeSymbol,
  isVisible,
}: Props) {
  const [newUrl, setNewUrl] = useState("");
  const [creating, setCreating] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const currentUser = getAuth().currentUser;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setFormData({
      ...formData,
      url: newUrl?.trim(),
    });
  }, [newUrl]);

  const goBack = () => {
    setCurrentStep(2);
  };

  const stringHasWhiteSpaceOrNot = (textValue: string): boolean => {
    return textValue?.indexOf(" ") >= 0;
  };

  const createNewBusiness = async () => {
    setShowSnackbar(true);
    setSnackBarMessage("Creando negocio");
    const businessesCollection = collection(db, "business");
    addDoc(businessesCollection, {
      userId: currentUser?.uid,
      images: [],
      owner: formData?.owner?.toLowerCase().trim(),
      name: {
        local: formData?.business.trim(),
      },
      shopName: formData?.business.trim(),
      searchShopName: formData?.business?.toLowerCase()?.trim(),
      description: "",
      email: "",
      country: "",
      town: "",
      direction: "",
      lat: "",
      lng: "",
      shop: true,
      url: formData?.url?.toLowerCase()?.trim(),
      phone: {
        number: formData?.phoneNumber,
        code: formData?.callingCode,
        cca2: formData?.cca2,
      },
      mainCurrency: {
        currencyType: currencyType,
        symbol: currencyTypeSymbol,
      },
      type:
        formData?.selectedCategory !== CATEGORY_NOT_SELECTED
          ? formData?.selectedCategory
          : "",
    })
      .then(async (docRef) => {
        const newBusiness = {
          id: docRef.id,
          userId: currentUser?.uid,
          images: [],
          owner: formData?.owner?.toLowerCase().trim(),
          name: {
            local: formData?.business.trim(),
          },
          shopName: formData?.business.trim(),
          searchShopName: formData?.business?.toLowerCase()?.trim(),
          description: "",
          email: "",
          country: "",
          town: "",
          direction: "",
          lat: "",
          lng: "",
          shop: true,
          url: formData?.url?.toLowerCase()?.trim(),
          phone: {
            number: formData?.phoneNumber,
            code: formData?.callingCode,
            cca2: formData?.cca2,
          },
          mainCurrency: {
            currencyType: currencyType,
            symbol: currencyTypeSymbol,
          },
          type:
            formData?.selectedCategory !== CATEGORY_NOT_SELECTED
              ? formData?.selectedCategory
              : "",
        };
        setShowSnackbar(true);
        setSnackBarMessage("Espere un momento...");
        dispatch(
          setSelectedBusiness({
            selectedBusiness: newBusiness,
          })
        );
        localStorage.setItem(SELECTED_BUSINESS_ID, docRef?.id);
        navigate({
          pathname: "/",
        });
      })
      .catch((err) => {
        setCreating(false);
        setShowSnackbar(true);
        setSnackBarMessage("Ocurrió un error al intentar crear el negocio");
      });
  };

  const handleClickContinue = () => {
    let errors = {
      ...formError,
      url: false,
      urlMessage: "",
    };
    if (formData?.url !== "" && formData?.url !== null) {
      if (formData?.url?.length >= 3) {
        setShowSnackbar(true);
        setSnackBarMessage("Comprobando disponibilidad del enlace");
        if (ganvent_urls.includes(formData?.url?.toLowerCase().trim())) {
          errors.url = true;
          errors.urlMessage = "Enlace NO DISPONIBLE, intenta con otro";
        } else if (
          stringHasWhiteSpaceOrNot(formData?.url?.toLowerCase()?.trim())
        ) {
          errors.url = true;
          errors.urlMessage = "El enlace NO PUEDE CONTENER ESPACIOS";
        } else {
          setCreating(true);
          // Creamos el negocio
          const q = query(
            collection(db, "business"),
            where("url", "==", formData?.url?.toLowerCase().trim())
          );
          getDocs(q)
            .then((response) => {
              if (!response.empty) {
                //Ya existe el negocio
                let tempArray = [];
                response.forEach((doc) => {
                  let object = doc.data();
                  object.id = doc.id;
                  tempArray.push(object);
                });
                errors.url = true;
                errors.urlMessage = "Enlace NO DISPONIBLE, intenta con otro";
                setCreating(false);
                setShowSnackbar(true);
                setSnackBarMessage("Enlace NO DISPONIBLE, intenta con otro");
              } else {
                createNewBusiness();
              }
            })
            .catch((err) => {
              setCreating(false);
              setShowSnackbar(true);
              setSnackBarMessage(
                "Ocurrió un error al intentar crear el negocio, inténtalo de nuevo"
              );
            });
        }
      } else {
        errors.url = true;
        errors.urlMessage = "El enlace debe tener como MÍNIMO 3 CARACTERES";
      }
    } else {
      errors.url = true;
      errors.urlMessage = "El enlace es obligatorio";
    }
    setFormError(errors);
  };

  if (!isVisible) {
    return <></>;
  }

  return (
    <Container>
      <StepBar stepNumber={stepNumber} />
      <div style={{ marginBottom: "38px" }} />
      <Title>Crea el link de tu tienda</Title>
      <Title>online</Title>
      <div style={{ marginTop: "24px" }} />
      <Content>
        <InputWithLabel
          newBusiness={true}
          label="Enlace de tu negocio:"
          placeholder="Escribe aquí el enlace de tu negocio"
          labelIcon={<IoLinkOutline style={{ color: "#00a680" }} />}
          value={newUrl}
          setValue={setNewUrl}
          error={formError?.url}
        />
        {formError?.url && formError?.urlMessage && (
          <ErrorText>{formError?.urlMessage}</ErrorText>
        )}
        <div style={{ marginBottom: "20px" }} />
        <Row>
          <Circle />
          <Rule>
            El enlace NO puede tener espacios en blanco ni caracteres especiales
            (/, #, +, etc)
          </Rule>
        </Row>
        <Row>
          <Circle />
          <Rule>Debe tener mínimo 3 caracteres</Rule>
        </Row>
        <Row>
          <Circle />
          <Rule>
            Si el enlace consta de varias palabras, entonces puedes separarlos
            mediante guiones '-' o colocar todas las palabras juntas
          </Rule>
        </Row>
        <Row>
          <Circle />
          <Rule>Por ejemplo: lamejortienda o la-mejor-tienda</Rule>
        </Row>
        <MainButton
          disabled={creating}
          onClick={handleClickContinue}
          endIcon={
            !creating ? <ChevronRight style={{ color: "white" }} /> : <></>
          }
        >
          {creating ? (
            <CircularProgress style={{ color: "white" }} size={16} />
          ) : (
            <MainButtonTitle>Continuar</MainButtonTitle>
          )}
        </MainButton>
        <Button
          disabled={creating}
          onClick={goBack}
          startIcon={<ChevronLeft style={{ color: "black" }} />}
        >
          <ButtonText>Atrás</ButtonText>
        </Button>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 600px;
  height: 100%;
`;

const Title = styled.p`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0px 50px;
`;

const Subtitle = styled.p`
  color: black;
  font-weight: 400;
  margin-left: 70px;
  margin-right: 70px;
  margin-top: 25px;
`;

const Content = styled.div`
  margin-left: 70px;
  margin-right: 70px;
`;

const MainButton = styled(Button)`
  &&& {
    background-color: #00a680;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 25px;
  }
`;

const MainButtonTitle = styled.p`
  text-transform: none;
  color: white;
  &&& {
    font-size: 15px;
    font-family: "Montserrat";
  }
`;

const ButtonText = styled.p`
  &&& {
    text-transform: none;
    color: black;
    font-family: "Montserrat";
    font-weight: 400;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Text = styled.p``;

const Circle = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 100%;
  background-color: #00a680;
  margin-top: 3px;
`;

const Rule = styled.p`
  flex: 1;
  font-size: 13px;
`;

const ErrorText = styled.p`
  color: red;
  font-size: 13px;
  margin-top: 15px;
`;
