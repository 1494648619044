import { Accordion, Button, IconButton } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import AccordionDetails from "@mui/material/AccordionDetails";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";

interface Props {
  label: string;
  placeholder: string;
  type?: string;
  labelIcon?: React.ReactNode;
  rightLabelIcon?: React.ReactNode;
  moreInformation?: string;
  showInput?: boolean;
  error?: boolean;
  value?: any;
  setValue?: React.Dispatch<React.SetStateAction<any>>;
  showPassword?: boolean;
  setShowPassword?: React.Dispatch<React.SetStateAction<boolean>>;
  newBusiness?: boolean;
  setUnsavedChanges?: React.Dispatch<React.SetStateAction<boolean>>;
  maxLength?: number | undefined;
}

interface InputI {
  error?: boolean;
}

export default function InputWithLabel({
  label,
  placeholder,
  type = "text",
  labelIcon,
  rightLabelIcon,
  moreInformation,
  showInput = true,
  error = false,
  value = "",
  showPassword,
  setShowPassword,
  setValue,
  newBusiness = false,
  setUnsavedChanges,
  maxLength = undefined,
}: Props) {
  const [isInformationVisible, setIsInformationVisible] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setValue !== undefined) {
      setValue(e.target.value);
    }
    if (setUnsavedChanges !== undefined) {
      setUnsavedChanges(true);
    }
  };

  return (
    <Container>
      <Row>
        {labelIcon !== undefined && labelIcon}
        {label && <Label>{label}</Label>}
        {rightLabelIcon !== undefined && (
          <IconButton onClick={() => setIsInformationVisible(true)}>
            {rightLabelIcon}
          </IconButton>
        )}
      </Row>
      {moreInformation !== undefined && isInformationVisible && (
        <Accordion expanded={true} style={{ marginBottom: "10px" }}>
          <AccordionDetails>
            <MoreInformation>{moreInformation}</MoreInformation>
            <AccordionFooter>
              <Button onClick={() => setIsInformationVisible(false)}>
                <AccordionText>Ok</AccordionText>
              </Button>
            </AccordionFooter>
          </AccordionDetails>
        </Accordion>
      )}
      {showInput && (
        <Row style={{ marginBottom: newBusiness ? "4px" : "0px", gap: "3px" }}>
          {newBusiness && <Link>https://ganvent.shop/</Link>}
          <InputContainer
            error={error}
            style={{ flex: newBusiness ? "1" : "inherit" }}
          >
            <Input
              maxLength={maxLength}
              value={value}
              type={type}
              placeholder={placeholder}
              onChange={handleChange}
            />
            {setShowPassword !== undefined && (
              <IconButton
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
              </IconButton>
            )}
          </InputContainer>
        </Row>
      )}
    </Container>
  );
}

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin-bottom: 4px;
`;

const Label = styled.p`
  font-size: 13px;
  color: #000000;
  flex: 1;
`;

const InputContainer = styled.div<InputI>`
  border: 1px solid ${(props) => (!props.error ? "#c1c1c1" : "red")};
  width: 100%;
  border-radius: 10px;
  margin-top: 7px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Link = styled.p`
  font-size: 1rem;
  font-weight: 500;
`;

const Input = styled.input`
  outline: none;
  border: none;
  width: 100%;
  padding: 15px 12px;
  font-family: "Montserrat";
  font-size: 13px;
`;

const MoreInformation = styled.p`
  font-size: 0.9rem;
  font-weight: 500;
`;

const AccordionFooter = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const AccordionText = styled.p`
  &&& {
    font-family: "Montserrat";
    font-size: 15px;
  }
`;
