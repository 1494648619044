import React, { useEffect, useRef, useState } from "react";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { LuShapes } from "react-icons/lu";
import { RootState } from "../../../context/store";
import {
  collection,
  db,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
} from "../../../firebase";
import {
  setCanLoadMore,
  setCategories,
  setIsLoadingMoreData,
  setLastCategory,
} from "../../../context/reducers/categories";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Add from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";

interface Props {
  selectedCategoryId: string;
  setSelectedCategoryId: React.Dispatch<React.SetStateAction<string>>;
}

const CATEGORY_NOT_SELECTED = "category not selected";

export default function CategorySelector({
  selectedCategoryId,
  setSelectedCategoryId,
}: Props) {
  const selectedBusiness = useSelector(
    (state: RootState) => state.layout.selectedBusiness
  );
  const userIsPro = useSelector((state: RootState) => state.layout.userIsPro);
  const categories = useSelector(
    (state: RootState) => state.categories.categories
  );
  const lastCategory = useSelector(
    (state: RootState) => state.categories.lastCategory
  );
  const canLoadMore = useSelector(
    (state: RootState) => state.categories.canLoadMore
  );
  const isLoadingMoreData = useSelector(
    (state: RootState) => state.categories.isLoadingMoreData
  );
  const [showModal, setShowModal] = useState(false);
  const categoriesLimit = 8;
  const isMounted = useRef(false);
  const dispatch = useDispatch();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (selectedBusiness?.id) {
      const categoriesRef = collection(
        db,
        "business",
        selectedBusiness?.id,
        "categorys"
      );

      const q = query(
        categoriesRef,
        orderBy("name", "asc"),
        limit(categoriesLimit)
      );

      const suscriber = onSnapshot(
        q,
        (snapshot) => {
          if (!snapshot.empty) {
            let tempArray: any[] = [];
            if (isMounted.current) {
              dispatch(
                setLastCategory({
                  lastCategory: snapshot.docs[snapshot.docs.length - 1],
                })
              );
            }
            snapshot.forEach((doc) => {
              let object = doc.data();
              object.id = doc.id;
              tempArray.push(object);
            });
            if (isMounted.current) {
              dispatch(
                setCategories({
                  categories: tempArray,
                })
              );
            }
          } else {
            if (isMounted.current) {
              dispatch(
                setCategories({
                  categories: [],
                })
              );
            }
          }
          dispatch(
            setIsLoadingMoreData({
              isLoadingMoreData: false,
            })
          );
          dispatch(
            setCanLoadMore({
              canLoadMore: true,
            })
          );
        },
        (err) => {}
      );

      return () => {
        suscriber();
      };
    }
  }, []);

  const handleLoadMore = () => {
    if (canLoadMore && !isLoadingMoreData) {
      dispatch(
        setCanLoadMore({
          canLoadMore: true,
        })
      );
      dispatch(
        setIsLoadingMoreData({
          isLoadingMoreData: true,
        })
      );
      const categoriesRef = collection(
        db,
        "business",
        selectedBusiness?.id,
        "categorys"
      );
      const q = query(
        categoriesRef,
        orderBy("name", "asc"),
        startAfter(lastCategory),
        limit(categoriesLimit)
      );
      onSnapshot(
        q,
        (snapshot) => {
          if (!snapshot.empty) {
            let tempArray: any = [];
            if (isMounted.current) {
              dispatch(
                setLastCategory({
                  lastCategory: snapshot.docs[snapshot.docs.length - 1],
                })
              );
            }
            snapshot.forEach((doc) => {
              let object = doc?.data();
              object.id = doc?.id;
              tempArray.push(object);
            });
            if (isMounted.current) {
              dispatch(
                setCategories({
                  categories: [...(categories as any[]), ...tempArray],
                })
              );
            }
          }
          if (snapshot.empty) {
            dispatch(
              setCanLoadMore({
                canLoadMore: false,
              })
            );
          } else {
            dispatch(
              setCanLoadMore({
                canLoadMore: true,
              })
            );
          }
          dispatch(
            setIsLoadingMoreData({
              isLoadingMoreData: false,
            })
          );
        },
        (err) => {
          console.log("err: ", err);
        }
      );
    } else {
      if (isMounted.current) {
        dispatch(
          setCanLoadMore({
            canLoadMore: false,
          })
        );
      }
    }
  };

  const handleClick = () => {
    if (categories == null) return;
    setShowModal(true);
  };

  const getSelectedCategory = () => {
    if (selectedCategoryId == CATEGORY_NOT_SELECTED)
      return "Selecciona la categoría de este producto";
    let index = categories?.findIndex((item) => item.id == selectedCategoryId);
    if (index == -1) return "Selecciona la categoría de este producto";
    if (categories == null) return "";
    return capitalizeFirstLetter(categories[index as number]?.name ?? "");
  };

  const handleClose = () => setShowModal(false);

  const onChangeSelected = (item: any) => {
    setSelectedCategoryId(item?.id);
    setShowModal(false);
  };

  const deleteCategory = () => {
    setSelectedCategoryId(CATEGORY_NOT_SELECTED);
  };

  return (
    <>
      <Header>
        <LuShapes />
        <Label>Categoría del producto</Label>
      </Header>
      <Row>
        <Container onClick={handleClick}>
          {categories == null ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <CircularProgress
                style={{
                  color: "#00a680",
                  marginTop: "10px",
                  marginBottom: "8px",
                }}
                size={30}
              />
            </div>
          ) : (
            <>
              <Text id="controlled-open-selected-label">
                {getSelectedCategory()}
              </Text>
              <FiChevronDown />
            </>
          )}
        </Container>
        {selectedCategoryId !== CATEGORY_NOT_SELECTED && (
          <IconButton
            onClick={deleteCategory}
            style={{
              borderWidth: 1,
              borderColor: "red",
              borderStyle: "solid",
              marginLeft: "10px",
            }}
          >
            <Close style={{ color: "red" }} />
          </IconButton>
        )}
      </Row>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={showModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Content>
          <StyledPaper>
            <Title>Selecciona la categoría de este producto</Title>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </StyledPaper>
          {categories == null ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                marginTop: "80px",
              }}
            >
              <CircularProgress
                style={{
                  color: "#00a680",
                  marginTop: "10px",
                  marginBottom: "8px",
                }}
                size={30}
              />
            </div>
          ) : categories?.length == 0 ? (
            <>
              <div style={{ marginTop: "60px" }} />
              <NotFound>
                No has agregado ninguna categoría al inventario de tu negocio.
              </NotFound>
              <NotFound>
                Para agregar una nueva categoría, dirígete al listado de
                categorías de tu inventario
              </NotFound>
            </>
          ) : (
            <Scroll>
              {categories?.map((item, index) => (
                <Item key={index} onClick={() => onChangeSelected(item)}>
                  <ItemText>{capitalizeFirstLetter(item.name ?? "")}</ItemText>
                  <FiChevronRight />
                </Item>
              ))}
              {canLoadMore &&
                (isLoadingMoreData ? (
                  <ButtonLoadMoreContainer>
                    <ButtonLoadMore
                      disabled
                      style={{ paddingLeft: "30px", paddingRight: "30px" }}
                    >
                      <CircularProgress
                        style={{ color: "#00a680" }}
                        size={20}
                      />
                    </ButtonLoadMore>
                  </ButtonLoadMoreContainer>
                ) : (
                  <ButtonLoadMoreContainer>
                    <ButtonLoadMore
                      onClick={handleLoadMore}
                      startIcon={<Add style={{ color: "#00a680" }} />}
                    >
                      <ButtonLoadMoreText>
                        Cargar más categorías
                      </ButtonLoadMoreText>
                    </ButtonLoadMore>
                  </ButtonLoadMoreContainer>
                ))}
            </Scroll>
          )}
        </Content>
      </Modal>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #c1c1c1;
  flex: 1;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 12px 10px;
  cursor: pointer;

  transition: all 0.1s ease;

  :hover {
    background-color: rgba(155, 155, 155, 0.1);
  }
`;

const Text = styled.p`
  flex: 1;
  font-size: 15px;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin-bottom: 4px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const Label = styled.p`
  font-size: 13px;
  color: #000000;
  flex: 1;
`;

const Content = styled.div`
  background-color: white;
  width: 80vw;
  max-width: 600px;
  height: 90vh;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
`;

const Scroll = styled.div`
  overflow-y: scroll;
  flex: 1;
`;

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 14px;
  padding-left: 14px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
`;

const Title = styled.p`
  padding: 25px 0px;
  flex: 1;
  font-weight: 600;
`;

const Item = styled.div`
  cursor: pointer;
  transition: all 0.1s ease;
  padding: 20px;
  border-bottom: 1px solid #c1c1c1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  :hover {
    background-color: rgba(155, 155, 155, 0.3);
  }
`;

const ItemText = styled.p`
  text-align: center;
  flex: 1;
`;

const NotFound = styled.p`
  text-align: center;
  margin: 0px 40px;
  margin-top: 8px;
  font-size: 13px;
  font-weight: 500;
`;

const ButtonLoadMoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const ButtonLoadMore = styled(Button)`
  &&& {
    border: 1px solid #00a680;
    padding: 15px 20px;
    border-radius: 10px;
    margin-top: 12px;
  }
`;

const ButtonLoadMoreText = styled.p`
  &&& {
    text-transform: none;
    font-family: "Montserrat";
  }
`;

const StyledButton = styled(Button)`
  position: fixed;
  bottom: 100px;
  right: 30px;
  &&& {
    background-color: #00a680;
    padding: 12px 15px;
    border-radius: 12px;
    box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.16);
  }
`;

const StyledButtonText = styled.p`
  text-transform: none;
  color: white;
  &&& {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 1rem;
  }
`;
