import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { MdStore } from "react-icons/md";
import { RootState } from "../../../context/store";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import { collection, db, doc, getDoc } from "../../../firebase";
import { CircularProgress } from "@mui/material";

interface Props {
  business: any;
  changeBusiness: (business: any) => void;
}

export default function AssociatedBusinessItem({
  business,
  changeBusiness,
}: Props) {
  const { businessId } = business;
  const selectedBusiness = useSelector(
    (state: RootState) => state.layout.selectedBusiness
  );
  const [businessObject, setBusinessObject] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const isMounted2 = useRef(false);

  useEffect(() => {
    isMounted2.current = true;
    return () => {
      isMounted2.current = false;
    };
  }, []);

  useEffect(() => {
    if (businessId) {
      const businessDoc = doc(db, "business", businessId);
      getDoc(businessDoc)
        .then((response) => {
          if (response.exists()) {
            let object = response.data();
            object.id = response.id;
            if (isMounted2.current) {
              setBusinessObject(object);
            }
          } else {
            setLoading(false);
          }
        })
        .catch(() => {});
    }
  }, []);

  if (loading && !businessObject) {
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
      }}
    >
      <CircularProgress style={{ color: "#00a680" }} size={22} />
    </div>;
  }

  return (
    <>
      {!loading ? (
        <></>
      ) : (
        <Container onClick={() => changeBusiness(businessObject)}>
          <ImageContainer>
            {businessObject?.images !== undefined &&
            businessObject?.images[0] ? (
              <Image src={businessObject?.images[0]} />
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#00a680",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MdStore style={{ color: "white", fontSize: "24px" }} />
              </div>
            )}
          </ImageContainer>
          <Name>{businessObject?.name?.local}</Name>
          <CircleContainer>
            {selectedBusiness?.id == businessObject?.id && <Circle />}
          </CircleContainer>
        </Container>
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px;
  gap: 10px;
  cursor: pointer;
  :hover {
    background-color: rgba(155, 155, 155, 0.2);
  }
`;

const ImageContainer = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 100%;
  overflow: hidden;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
`;

const Name = styled.p`
  flex: 1;
`;

const CircleContainer = styled.div`
  height: 30px;
  width: 30px;
  border: 2px solid #c1c1c1;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Circle = styled.div`
  height: 80%;
  width: 80%;
  border-radius: 100%;
  background-color: #00a680;
`;
