import styled from "styled-components";

interface Props {
  description: string;
  image: any;
}

export default function OnboardingFeature({ description, image }: Props) {
  return (
    <Container>
      <ImageContainer>
        <Image src={image} />
      </ImageContainer>
      <Text>{description}</Text>
    </Container>
  );
}

const Container = styled.div`
  width: calc(100vw - 600px);
  height: 100%;
  background-color: #00a680;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 230px;
  width: 230px;
  background-color: #00a680;
  border-radius: 100%;
`;

const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

const Text = styled.p`
  margin: 0px 35px;
  color: white;
  font-weight: bold;
  font-size: 1.3rem;
  margin-top: 15px;
  text-align: center;
`;
