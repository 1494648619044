import React, { useCallback, useRef, useState } from "react";
import Close from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Cropper from "react-easy-crop";
import { GrGallery } from "react-icons/gr";
import styled from "styled-components";

interface Props {
  selectedImage: string;
  setSelectedImage: React.Dispatch<React.SetStateAction<string>>;
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  croppedAreaPixels: any;
  setCroppedAreaPixels: React.Dispatch<React.SetStateAction<any>>;
}

interface Crop {
  x: number;
  y: number;
}

export default function ImagePickerModal({
  selectedImage,
  setSelectedImage,
  isVisible,
  setIsVisible,
  croppedAreaPixels,
  setCroppedAreaPixels,
}: Props) {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState<Crop>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const inputFile = useRef<HTMLInputElement>(null);

  const handleClose = () => {
    setImageSrc(null);
    setIsVisible(false);
    // @ts-ignore: Unreachable code error
    if (inputFile.current) {
      inputFile.current.value = ""; // Reiniciar el input de tipo file
    }
  };

  const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.size <= 2 * 1024 * 1024) {
      // Check file size (2MB limit)
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === "string") {
          setImageSrc(reader.result);
        }
      };
    } else {
      alert(
        "El archivo es demasiado grande. Seleccione una imagen de menos de 2 MB."
      );
    }
  };

  const onCropComplete = useCallback(
    (
      croppedArea: any,
      croppedAreaPixels: { x: number; y: number; width: number; height: number }
    ) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
    });

  const getCroppedImage = async (): Promise<Blob | null> => {
    if (!imageSrc || !croppedAreaPixels) return null;

    const canvas = document.createElement("canvas");
    const image = await createImage(imageSrc);
    const { width, height } = croppedAreaPixels;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");

    if (ctx) {
      ctx.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        width,
        height,
        0,
        0,
        width,
        height
      );
    }
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (blob) resolve(blob);
      }, "image/jpeg");
    });
  };

  const openInputFile = (): void => {
    inputFile?.current?.click();
  };

  const cancelSelectedImage = () => {
    setImageSrc(null);
    // @ts-ignore: Unreachable code error
    if (inputFile.current) {
      inputFile.current.value = ""; // Reiniciar el input de tipo file
    }
  };

  const handleAccept = () => {
    setSelectedImage(imageSrc as string);
    setIsVisible(false);
    setImageSrc(null);
    if (inputFile.current) {
      inputFile.current.value = ""; // Reiniciar el input de tipo file
    }
  };

  return (
    <CustomDrawer
      open={isVisible}
      onClose={handleClose}
      anchor="right"
      variant="temporary"
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Container>
        <Header>
          <Title>Selecciona la imagen de este producto</Title>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Header>
        <Subtitle>
          Selecciona una imagen de dimensiones cuadrangulares para este producto
        </Subtitle>
        {!imageSrc && (
          <SelectorContainer>
            <Selector onClick={openInputFile}>
              <GrGallery style={{ fontSize: "20px" }} />
              <SelectorText>
                Pulsa aquí para seleccionar una nueva imagen
              </SelectorText>
              <SelectorText>
                Se recomienda imágenes de hasta 500px x 500px
              </SelectorText>
            </Selector>
          </SelectorContainer>
        )}
        <input
          ref={inputFile}
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          style={{ display: "none" }}
        />
        {imageSrc && (
          <SelectedImageContainer>
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
              }}
            >
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
                onCropChange={setCrop}
              />
            </div>
            {/* <button onClick={uploadCroppedImage}>Subir imagen recortada</button> */}
            {imageSrc && (
              <Footer>
                <StyledButton
                  style={{ background: "#00a680" }}
                  onClick={handleAccept}
                >
                  <StyledButtonText>Aceptar</StyledButtonText>
                </StyledButton>
                <StyledButton
                  style={{ background: "transparent", border: "1px solid red" }}
                  onClick={cancelSelectedImage}
                >
                  <StyledButtonText style={{ color: "red" }}>
                    Cancelar
                  </StyledButtonText>
                </StyledButton>
              </Footer>
            )}
          </SelectedImageContainer>
        )}
      </Container>
    </CustomDrawer>
  );
}

const CustomDrawer = styled(Drawer)``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 18px;
  padding-top: 28px;
  padding-bottom: 12px;
  padding-right: 10px;
`;

const Title = styled.p`
  flex: 1;
  font-weight: 600;
`;

const Subtitle = styled.p`
  padding-left: 18px;
  padding-right: 18px;
  margin-bottom: 10px;
`;

const SelectorContainer = styled.div`
  flex: 1;
`;

const Selector = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 15px;
  border: 2px solid #c1c1c1;
  gap: 10px;
  cursor: pointer;
  margin: 10px 12px;
  :hover {
    background-color: rgba(155, 155, 155, 0.2);
  }
`;

const SelectorText = styled.p`
  text-align: center;
  font-size: 12px;
`;

const SelectedImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #c1c1c1;
  padding: 20px 20px;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  background-color: #00a680;
  padding: 10px 30px;
  width: 250px;
`;

const StyledButtonText = styled.p`
  text-transform: none;
  &&& {
    font-family: "Montserrat";
    color: white;
  }
`;
