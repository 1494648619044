import React, { useState } from "react";
import styled from "styled-components";
import { BsFillPlayBtnFill } from "react-icons/bs";
import VideoModal from "./VideoModal";
import { Button } from "@mui/material";

interface Props {
  videoUrl: string;
  setVideoUrl: React.Dispatch<React.SetStateAction<string>>;
}

export default function Video({ videoUrl, setVideoUrl }: Props) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Container onClick={() => setShowModal(true)}>
        <Header>
          <Row>
            <BsFillPlayBtnFill />
            <Label>Video</Label>
          </Row>
          {videoUrl !== "" && (
            <StyledButton>
              <StyledButtonText>Editar link</StyledButtonText>
            </StyledButton>
          )}
        </Header>
        <Description>
          {videoUrl !== ""
            ? videoUrl
            : "📹 Añade videos a tus productos de tu tienda virtual y cautiva a tus clientes con demostraciones increíbles! 🌟"}
        </Description>
      </Container>
      <VideoModal
        isVisible={showModal}
        setIsVisible={setShowModal}
        videoUrl={videoUrl}
        setVideoUrl={setVideoUrl}
      />
    </>
  );
}

const Container = styled.div`
  border: 1px solid black;
  border-radius: 12px;
  padding: 16px 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  :hover {
    background-color: rgba(155, 155, 155, 0.3);
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  margin-bottom: 10px;
`;

const Label = styled.p`
  font-size: 13px;
`;

const Description = styled.p`
  font-size: 13px;
  margin-top: 7px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  gap: 6px;
`;

const StyledButton = styled(Button)`
  &&& {
    background-color: white;
    border: 1px solid #00a680;
    border-radius: 10px;
  }
`;

const StyledButtonText = styled.p`
  &&& {
    text-transform: none;
    font-size: 11px;
    font-family: "Montserrat";
  }
`;
