import { createSlice } from "@reduxjs/toolkit";

interface InitialStateI {
  isNewIncomeDrawerOpen: boolean;
  isNewExpenseDrawerOpen: boolean;
  isNewSaleDrawerOpen: boolean;
  isNewPurchaseDrawerOpen: boolean;
}

const initialState: InitialStateI = {
  isNewIncomeDrawerOpen: false,
  isNewExpenseDrawerOpen: false,
  isNewSaleDrawerOpen: false,
  isNewPurchaseDrawerOpen: false,
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    setIsNewIncomeDrawerOpen: (state, action) => {
      state.isNewIncomeDrawerOpen = action.payload.isNewIncomeDrawerOpen;
    },
    setIsNewExpenseDrawerOpen: (state, action) => {
      state.isNewExpenseDrawerOpen = action.payload.isNewExpenseDrawerOpen;
    },
    setIsNewSaleDrawerOpen: (state, action) => {
      state.isNewSaleDrawerOpen = action.payload.isNewSaleDrawerOpen;
    },
    setIsNewPurchaseDrawerOpen: (state, action) => {
      state.isNewPurchaseDrawerOpen = action.payload.isNewPurchaseDrawerOpen;
    },
  },
});

export const {
  setIsNewExpenseDrawerOpen,
  setIsNewIncomeDrawerOpen,
  setIsNewSaleDrawerOpen,
  setIsNewPurchaseDrawerOpen,
} = transactionsSlice.actions;
export default transactionsSlice.reducer;
