import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";
import store from "./context/store";
import { base } from "./styles/StylesComponentsTheme";
import GlobalStyle from "./styles/GlobalStyle";

const rootElement = document.getElementById("root");

/* const root = ReactDOM.createRoot(rootElement as HTMLElement); */

const muiTheme = createTheme({
  components: {
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
  palette: {
    primary: {
      main: "#00a680",
    },
    secondary: {
      main: "#00a680",
    },
  },
});

const styledComponetsTheme = {
  ...base,
};

ReactDOM.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <StyledComponentsThemeProvider theme={styledComponetsTheme}>
        <ThemeProvider theme={muiTheme}>
          <GlobalStyle />
          <App />
        </ThemeProvider>
      </StyledComponentsThemeProvider>
    </StyledEngineProvider>
  </Provider>,
  rootElement
);

/* root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <StyledComponentsThemeProvider theme={styledComponetsTheme}>
        <ThemeProvider theme={muiTheme}>
          <GlobalStyle />
          <App />
        </ThemeProvider>
      </StyledComponentsThemeProvider>
    </StyledEngineProvider>
  </Provider>
); */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
