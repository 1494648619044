import React, { useEffect, useState } from "react";
import styled from "styled-components";
import StepBar from "./StepBar";
import { Button } from "@mui/material";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { FiDollarSign } from "react-icons/fi";
import { IoMdPhonePortrait } from "react-icons/io";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import InputWithLabel from "../login/InputWithLabel";
import CurrencySelector from "../global/CurrencySelector";
import { CURRENCY_TYPE_NOT_SELECTED } from "../../utils/globalConstants";

interface Props {
  stepNumber: number;
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  formError: any;
  setFormError: React.Dispatch<React.SetStateAction<any>>;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  currencyType: any;
  setCurrencyType: React.Dispatch<React.SetStateAction<any>>;
  isVisible: boolean;
}

export default function Step2({
  stepNumber,
  currentStep,
  formData,
  formError,
  setCurrentStep,
  setFormData,
  setFormError,
  currencyType,
  setCurrencyType,
  isVisible,
}: Props) {
  const [auxFormData, setAuxFormData] = useState({
    phoneNumber: "",
    cca2: "US",
    callingCode: ["1"],
  });
  const [phone, setPhone] = useState<string>("");

  useEffect(() => {
    setFormData({
      ...formData,
      phoneNumber: auxFormData.phoneNumber,
      cca2: auxFormData.cca2,
      callingCode: auxFormData.callingCode,
    });
  }, [auxFormData]);

  const handleContinue = () => {
    let errors = {
      ...formError,
      currencyType: false,
      currencyTypeMessage: "",
      phone: false,
      phoneMessage: "",
    };
    if (
      currencyType == CURRENCY_TYPE_NOT_SELECTED ||
      currencyType == null ||
      currencyType == undefined
    ) {
      errors.currencyType = true;
      errors.currencyTypeMessage = "El tipo de moneda es obligatorio";
    } else if (
      formData?.phoneNumber == null ||
      formData?.phoneNumber == "" ||
      formData?.phoneNumber == undefined ||
      formData?.callingCode == "" ||
      formData?.cca2 == ""
    ) {
      errors.phone = true;
      errors.phoneMessage = "El teléfono de tu negocio es obligatorio";
    } else {
      setCurrentStep(2);
    }
    setFormError(errors);
  };

  const goBack = () => {
    setCurrentStep(0);
  };

  function removeDialCode(phone: string, dialCode: string): string {
    if (phone.startsWith(dialCode)) {
      return phone.substring(dialCode.length);
    }
    return phone;
  }

  if (!isVisible) {
    return <></>;
  }

  return (
    <Container>
      <StepBar stepNumber={stepNumber} />
      <Title>Establece tu moneda e información de contacto</Title>
      <Content>
        <InputWithLabel
          label="Selecciona el tipo de moneda (Obligatorio)"
          placeholder=""
          showInput={false}
          labelIcon={<FiDollarSign style={{ color: "#00a680" }} />}
        />
        <CurrencySelector setCurrencyType={setCurrencyType} />
        {formError?.currencyType && formError?.currencyTypeMessage && (
          <ErrorText>{formError?.currencyTypeMessage}</ErrorText>
        )}
        <div style={{ marginTop: "30px" }} />
        <InputWithLabel
          label="Número de teléfono de tu negocio"
          placeholder=""
          showInput={false}
          labelIcon={<IoMdPhonePortrait style={{ color: "#00a680" }} />}
        />
        <div style={{ marginBottom: "10px" }} />
        <PhoneInput
          enableSearch={true}
          inputStyle={{
            width: "100%",
            borderColor: formError?.phone ? "red" : "#c1c1c1",
          }}
          containerStyle={
            {
              /* backgroundColor: 'red' */
            }
          }
          specialLabel={""}
          placeholder={"Número"}
          inputProps={{
            name: "Número",
          }}
          country={"us"}
          value={phone}
          onChange={(phone, data: any) => {
            const { countryCode, dialCode } = data;
            setAuxFormData({
              phoneNumber: removeDialCode(phone, dialCode),
              callingCode: [dialCode],
              cca2: countryCode?.toUpperCase() ?? "",
            });
            setPhone(phone);
          }}
        />
        {formError?.phone && formError?.phoneMessage && (
          <ErrorText>{formError?.phoneMessage}</ErrorText>
        )}
        <Note>
          Nota: Los pedidos que tus clientes realicen en tu tienda online,
          llegarán al WhatsApp de este número*
        </Note>
        <MainButton
          endIcon={<ChevronRight style={{ color: "white" }} />}
          onClick={handleContinue}
        >
          <MainButtonTitle>Continuar</MainButtonTitle>
        </MainButton>
      </Content>
      <Button
        style={{ marginLeft: "60px" }}
        startIcon={<ChevronLeft style={{ color: "black" }} />}
        onClick={goBack}
      >
        <ButtonText>Atrás</ButtonText>
      </Button>
    </Container>
  );
}

const Container = styled.div`
  width: 600px;
  height: 100%;
`;

const Title = styled.p`
  text-align: center;
  margin-top: 35px;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0px 50px;
`;

const Content = styled.div`
  margin-left: 70px;
  margin-right: 70px;
  margin-top: 35px;
`;

const Row = styled.div``;

const MainButton = styled(Button)`
  &&& {
    background-color: #00a680;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 25px;
  }
`;

const MainButtonTitle = styled.p`
  text-transform: none;
  color: white;
  &&& {
    font-size: 15px;
    font-family: "Montserrat";
  }
`;

const Note = styled.p`
  font-size: 14px;
  margin-top: 10px;
  font-weight: 400;
  color: gray;
`;

const ButtonText = styled.p`
  &&& {
    text-transform: none;
    color: black;
    font-family: "Montserrat";
    font-weight: 400;
  }
`;

const ErrorText = styled.p`
  color: red;
  font-size: 12px;
  margin-top: 15px;
`;
