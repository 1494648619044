import { type AxiosResponse, type AxiosError } from "axios";

const axios = require("axios").default;

const apiClientConfig: any = {
  baseURL: "https://api.revenuecat.com/v1",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + "YFdYxuZgckZATODgymIymceHbjMzMUrb",
  },
};

export const apiClient = axios.create(apiClientConfig);
