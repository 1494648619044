import IconButton from "@mui/material/IconButton";
import React, { useState } from "react";
import styled from "styled-components";
import { FaPlus } from "react-icons/fa6";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { BsFillLayersFill } from "react-icons/bs";
import { TbPointFilled } from "react-icons/tb";
import { IoIosCloseCircleOutline } from "react-icons/io";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import { useDispatch } from "react-redux";
import {
  setGeneralSnackBarMessage,
  setShowGeneralSnackbar,
} from "../../../context/reducers/layout";

interface Props {
  features: string[];
  setFeatures: React.Dispatch<React.SetStateAction<string[]>>;
}

interface InputI {
  error?: boolean;
}

const MAX_QUANTITY = 4;

export default function Features({ features, setFeatures }: Props) {
  const [newFeature, setNewFeature] = useState("");
  const [showInformation, setShowInformation] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    setNewFeature(e.target.value);
  };

  const handleClick = () => {
    if (features.length <= MAX_QUANTITY - 1) {
      if (!newFeature) {
        setError(true);
      } else {
        setFeatures([...features, newFeature]);
        setNewFeature("");
      }
    } else {
      dispatch(
        setShowGeneralSnackbar({
          showSnackbar: true,
        })
      );
      dispatch(
        setGeneralSnackBarMessage({
          snackBarMessage: `Cada producto puede tener como máximo ${MAX_QUANTITY} características`,
        })
      );
    }
  };

  const deleteFeature = (index: number) => {
    setFeatures(features?.filter((item, i) => i !== index));
  };

  return (
    <Container>
      <Header>
        <BsFillLayersFill />
        <Label>Características o detalles del producto</Label>
        <IconButton onClick={() => setShowInformation(true)}>
          <IoIosInformationCircleOutline />
        </IconButton>
      </Header>
      <Row>
        <InputContainer error={error} style={{ flex: 1 }}>
          <Input
            value={newFeature}
            type={"text"}
            placeholder={"Escribe aquí la característica"}
            onChange={handleChange}
            maxLength={120}
          />
        </InputContainer>
        <StyledIconButton onClick={handleClick}>
          <FaPlus />
        </StyledIconButton>
      </Row>
      {error && <ErrorText>El campo está vacío</ErrorText>}
      {features?.map((feature, index) => (
        <FeatureItem key={index}>
          <Row>
            <TbPointFilled />
            <Feature>{capitalizeFirstLetter(feature)}</Feature>
            <IconButton onClick={() => deleteFeature(index)}>
              <IoIosCloseCircleOutline />
            </IconButton>
          </Row>
        </FeatureItem>
      ))}
    </Container>
  );
}

const Container = styled.div``;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  margin-bottom: 6px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin-bottom: 4px;
`;

const Label = styled.p`
  font-size: 13px;
  color: #000000;
  flex: 1;
`;

const InputContainer = styled.div<InputI>`
  border: 1px solid ${(props) => (!props.error ? "#c1c1c1" : "red")};
  width: 100%;
  border-radius: 10px;
  margin-top: 7px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Input = styled.input`
  outline: none;
  border: none;
  width: 100%;
  padding: 15px 12px;
  font-family: "Montserrat";
  font-size: 13px;
`;

const StyledIconButton = styled(IconButton)`
  &&& {
    background-color: white;
    border-radius: 100%;
    margin-left: 8px;
    padding: 10px;
    border: 1px solid #c1c1c1;
  }
`;

const ErrorText = styled.p`
  color: red;
  font-size: 12px;
  margin-top: 12px;
`;

const FeatureItem = styled.div`
  border-bottom: 1px solid rgba(155, 155, 155, 0.3);
`;

const Feature = styled.p`
  flex: 1;
  font-size: 13px;
`;
