import React from "react";
import styled from "styled-components";

interface Props {
  link: string;
  title: string;
  icon: React.ReactNode;
}

export default function PolicyOption({ link, title, icon }: Props) {
  const openWhatsapp = () => {
    const url = link;
    window.open(url, "_blank");
  };

  return (
    <Container onClick={openWhatsapp}>
      {icon}
      <Title>{title}</Title>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px 0px;
  gap: 8px;
  cursor: pointer;
  :hover {
    background-color: rgba(155, 155, 155, 0.2);
  }
`;

const Title = styled.p`
  font-size: 12px;
`;
