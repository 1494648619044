import styled from "styled-components";
import currencyData from "../../../utils/currencyData";
import { useSelector } from "react-redux";
import { RootState } from "../../../context/store";

export default function SelectedCurrency() {
  const selectedBusiness = useSelector(
    (state: RootState) => state.layout.selectedBusiness
  );

  const getCurrencyTypeSymbol = (): string => {
    const currency = selectedBusiness?.mainCurrency?.currencyType;
    let message: string = "";
    if (
      currency != null &&
      currency != "currencyType not selected" &&
      currency != undefined &&
      currency != ""
    ) {
      currencyData.forEach((data) => {
        if (data.code === currency) {
          message = `${data.name}`;
        }
      });
    } else {
      message = "Tipo de moneda no especificado";
    }
    return message;
  };

  return (
    <Container>
      <Text>
        Moneda: <Span>{getCurrencyTypeSymbol()}</Span>
      </Text>
    </Container>
  );
}

const Container = styled.div``;

const Text = styled.p`
  font-size: 14px;
`;

const Span = styled.span`
  font-weight: 600;
  color: #00a680;
`;
