import React, { useEffect, useState } from "react";
import Close from "@mui/icons-material/Close";
import { CircularProgress, Drawer, IconButton } from "@mui/material";
import styled from "styled-components";
import { MdOutlineStore } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { MdAddLink } from "react-icons/md";
import BusinessItem from "./businessesModal/BusinessItem";
import { useNavigate } from "react-router-dom";
import {
  auth,
  collection,
  db,
  query,
  where,
  getDocs,
  onSnapshot,
} from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../context/store";
import {
  setChangingBusiness,
  setIsBusinessesModalVisible,
  setSelectedBusiness,
} from "../../context/reducers/layout";
import AssociatedBusinessItem from "./businessesModal/AssociatedBusinessItem";
import { SELECTED_BUSINESS_ID } from "../../utils/globalConstants";
import NewBusinessInfoModal from "./NewBusinessInfoModal";
import LockIcon from "@mui/icons-material/Lock";

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function BusinessesModal({}: /* isVisible, setIsVisible */ Props) {
  const navigate = useNavigate();
  const selectedBusiness = useSelector(
    (state: RootState) => state.layout.selectedBusiness
  );
  const isBusinessesModalVisible = useSelector(
    (state: RootState) => state.layout.isBusinessesModalVisible
  );
  const userIsPro = useSelector((state: RootState) => state.layout.userIsPro);
  const [businessesList, setBusinessesList] = useState<any[] | null>(null);
  const [associatedBusinessesList, setAssociatedBusinessesList] = useState<
    any[] | null
  >(null);
  const [showNewBusinessModal, setShowNewBusinessModal] = useState(false);
  const user = auth.currentUser;
  const dispatch = useDispatch();

  const handleClose = () => {
    if (!selectedBusiness?.id) {
      return;
    }
    dispatch(
      setIsBusinessesModalVisible({
        isBusinessesModalVisible: false,
      })
    );
  };

  const list = [
    {
      title: "Ver información y lista de empleados del negocio",
      icon: <MdOutlineStore />,
      path: "/employees-list",
    },
    {
      title: "Crear nuevo negocio",
      icon: <FaPlus />,
      path: "/employees-list",
      id: "new-business",
    },
    {
      title: "Asociarse a un negocio",
      icon: <MdAddLink />,
      path: "/join-a-business",
    },
  ];

  const navigateTo = (path: string) => {
    handleClose();
    navigate({
      pathname: path,
    });
  };

  useEffect(() => {
    const q = query(
      collection(db, "business"),
      where("userId", "==", user?.uid)
    );
    const suscriber = onSnapshot(
      q,
      (snapshot) => {
        if (!snapshot.empty) {
          let tempArray: any[] = [];
          snapshot.forEach((doc) => {
            let object = doc?.data();
            object.id = doc?.id;
            tempArray.push(object);
          });
          setBusinessesList(tempArray);
        } else {
          setBusinessesList([]);
        }
      },
      (err) => {}
    );
    return () => {
      suscriber();
    };
  }, []);

  useEffect(() => {
    const q = query(
      collection(db, "employee-business"),
      where("userId", "==", user?.uid)
    );

    getDocs(q)
      .then((snapshot) => {
        if (!snapshot.empty) {
          let tempArray: any[] = [];
          snapshot.forEach((doc) => {
            let object = doc?.data();
            object.id = doc?.id;
            tempArray.push(object);
          });
          setAssociatedBusinessesList(tempArray);
        } else {
          setAssociatedBusinessesList([]);
        }
      })
      .catch((err) => {});
  }, []);

  const changeBusiness = (newBusiness: any) => {
    const {
      name,
      id,
      images,
      userId,
      description,
      owner,
      email,
      phone,
      type,
      country,
      direction,
      town,
      shop,
      shopName,
      mainCurrency,
      url,
    } = newBusiness;
    if (id !== selectedBusiness?.id) {
      localStorage.setItem(SELECTED_BUSINESS_ID, id);
      dispatch(
        setIsBusinessesModalVisible({
          isBusinessesModalVisible: false,
        })
      );
      dispatch(
        setSelectedBusiness({
          selectedBusiness: newBusiness,
        })
      );
      dispatch(
        setChangingBusiness({
          changingBusiness: true,
        })
      );
    }
  };

  return (
    <>
      <StyledDrawer
        anchor="bottom"
        open={isBusinessesModalVisible}
        onClose={handleClose}
        SlideProps={{
          style: {
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
          },
        }}
      >
        <Container>
          <Content>
            <Row>
              <Title>Tus negocios</Title>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Row>
            {businessesList == null ? (
              <CircularProgressContainer>
                <CircularProgress size={25} />
              </CircularProgressContainer>
            ) : (
              businessesList.map((business, index) => (
                <BusinessItem
                  business={business}
                  key={`${business?.id}_${index}`}
                  changeBusiness={changeBusiness}
                />
              ))
            )}
            <Row>
              <Title>Negocios asociados:</Title>
            </Row>
            {associatedBusinessesList == null ? (
              <CircularProgressContainer>
                <CircularProgress size={25} />
              </CircularProgressContainer>
            ) : associatedBusinessesList?.length == 0 ? (
              <div style={{ margin: "10px 15px", textAlign: "center" }}>
                <Text>
                  No te has asociado a ningún negocio o no tienes conexión a
                  internet
                </Text>
              </div>
            ) : (
              associatedBusinessesList.map((business, index) => (
                <AssociatedBusinessItem
                  business={business}
                  key={index}
                  changeBusiness={changeBusiness}
                />
              ))
            )}
          </Content>
          {selectedBusiness?.id && (
            <Footer>
              {list.map((item: any, index: number) => (
                <Option
                  key={index}
                  onClick={() => {
                    if (item?.id == "new-business") {
                      if (!userIsPro) return;
                      setShowNewBusinessModal(true);
                    } else {
                      navigateTo(item?.path);
                    }
                  }}
                >
                  <IconContainer>{item?.icon}</IconContainer>
                  <Text>{item?.title}</Text>
                  {item?.id == "new-business" && !userIsPro && <LockIcon />}
                </Option>
              ))}
            </Footer>
          )}
        </Container>
      </StyledDrawer>
      <NewBusinessInfoModal
        isVisible={showNewBusinessModal}
        setIsVisible={setShowNewBusinessModal}
      />
    </>
  );
}

const StyledDrawer = styled(Drawer)`
  &&& {
  }
`;

const Container = styled.div`
  width: 50vw;
  background-color: white;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  max-height: 50vh;
  overflow-y: scroll;
`;

const Row = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 15px;
`;

const Title = styled.p`
  flex: 1;
  font-weight: 600;
  font-size: 1.1rem;
`;

const Footer = styled.footer`
  border-top: 1px solid #c1c1c1;
`;

const Option = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px;
  gap: 10px;
  transition: all 0.1s ease-in-out;
  border-bottom: 1px solid #c1c1c1;
  :hover {
    background-color: rgba(155, 155, 155, 0.2);
  }
`;

const IconContainer = styled.div`
  border: 1px solid #c1c1c1;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.p`
  font-size: 14px;
  flex: 1;
`;

const CircularProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
`;
