import { Button } from "@mui/material";
import React from "react";
import styled from "styled-components";

interface Props {
  title: string;
  image: any;
  handleClick: () => void;
}

export default function ButtonLogin({ title, image, handleClick }: Props) {
  return (
    <StyledButton onClick={handleClick}>
      <Row>
        <Image src={image} />
        <Title>{title}</Title>
      </Row>
    </StyledButton>
  );
}

const StyledButton = styled(Button)`
  &&& {
    background-color: white;
    box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.16);
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 10px 0px;
`;

const Image = styled.img`
  height: 20px;
  width: 20px;
  object-fit: contain;
`;

const Title = styled.p`
  text-transform: none;
  color: black;
  &&& {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 13px;
  }
`;
