import ChevronRight from "@mui/icons-material/ChevronRight";
import React from "react";
import { FaCrown } from "react-icons/fa";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../../../context/store";
import { useNavigate } from "react-router-dom";

interface ContainerI {
  isCollapsed: boolean;
}

export default function ImprovePremium() {
  const isCollapsed = useSelector(
    (state: RootState) => state.layout.isSidebarCollapsed
  );
  const navigate = useNavigate();

  const handleClick = () => {
    navigate({
      pathname: "/paywall",
    });
  };

  return (
    <Container isCollapsed={isCollapsed} onClick={handleClick}>
      <ImageContainer>
        <Image src={require("../../../../images/ganventLogo.png")} />
        <IconContainer>
          <FaCrown style={{ color: "#F5DE10", fontSize: "20px" }} />
        </IconContainer>
      </ImageContainer>
      {!isCollapsed && (
        <>
          <Information>
            <Label>Mejorar a PREMIUM</Label>
            <Title>
              ¡Desbloquea todas las funcionalidades y lleva tu negocio al
              siguiente nivel!
            </Title>
          </Information>
          <ChevronRight />
        </>
      )}
    </Container>
  );
}

const Container = styled.div<ContainerI>`
  border: 1px solid #00a680;
  border-radius: 8px;
  padding-left: ${(props) => (!props.isCollapsed ? "12px" : "0px")};
  padding-right: ${(props) => (!props.isCollapsed ? "8px" : "0px")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (!props.isCollapsed ? "flex-start" : "center")};
  background-color: white;
  margin-left: 15px;
  margin-right: ${(props) => (!props.isCollapsed ? "8px" : "15px")};
  margin-top: 14px;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
  :hover {
    background-color: rgba(155, 155, 155, 0.1);
  }
`;

const ImageContainer = styled.div`
  height: 35px;
  width: 35px;
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const IconContainer = styled.div`
  position: absolute;
  top: -10px;
  right: -5px;
`;

const Information = styled.div`
  flex: 1;
  margin-left: 10px;
`;

const Label = styled.p`
  font-size: 10px;
  margin-bottom: 2px;
  font-size: 13px;
  font-weight: 700;
  color: #00a680;
`;

const Title = styled.p`
  font-size: 10px;
`;
