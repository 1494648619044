import {
  IconButton,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import styled from "styled-components";
import Modal from "@mui/material/Modal";
import Close from "@mui/icons-material/Close";

interface Props {
  setCurrencyType: React.Dispatch<React.SetStateAction<string>>;
}

export default function CurrencySelector({ setCurrencyType }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [currency, setCurrency] = useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setCurrency(event.target.value as string);
  };

  const currencyData = [
    {
      code: "ARS",
      name: "Peso argentino",
      symbol: "$",
    },
    {
      code: "UYU",
      name: "Peso uruguayo",
      symbol: "$",
    },
    {
      code: "MXN",
      name: "Peso mexicano",
      symbol: "$",
    },
    {
      code: "COP",
      name: "Peso colombiano",
      symbol: "$",
    },
    {
      code: "CUP",
      name: "Peso cubano",
      symbol: "$",
    },
    {
      code: "DOP",
      name: "Peso dominicano",
      symbol: "$",
    },
    {
      code: "CLP",
      name: "Peso chileno",
      symbol: "$",
    },
    {
      code: "PEN",
      name: "Sol peruano",
      symbol: "S/",
    },
    {
      code: "BOB",
      name: "Boliviano",
      symbol: "Bs",
    },
    {
      code: "BSD",
      name: "Dólar bahameño",
      symbol: "$",
    },
    {
      code: "VEF",
      name: "Bolivar",
      symbol: "Bs",
    },
    {
      code: "PAB",
      name: "Balboa",
      symbol: "B/.",
    },
    {
      code: "NIO",
      name: "Cordoba Oro",
      symbol: "C$",
    },
    {
      code: "CRC",
      name: "Colón costarricense",
      symbol: "₡",
    },
    {
      code: "GTQ",
      name: "Quetzal guatemalteco",
      symbol: "Q",
    },
    {
      code: "HNL",
      name: "Lempira hondureño",
      symbol: "L",
    },
    {
      code: "PYG",
      name: "Guaraní paraguayo",
      symbol: "₲",
    },
    {
      code: "PGK",
      name: "Kina",
      symbol: "K",
    },
    {
      code: "HTG",
      name: "Gourde",
      symbol: "G",
    },
    {
      code: "GYD",
      name: "Dólar guyanés",
      symbol: "$",
    },
    {
      code: "BZD",
      name: "Dólar beliceño",
      symbol: "$",
    },
    {
      code: "BRL",
      name: "Real brasileño",
      symbol: "R$",
    },
    {
      code: "EUR",
      name: "Euro",
      symbol: "€",
    },
    {
      code: "USD",
      name: "Dólar de los Estados Unidos",
      symbol: "$",
    },
    {
      code: "JMD",
      name: "Dólar jamaiquino",
      symbol: "$",
    },
    {
      code: "AUD",
      name: "Dólar australiano",
      symbol: "A$",
    },
    {
      code: "GBP",
      name: "Libra esterlina",
      symbol: "£",
    },
    {
      code: "CAD",
      name: "Dólar canadiense",
      symbol: "C$",
    },
    {
      code: "HKD",
      name: "Dolar de Hong Kong",
      symbol: "HK$",
    },
    {
      code: "KYD",
      name: "Dólar de las Islas Caimán",
      symbol: "$",
    },
    {
      code: "JPY",
      name: "Yen japonés",
      symbol: "¥",
    },
    {
      code: "KRW",
      name: "South Korean Won",
      symbol: "₩",
    },
  ];

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const onChangeSelected = (newCurrency: string) => {
    setCurrency(newCurrency);
    setIsOpen(false);
    setCurrencyType(newCurrency);
  };

  const getSelectedCurrency = () => {
    if (!currency) return "Selecciona el tipo de moneda";
    const index = currencyData?.findIndex((item) => item.code === currency);
    if (index === -1) return "Selecciona el tipo de moneda";
    return currencyData[index].name;
  };

  return (
    <>
      <Container onClick={handleClick}>
        <Text id="controlled-open-selected-label">{getSelectedCurrency()}</Text>
        <FiChevronDown />
      </Container>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Content>
          <StyledPaper>
            <Title>Selecciona el tipo de moneda</Title>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </StyledPaper>
          <Scroll>
            {currencyData.map((item, index) => (
              <Item key={index} onClick={() => onChangeSelected(item.code)}>
                <ItemText>{item.name}</ItemText>
              </Item>
            ))}
          </Scroll>
        </Content>
      </Modal>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #c1c1c1;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 12px 10px;
  cursor: pointer;

  transition: all 0.1s ease;

  :hover {
    background-color: rgba(155, 155, 155, 0.1);
  }
`;

const Text = styled.p`
  flex: 1;
  font-size: 15px;
`;

const Content = styled.div`
  background-color: white;
  width: 80vw;
  max-width: 600px;
  height: 90vh;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
`;

const Scroll = styled.div`
  overflow-y: scroll;
  flex: 1;
`;

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 14px;
  padding-left: 14px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
`;

const Title = styled.p`
  padding: 25px 0px;
  flex: 1;
  font-weight: 600;
`;

const Item = styled.div`
  cursor: pointer;
  transition: all 0.1s ease;
  padding: 20px;
  border-bottom: 1px solid #c1c1c1;
  overflow: hidden;

  :hover {
    background-color: rgba(155, 155, 155, 0.3);
  }
`;

const ItemText = styled.p`
  text-align: center;
`;
