import React, { useEffect, useState } from "react";
import styled from "styled-components";
import StepBar from "./StepBar";
import Button from "@mui/material/Button";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { MdStore } from "react-icons/md";
import { IoMdInformationCircleOutline } from "react-icons/io";
import InputWithLabel from "../login/InputWithLabel";

interface Props {
  stepNumber: number;
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  formError: any;
  setFormError: React.Dispatch<React.SetStateAction<any>>;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  isVisible: boolean;
}

export default function Step1({
  stepNumber,
  formData,
  setFormData,
  formError,
  setFormError,
  currentStep,
  setCurrentStep,
  isVisible,
}: Props) {
  const [name, setName] = useState("");

  useEffect(() => {
    setFormError({ ...formError, business: false });
    setFormData({ ...formData, business: name?.toLowerCase().trim() });
  }, [name]);

  const handleContinue = () => {
    let errors = { ...formError };
    if (!name) {
      errors.business = true;
      errors.businessMessage = "El nombre del negocio es obligatorio";
    } else {
      setCurrentStep(1);
    }
    setFormError(errors);
  };

  if (!isVisible) {
    return <></>;
  }

  return (
    <Container>
      <StepBar stepNumber={stepNumber} />
      <Title>Crear negocio</Title>
      <Content>
        <InputWithLabel
          label="Nombre de tu negocio *"
          placeholder="Nombre de tu negocio (Obligatorio)"
          labelIcon={<MdStore />}
          rightLabelIcon={<IoMdInformationCircleOutline />}
          moreInformation="Este nombre será visible para tus clientes en tu tienda online"
          error={formError?.business}
          type="text"
          setValue={setName}
          value={name}
        />
        {formError?.business && formError?.businessMessage && (
          <ErrorText>{formError?.businessMessage}</ErrorText>
        )}
        <MainButton
          endIcon={<ChevronRight style={{ color: "white" }} />}
          onClick={handleContinue}
        >
          <MainButtonTitle>Continuar</MainButtonTitle>
        </MainButton>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Title = styled.p`
  text-align: center;
  margin-top: 35px;
  font-size: 1.9rem;
  font-weight: 500;
`;

const Content = styled.div`
  margin-left: 70px;
  margin-right: 70px;
  margin-top: 35px;
`;

const Row = styled.div``;

const MainButton = styled(Button)`
  &&& {
    background-color: #00a680;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 25px;
  }
`;

const MainButtonTitle = styled.p`
  text-transform: none;
  color: white;
  &&& {
    font-size: 15px;
    font-family: "Montserrat";
  }
`;

const ErrorText = styled.p`
  color: red;
  font-size: 12px;
  margin-top: 15px;
`;
