import { combineReducers } from "@reduxjs/toolkit";
import transactionsSlice from "./reducers/transactions";
import layoutSlice from "./reducers/layout";
import categoriesSlice from "./reducers/categories";
import availableProductsSlice from "./reducers/availableProducts";
import tagsSlice from "./reducers/tags";
import notAvailableProductsSlice from "./reducers/notAvailableProducts";
import availableProductsByNameSlice from "./reducers/availableProductsByName";
import availableProductsByStockSlice from "./reducers/availableProductsByStock";
import salesSlice from "./reducers/sales";
import salesReceivableSlice from "./reducers/salesReceivable";
import incomesSlice from "./reducers/incomes";
import incomesReceivableSlice from "./reducers/incomesReceivable";
import clientsSlice from "./reducers/clients";
import providersSlice from "./reducers/providers";
import expensesSlice from "./reducers/expenses";
import expensesPayableSlice from "./reducers/expensesPayable";
import purchasesSlice from "./reducers/purchases";
import purchasesPayableSlice from "./reducers/purchasesPayable";

const rootReducer = combineReducers({
  transactions: transactionsSlice,
  layout: layoutSlice,
  categories: categoriesSlice,
  availableProducts: availableProductsSlice,
  notAvailableProducts: notAvailableProductsSlice,
  tags: tagsSlice,
  availableProductsByName: availableProductsByNameSlice,
  availableProductsByStock: availableProductsByStockSlice,
  incomes: incomesSlice,
  incomesReceivable: incomesReceivableSlice,
  sales: salesSlice,
  salesReceivable: salesReceivableSlice,
  clients: clientsSlice,
  providers: providersSlice,
  expenses: expensesSlice,
  expensesPayable: expensesPayableSlice,
  purchases: purchasesSlice,
  purchasesPayable: purchasesPayableSlice,
});

export default rootReducer;
