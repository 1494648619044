import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../../context/store";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import { MdStore } from "react-icons/md";
import { setIsBusinessesModalVisible } from "../../../context/reducers/layout";
import { getAuth } from "firebase/auth";
import employeesRoles from "../../../utils/employeesRoles";

interface Props {
  setIsBusinessModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function BusinessSelector({}: Props) {
  const selectedBusiness = useSelector(
    (state: RootState) => state.layout.selectedBusiness
  );
  const employee_business = useSelector(
    (state: RootState) => state.layout.employee_business
  );
  const dispatch = useDispatch();
  const user = getAuth().currentUser;

  const handleClick = () => {
    dispatch(
      setIsBusinessesModalVisible({
        isBusinessesModalVisible: true,
      })
    );
  };

  const getRole = () => {
    if (user?.uid?.trim() == selectedBusiness?.userId) {
      return "Propietario";
    }
    let roles = employee_business?.role;
    let auxRoles = "";
    if (roles === "employee") {
      return "Empleado";
    }
    if (roles === "") {
      return "Sin rol";
    }
    if (Array.isArray(roles)) {
      roles.forEach((rolItem, i) => {
        employeesRoles.forEach((employee, j) => {
          if (i > 0 && rolItem === employee.role) {
            auxRoles += `, ${employee.name}`;
          } else if (rolItem == employee.role) {
            auxRoles += employee.name;
          }
        });
      });
      return auxRoles;
    }
    return "Sin rol";
  };

  return (
    <Container onClick={handleClick}>
      <ImageContainer>
        {selectedBusiness?.images !== undefined &&
        selectedBusiness?.images[0] ? (
          <Image src={selectedBusiness?.images[0]} />
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#00a680",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "15px",
            }}
          >
            <MdStore style={{ color: "white", fontSize: "20px" }} />
          </div>
        )}
      </ImageContainer>
      <InformationContainer>
        <Name>
          {capitalizeFirstLetter(
            selectedBusiness?.shopName
              ? selectedBusiness?.shopName
              : selectedBusiness?.localName
              ? selectedBusiness?.localName
              : selectedBusiness?.name?.local
              ? selectedBusiness?.name?.local
              : ""
          )}
        </Name>
        <Role>{getRole()}</Role>
      </InformationContainer>
      <ArrowDropDown style={{ color: "black" }} />
    </Container>
  );
}

const Container = styled.div`
  margin-left: 10px;
  border: 1px solid #c1c1c1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  padding-right: 10px;
  height: 50px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  :hover {
    background-color: rgba(155, 155, 155, 0.1);
  }
`;

const ImageContainer = styled.div`
  height: 30px;
  width: 30px;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
`;

const InformationContainer = styled.div`
  margin-left: 8px;
  margin-right: 8px;
`;

const Name = styled.p`
  font-size: 12px;
  font-weight: 500;
`;

const Role = styled.p`
  font-size: 11px;
  font-weight: 400;
`;
