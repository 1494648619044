import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth, onAuthStateChanged } from "../firebase";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import FeaturesCarousel from "../components/login/FeaturesCarousel";
import GanventComponent from "../components/login/featuresCarousel/GanventComponent";
import ImproveSales from "../components/login/featuresCarousel/ImproveSales";
import AllInOne from "../components/login/featuresCarousel/AllInOne";
import ClientsSupport from "../components/login/featuresCarousel/ClientsSupport";
import Ratings from "../components/login/featuresCarousel/Ratings";
import SignUp from "../components/login/SignUp";
import SignIn from "../components/login/SignIn";
import ForgotPassword from "../components/login/ForgotPassword";

export default function Login() {
  const [isGettingUser, setIsGettingUser] = useState(true);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [login, setLogin] = useState<boolean | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const suscriber = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLogin(true);
        setCurrentUser(user);
      } else {
        setLogin(false);
        setCurrentUser(null);
      }
      setIsGettingUser(false);
    });
    return () => {
      suscriber();
    };
  }, []);

  useEffect(() => {
    if (!isGettingUser) {
      if (currentUser !== null) {
        navigate({
          pathname: "/",
        });
      } else {
        navigate({
          pathname: "/login",
        });
      }
    }
  }, [isGettingUser, currentUser]);

  if (isGettingUser) {
    return (
      <LoadingContainer>
        <CircularProgress size={60} />
      </LoadingContainer>
    );
  }

  const options = [
    <GanventComponent />,
    <ImproveSales />,
    <AllInOne />,
    <ClientsSupport />,
    <Ratings />,
  ];

  return (
    <Container>
      <LeftColumn>
        {location?.pathname === "/signup" ? (
          <SignUp />
        ) : location?.pathname === "/login" ? (
          <SignIn />
        ) : location?.pathname === "/forgot-password" ? (
          <ForgotPassword />
        ) : (
          <></>
        )}
      </LeftColumn>
      <RightColumn>
        <FeaturesCarousel options={options} />
      </RightColumn>
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 600px 1fr;
`;

const LeftColumn = styled.div``;

const RightColumn = styled.div`
  height: 100vh;
  position: fixed;
  right: 0px;
`;

const LoadingContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;
