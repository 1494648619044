export default [
  {
    code: "ARS",
    name: "Peso argentino",
    symbol: "$",
  },
  {
    code: "UYU",
    name: "Peso uruguayo",
    symbol: "$",
  },
  {
    code: "MXN",
    name: "Peso mexicano",
    symbol: "$",
  },
  {
    code: "COP",
    name: "Peso colombiano",
    symbol: "$",
  },
  {
    code: "CUP",
    name: "Peso cubano",
    symbol: "$",
  },
  {
    code: "DOP",
    name: "Peso dominicano",
    symbol: "$",
  },
  {
    code: "CLP",
    name: "Peso chileno",
    symbol: "$",
  },
  {
    code: "PEN",
    name: "Sol peruano",
    symbol: "S/",
  },
  {
    code: "BOB",
    name: "Boliviano",
    symbol: "Bs",
  },
  {
    code: "BSD",
    name: "Dólar bahameño",
    symbol: "$",
  },
  {
    code: "VEF",
    name: "Bolivar",
    symbol: "Bs",
  },
  {
    code: "PAB",
    name: "Balboa",
    symbol: "B/.",
  },
  {
    code: "NIO",
    name: "Cordoba Oro",
    symbol: "C$",
  },
  {
    code: "CRC",
    name: "Colón costarricense",
    symbol: "₡",
  },
  {
    code: "GTQ",
    name: "Quetzal guatemalteco",
    symbol: "Q",
  },
  {
    code: "HNL",
    name: "Lempira hondureño",
    symbol: "L",
  },
  {
    code: "PYG",
    name: "Guaraní paraguayo",
    symbol: "₲",
  },
  {
    code: "PGK",
    name: "Kina",
    symbol: "K",
  },
  {
    code: "HTG",
    name: "Gourde",
    symbol: "G",
  },
  {
    code: "GYD",
    name: "Dólar guyanés",
    symbol: "$",
  },
  {
    code: "BZD",
    name: "Dólar beliceño",
    symbol: "$",
  },
  {
    code: "BRL",
    name: "Real brasileño",
    symbol: "R$",
  },
  {
    code: "EUR",
    name: "Euro",
    symbol: "€",
  },
  {
    code: "USD",
    name: "Dólar de los Estados Unidos",
    symbol: "$",
  },
  {
    code: "JMD",
    name: "Dólar jamaiquino",
    symbol: "$",
  },
  {
    code: "AUD",
    name: "Dólar australiano",
    symbol: "A$",
  },
  {
    code: "GBP",
    name: "Libra esterlina",
    symbol: "£",
  },
  {
    code: "CAD",
    name: "Dólar canadiense",
    symbol: "C$",
  },
  {
    code: "HKD",
    name: "Dolar de Hong Kong",
    symbol: "HK$",
  },
  {
    code: "KYD",
    name: "Dólar de las Islas Caimán",
    symbol: "$",
  },
  {
    code: "JPY",
    name: "Yen japonés",
    symbol: "¥",
  },
  {
    code: "KRW",
    name: "South Korean Won",
    symbol: "₩",
  },
];
