import { createSlice } from "@reduxjs/toolkit";

interface InitialStateI {
  providers: any[] | null;
  canLoadMore: boolean;
  isLoadingMoreData: boolean;
  lastProvider: any | null;
  isCreating: boolean;
}

const initialState: InitialStateI = {
  providers: null,
  canLoadMore: true,
  isLoadingMoreData: false,
  lastProvider: null,
  isCreating: false,
};

const providersSlice = createSlice({
  name: "providers",
  initialState,
  reducers: {
    setProviders: (state, action) => {
      state.providers = action.payload.providers;
    },
    setLastProvider: (state, action) => {
      state.lastProvider = action.payload.lastProvider;
    },
    setCanLoadMore: (state, action) => {
      state.canLoadMore = action.payload.canLoadMore;
    },
    setIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
    setIsCreating: (state, action) => {
      state.isCreating = action.payload.isCreating;
    },
  },
});

export const {
  setCanLoadMore,
  setIsCreating,
  setIsLoadingMoreData,
  setLastProvider,
  setProviders,
} = providersSlice.actions;
export default providersSlice.reducer;
