import { CircularProgress, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { setChangingBusiness } from "../../context/reducers/layout";
import { useNavigate } from "react-router-dom";

export default function ChangingBusiness() {
  const [isOpen, setIsOpen] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      dispatch(
        setChangingBusiness({
          changingBusiness: false,
        })
      );
      navigate({
        pathname: "/",
      });
    }, 2000);
  }, []);

  return (
    <Modal
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Content>
        <CircularProgress style={{ color: "#00a680" }} size={55} />
        <Title>Cambiando de negocio...</Title>
      </Content>
    </Modal>
  );
}

const Content = styled.div`
  background-color: white;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.p`
  margin-top: 20px;
  font-size: 1.4rem;
  font-weight: 600;
`;
