import { createSlice } from "@reduxjs/toolkit";

interface InitialStateI {
  expensesPayable: any[] | null;
  canLoadMore: boolean;
  isLoadingMoreData: boolean;
  lastExpensePayable: any | null;
  isCreating: boolean;
}

const initialState: InitialStateI = {
  expensesPayable: null,
  canLoadMore: true,
  isLoadingMoreData: false,
  lastExpensePayable: null,
  isCreating: false,
};

const expensesPayableSlice = createSlice({
  name: "expensesPayable",
  initialState,
  reducers: {
    setExpensesPayable: (state, action) => {
      state.expensesPayable = action.payload.expensesPayable;
    },
    setCanLoadMore: (state, action) => {
      state.canLoadMore = action.payload.canLoadMore;
    },
    setIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
    setIsCreating: (state, action) => {
      state.isCreating = action.payload.isCreating;
    },
    setLastExpensePayable: (state, action) => {
      state.lastExpensePayable = action.payload.lastExpensePayable;
    },
  },
});

export const {
  setCanLoadMore,
  setExpensesPayable,
  setIsCreating,
  setIsLoadingMoreData,
  setLastExpensePayable,
} = expensesPayableSlice.actions;
export default expensesPayableSlice.reducer;
