import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../context/store";
import {
  setCollaboration,
  setCurrentPlan,
  setEmployeeBusiness,
  setUserIsPro,
} from "../../context/reducers/layout";
import {
  auth,
  collection,
  db,
  getDocs,
  onSnapshot,
  query,
  where,
} from "../../firebase";
import { getCustomerInfo } from "../../api/revenueCat/customers";
import { ENTITLEMENT_ID } from "../../utils/globalConstants";

export default function EmployeeBusiness() {
  const selectedBusiness = useSelector(
    (state: RootState) => state.layout.selectedBusiness
  );
  const employee_business = useSelector(
    (state: RootState) => state.layout.employee_business
  );
  const user = auth.currentUser;
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedBusiness?.id !== "") {
      if (selectedBusiness?.userId != user?.uid) {
        const employeeBusinessCollection = collection(db, "employee-business");

        const q = query(
          employeeBusinessCollection,
          where("userId", "==", user?.uid),
          where("businessId", "==", selectedBusiness?.id)
        );

        getDocs(q)
          .then((response) => {
            if (!response.empty) {
              let objectRelation = response.docs[0];

              onSnapshot(
                objectRelation.ref,
                (snapshot) => {
                  if (snapshot.exists()) {
                    let relationData = snapshot.data();
                    let auxObject = {
                      userId: user?.uid,
                      businessId: selectedBusiness?.id,
                      role: relationData.role,
                      access: true,
                    };
                    dispatch(
                      setEmployeeBusiness({
                        employee_business: auxObject,
                      })
                    );
                  } else {
                    let auxObject = {
                      userId: user?.uid,
                      businessId: selectedBusiness?.id,
                      role: [],
                      access: false,
                    };

                    dispatch(
                      setEmployeeBusiness({
                        employee_business: auxObject,
                      })
                    );
                  }
                },
                (err) => {}
              );
            } else {
              let auxObject = {
                userId: user?.uid,
                businessId: selectedBusiness?.id,
                role: [],
                access: false,
              };

              dispatch(
                setEmployeeBusiness({
                  employee_business: auxObject,
                })
              );
            }
          })
          .catch(() => {});
      } else {
        let auxObject = {
          userId: user?.uid,
          businessId: selectedBusiness?.id,
          role: ["OWNER"],
          access: true,
        };
        dispatch(
          setEmployeeBusiness({
            employee_business: auxObject,
          })
        );
      }
    }
  }, [selectedBusiness]);

  const getCollaborations = () => {
    const collaborationsCollection = collection(db, "collaborations");

    getDocs(collaborationsCollection)
      .then((snapshot) => {
        if (!snapshot.empty) {
          let tempArray: any[] = [];
          snapshot.forEach((doc) => {
            let object = doc.data();
            object.id = doc.id;
            tempArray.push(object);
          });
          let band = false;
          tempArray.forEach((doc) => {
            if (doc?.email == user?.email) {
              band = true;
            }
          });
          // @ts-ignore: Unreachable code error
          if (band == true) {
            dispatch(
              setCollaboration({
                collaboration: band,
              })
            );
            dispatch(
              setUserIsPro({
                userIsPro: true,
              })
            );
          }
        } else {
          dispatch(
            setCollaboration({
              collaboration: false,
            })
          );
          dispatch(
            setUserIsPro({
              userIsPro: false,
            })
          );
        }
      })
      .catch(() => {});
  };

  const callApiToGetCustomerInfo = () => {
    const app_user_id = user?.uid;
    getCustomerInfo(app_user_id as string)
      .then((res) => {
        const purchaserInfo = res?.subscriber;
        if (
          typeof purchaserInfo.entitlements.active[ENTITLEMENT_ID] !==
          "undefined"
        ) {
          dispatch(
            setUserIsPro({
              userIsPro: true,
            })
          );
          dispatch(
            setCurrentPlan({
              currentPlan: {
                plan: purchaserInfo?.entitlements?.active[ENTITLEMENT_ID]
                  ?.productIdentifier,
              },
            })
          );
        } else {
          dispatch(
            setUserIsPro({
              userIsPro: true,
            })
          );
          dispatch({
            type: "SET_CURRENT_PLAN",
            currentPlan: {
              plan: "free",
            },
          });
          getCollaborations();
        }
      })
      .catch((err) => {
        dispatch(
          setUserIsPro({
            userIsPro: false,
          })
        );
        dispatch(
          setCurrentPlan({
            currentPlan: null,
          })
        );
        getCollaborations();
      });
  };

  useEffect(() => {
    callApiToGetCustomerInfo();
  }, []);

  return <></>;
}
