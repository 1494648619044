import React, { useState } from "react";
import styled from "styled-components";
import { IoMdCalendar } from "react-icons/io";
import { Button, IconButton } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs, { Dayjs } from "dayjs";
import Modal from "@mui/material/Modal";
import Close from "@mui/icons-material/Close";
import moment from "moment";

interface Props {
  expirationDate: any;
  setExpirationDate: React.Dispatch<React.SetStateAction<any>>;
}

export default function ExpirationDate({
  expirationDate,
  setExpirationDate,
}: Props) {
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(true);
  };

  const handleClose = () => {
    setIsVisible(false);
    setExpirationDate(null);
  };

  const deleteDate = () => {
    setExpirationDate(null);
  };

  const getSelectedDate = () => {
    if (expirationDate == null) return "";
    return moment(expirationDate?.toDate()).format("DD/MMM/YYYY");
  };

  return (
    <>
      <Container>
        <IconContainer>
          <IoMdCalendar />
        </IconContainer>
        <Text>Agregar fecha de caducidad</Text>
        {expirationDate == null && (
          <StyledButton onClick={handleClick}>
            <StyledButtonText>Seleccionar fecha</StyledButtonText>
          </StyledButton>
        )}
      </Container>
      {expirationDate && (
        <SelectedDateContainer>
          <LeftContainer>
            <Label>Fecha de caducidad seleccionada</Label>
            <SelectedDate>{getSelectedDate()}</SelectedDate>
          </LeftContainer>
          <IconButton onClick={deleteDate}>
            <Close />
          </IconButton>
        </SelectedDateContainer>
      )}
      <Modal
        open={isVisible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Content>
          <Header>
            <Title>Selecciona la fecha de caducidad</Title>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Header>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              value={expirationDate}
              onChange={(newValue) => setExpirationDate(newValue)}
            />
          </LocalizationProvider>
          <Footer>
            <StyledButtonConfirm
              onClick={() => {
                setIsVisible(false);
              }}
            >
              <StyledButtonConfirmText>Confirmar</StyledButtonConfirmText>
            </StyledButtonConfirm>
          </Footer>
        </Content>
      </Modal>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
`;

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  border: 2px solid #000;
  border-radius: 10px;
  box-shadow: 24;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.p`
  font-size: 13px;
  color: #000000;
  flex: 1;
`;

const StyledButton = styled(Button)`
  border: 1px solid #00a680;
  padding: 10px 14px;
`;

const StyledButtonText = styled.p`
  &&& {
    text-transform: none;
    font-family: "Montserrat";
    font-size: 13px;
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 15px;
  padding-left: 20px;
  gap: 10px;
`;

const Title = styled.p`
  flex: 1;
  font-weight: 600;
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  margin-bottom: 20px;
`;

const StyledButtonConfirm = styled(Button)`
  &&& {
    background-color: #00a680;
    padding: 10px 15px;
  }
`;

const StyledButtonConfirmText = styled.p`
  &&& {
    text-transform: none;
    color: white;
    font-family: "Montserrat";
  }
`;

const SelectedDateContainer = styled.div`
  margin-top: 15px;
  border: 1px solid #00a680;
  border-radius: 10px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const LeftContainer = styled.div`
  flex: 1;
`;

const Label = styled.p`
  font-size: 13px;
`;

const SelectedDate = styled.p`
  font-size: 15px;
  font-weight: 500;
  margin-top: 3px;
`;
