interface Props {
  paddingRight?: number;
  paddingLeft?: number;
  marginTop?: number;
  marginBottom?: number;
}

export default function CustomDivider({
  paddingRight = 0,
  paddingLeft = 0,
  marginTop = 0,
  marginBottom = 0,
}: Props) {
  return (
    <div
      style={{
        backgroundColor: "rgba(155,155,155,0.3)",
        height: "0.6px",
        paddingLeft: `${paddingLeft}px`,
        paddingRight: `${paddingRight}px`,
        marginTop: `${marginTop}px`,
        marginBottom: `${marginBottom}px`,
      }}
    ></div>
  );
}
