import React, { useEffect, useRef, useState } from "react";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { LuShapes } from "react-icons/lu";
import { RootState } from "../../../context/store";
import { collection, db, onSnapshot, query, where } from "../../../firebase";
import {
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Paper,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import LockIcon from "@mui/icons-material/Lock";

interface Props {
  selectedCategoryId: string;
  subcategorysIds: any[];
  setSubcategorysIds: React.Dispatch<React.SetStateAction<any[]>>;
}

const CATEGORY_NOT_SELECTED = "category not selected";

export default function SubcategorySelector({
  selectedCategoryId,
  setSubcategorysIds,
  subcategorysIds,
}: Props) {
  const selectedBusiness = useSelector(
    (state: RootState) => state.layout.selectedBusiness
  );
  const userIsPro = useSelector((state: RootState) => state.layout.userIsPro);
  const [showModal, setShowModal] = useState(false);
  const [subcategories, setSubcategories] = useState<any[] | null>(null);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (selectedCategoryId !== CATEGORY_NOT_SELECTED) {
      setSubcategories(null);
      setSubcategorysIds([]);
      const subcategoriesRef = collection(
        db,
        "business",
        selectedBusiness?.id,
        "subcategorys"
      );

      const q = query(
        subcategoriesRef,
        where("categoryId", "==", selectedCategoryId)
      );

      const suscriber = onSnapshot(
        q,
        (snapshot) => {
          if (!snapshot.empty) {
            let subcategoryArray: any[] = [];
            snapshot.forEach((doc) => {
              let object = doc.data();
              object.id = doc.id;
              subcategoryArray.push(object);
            });
            if (isMounted.current) {
              setSubcategories(subcategoryArray);
            }
          } else {
            console.log("VACÍO");
            if (isMounted.current) {
              setSubcategories([]);
            }
          }
        },
        (err) => {}
      );

      return () => {
        suscriber();
      };
    }
  }, [selectedCategoryId]);

  const getSelectedSubcategory = () => {
    if (subcategorysIds?.length == 0)
      return "Selecciona la subcategoría de este producto";
    let index = subcategories?.findIndex(
      (item) => item.id == subcategorysIds[0]
    );
    if (index == -1) return "Selecciona la subcategoría de este producto";
    if (subcategories == null) return "";
    return capitalizeFirstLetter(subcategories[index as number]?.name ?? "");
  };

  const handleClick = () => {
    if (subcategories == null) return;
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const onChangeSelected = (item: any) => {
    setSubcategorysIds([item?.id]);
    setShowModal(false);
  };

  const deleteSubcategory = () => {
    setSubcategorysIds([]);
  };

  return (
    <>
      <Header>
        <Row style={{ gap: "5px" }}>
          <LuShapes />
          <Label>Subcategoría del producto</Label>
        </Row>
        {!userIsPro && <LockIcon />}
      </Header>
      {!userIsPro ? (
        <Premium>
          Las <Highlight>SUBCATEGORÍAS</Highlight> son parte de Ganvent PREMIUM
        </Premium>
      ) : selectedCategoryId == CATEGORY_NOT_SELECTED ? (
        <>
          <div style={{ marginTop: "12px" }} />
          <NotFound>
            Para clasificar el producto en una SUBCATEGORÍA, primero debes
            seleccionar la categoría
          </NotFound>
        </>
      ) : subcategories == null ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              marginTop: "15px",
              marginBottom: "6px",
            }}
          >
            <CircularProgress style={{ color: "#00a680" }} size={25} />
          </div>
        </>
      ) : subcategories?.length == 0 ? (
        <>
          <div style={{ marginTop: "15px" }} />
          <NotFound>La categoría seleccionada no tiene subcategorías.</NotFound>
          <NotFound>
            Para agregar una nueva subcategoría, dirígete a los detalles de esta
            categoría y agrega la subcategoría que deseas
          </NotFound>
        </>
      ) : (
        <Row>
          <Container onClick={handleClick}>
            <Text id="controlled-open-selected-label">
              {getSelectedSubcategory()}
            </Text>
            <FiChevronDown />
          </Container>
          {subcategorysIds?.length > 0 && (
            <IconButton
              onClick={deleteSubcategory}
              style={{
                borderWidth: 1,
                borderColor: "red",
                borderStyle: "solid",
                marginLeft: "10px",
              }}
            >
              <Close style={{ color: "red" }} />
            </IconButton>
          )}
        </Row>
      )}
      {subcategories !== null && subcategories?.length > 0 && (
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={showModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Content>
            <StyledPaper>
              <Title>Selecciona la subcategoría de este producto</Title>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </StyledPaper>
            <Scroll>
              {subcategories?.map((item, index) => (
                <Item key={index} onClick={() => onChangeSelected(item)}>
                  <ItemText>{capitalizeFirstLetter(item.name ?? "")}</ItemText>
                  <FiChevronRight />
                </Item>
              ))}
            </Scroll>
          </Content>
        </Modal>
      )}
    </>
  );
}

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin-bottom: 4px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const Label = styled.p`
  font-size: 13px;
  color: #000000;
  flex: 1;
`;

const NotFound = styled.p`
  text-align: center;
  margin: 0px 40px;
  margin-top: 8px;
  font-size: 13px;
  font-weight: 500;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #c1c1c1;
  flex: 1;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 12px 10px;
  cursor: pointer;

  transition: all 0.1s ease;

  :hover {
    background-color: rgba(155, 155, 155, 0.1);
  }
`;

const Text = styled.p`
  flex: 1;
  font-size: 15px;
`;

const Content = styled.div`
  background-color: white;
  width: 80vw;
  max-width: 600px;
  height: 90vh;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
`;

const Scroll = styled.div`
  overflow-y: scroll;
  flex: 1;
`;

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 14px;
  padding-left: 14px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
`;

const Title = styled.p`
  padding: 25px 0px;
  flex: 1;
  font-weight: 600;
`;

const Item = styled.div`
  cursor: pointer;
  transition: all 0.1s ease;
  padding: 20px;
  border-bottom: 1px solid #c1c1c1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  :hover {
    background-color: rgba(155, 155, 155, 0.3);
  }
`;

const ItemText = styled.p`
  text-align: center;
  flex: 1;
`;

const ButtonLoadMoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ButtonLoadMore = styled(Button)`
  &&& {
    border: 1px solid #00a680;
    padding: 15px 20px;
    border-radius: 10px;
    margin-top: 12px;
  }
`;

const ButtonLoadMoreText = styled.p`
  &&& {
    text-transform: none;
    font-family: "Montserrat";
  }
`;

const StyledButton = styled(Button)`
  position: fixed;
  bottom: 100px;
  right: 30px;
  &&& {
    background-color: #00a680;
    padding: 12px 15px;
    border-radius: 12px;
    box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.16);
  }
`;

const StyledButtonText = styled.p`
  text-transform: none;
  color: white;
  &&& {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 1rem;
  }
`;

const Highlight = styled.span`
  font-weight: 600;
  color: #00a680;
`;

const Premium = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin-top: 12px;
`;
