import { useState } from "react";
import styled from "styled-components";
import { Button, CircularProgress, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InputWithLabel from "./InputWithLabel";
import { validationEmail } from "../../utils/validationEmail";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";

export default function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [formError, setFormError] = useState<any>({});
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const navigate = useNavigate();

  const sendEmail = async () => {
    let errors: any = {};
    if (!email) {
      errors.email = "El correo es obligatorio";
    } else if (!validationEmail(email)) {
      errors.email = "Correo inválido, intenta con otro";
    } else {
      setIsLoading(true);
      sendPasswordResetEmail(auth, email)
        .then(() => {
          setIsLoading(false);
          setShowSnackbar(true);
          setSnackBarMessage("Correo enviado ✅");
        })
        .catch((err) => {
          let message = "Ocurrió un error";
          switch (err.code) {
            case "auth/user-not-found":
              message = "Correo no registrado";
              break;
            // @ts-ignore: Unreachable code error
            case "auth/unknown":
              message = "Ocurrió un error";
            default:
              break;
          }
          setIsLoading(false);
          setShowSnackbar(true);
          setSnackBarMessage(message);
        });
    }
    setFormError(errors);
  };

  const handleClickSignIn = () => {
    navigate("/login");
  };

  return (
    <Container>
      <Header>
        <LogoContainer>
          <Image src={require("../../images/ganventLogo.png")} />
        </LogoContainer>
        <Ganvent>Ganvent</Ganvent>
      </Header>
      <Title>Recuperar</Title>
      <Title>contraseña</Title>
      <InputsContainer>
        <InputWithLabel
          label="Correo"
          placeholder="Correo"
          type="text"
          error={formError?.email}
          value={email}
          setValue={setEmail}
        />
        {formError?.email && <ErrorText>{formError?.email}</ErrorText>}
      </InputsContainer>
      <MainButton disabled={isLoading} onClick={sendEmail}>
        {isLoading ? (
          <CircularProgress style={{ color: "white" }} size={20} />
        ) : (
          <MainButtonTitle>Enviar correo</MainButtonTitle>
        )}
      </MainButton>
      <Row>
        <Text>Ya tengo una cuenta</Text>
        <Button onClick={handleClickSignIn}>
          <ButtonTitle>Inicia Sesión</ButtonTitle>
        </Button>
      </Row>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3200}
        onClose={() => setShowSnackbar(false)}
        message={snackBarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </Container>
  );
}

const Container = styled.div`
  padding-top: 55px;
  padding-left: 60px;
  padding-right: 60px;
  margin-bottom: 40px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 32px;
`;

const LogoContainer = styled.div`
  height: 50px;
  width: 50px;
  position: relative;
`;

const Image = styled.img`
  object-fit: contain;
  height: 100%;
  width: 100%;
`;

const Ganvent = styled.h1`
  font-size: 1.4rem;
`;

const Title = styled.p`
  font-size: 2.1rem;
  font-weight: 500;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 8px;
`;

const Or = styled.p`
  text-align: center;
  font-size: 1rem;
  margin-top: 20px;
  margin-bottom: 12px;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;
  margin-top: 30px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1px;
`;

const Text = styled.p`
  font-size: 14px;
  font-family: "Montserrat";
`;

const ButtonTitle = styled.p`
  text-transform: none;
  &&& {
    font-size: 14px;
    font-family: "Montserrat";
  }
`;

const MainButton = styled(Button)`
  &&& {
    background-color: #00a680;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
`;

const MainButtonTitle = styled.p`
  text-transform: none;
  color: white;
  &&& {
    font-size: 15px;
    font-family: "Montserrat";
  }
`;

const ErrorText = styled.p`
  color: red;
  font-size: 12px;
`;
