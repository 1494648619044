import React from "react";
import styled from "styled-components";

interface Props {
  option: any;
}

export default function SubOptionItem({ option }: Props) {
  const handleClick = () => {
    if (option?.url) {
      window.open(option?.url, "_blank");
    }
  };

  return (
    <Container onClick={handleClick}>
      <IconContainer
        style={{
          backgroundColor: option?.color ? option?.color : "transparent",
        }}
      >
        {option?.icon}
      </IconContainer>
      <Title>{option?.title}</Title>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
  :hover {
    background-color: rgba(155, 155, 155, 0.2);
  }
`;

const IconContainer = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Title = styled.p`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 3px;
  flex: 1;
`;
