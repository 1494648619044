import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../context/store";
import {
  setGeneralSnackBarMessage,
  setIsNewProductModalVisible,
  setShowGeneralSnackbar,
} from "../../context/reducers/layout";
import ArrowBack from "@mui/icons-material/ArrowBack";
import InputWithLabel from "../login/InputWithLabel";
import { IoDocumentTextOutline } from "react-icons/io5";
import { MdOutlineFormatListNumbered } from "react-icons/md";
import { FaDollarSign } from "react-icons/fa6";
import { BsCash } from "react-icons/bs";
import CustomDivider from "../global/CustomDivider";
import { RiFileMarkedLine } from "react-icons/ri";
import SelectedCurrency from "./newProduct/SelectedCurrency";
import ExpirationDate from "./newProduct/ExpirationDate";
import BarCode from "./newProduct/BarCode";
import TagsList from "./newProduct/TagsList";
import Features from "./newProduct/Features";
import CategorySelector from "./newProduct/CategorySelector";
import Video from "./newProduct/Video";
import SubcategorySelector from "./newProduct/SubcategorySelector";
import { getAuth } from "firebase/auth";
import {
  collection,
  db,
  doc,
  increment,
  onSnapshot,
  storage,
  writeBatch,
} from "../../firebase";
import { Dayjs } from "dayjs";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CheckUsernameModal from "../global/CheckUsernameModal";
import ImagePickerModal from "./newProduct/ImagePickerModal";
import ProductPhoto from "./newProduct/ProductPhoto";
import uuid from "random-uuid-v4";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import PremiumModal from "../global/PremiumModal";

const CATEGORY_NOT_SELECTED = "category not selected";
const PRODUCTS_MAX_QUANTITY = 35;

export default function NewProductModal() {
  const selectedBusiness = useSelector(
    (state: RootState) => state.layout.selectedBusiness
  );
  const isVisible = useSelector(
    (state: RootState) => state.layout.isNewProductModalVisible
  );
  const userIsPro = useSelector((state: RootState) => state.layout.userIsPro);
  const username = useSelector((state: RootState) => state.layout.username);
  const [name, setName] = useState("");
  const [stock, setStock] = useState<null | number>(null);
  const [price, setPrice] = useState<null | number>(null);
  const [salePrice, setSalePrice] = useState<null | number>(null);
  const [note, setNote] = useState<string>("");
  const [features, setFeatures] = useState<string[]>([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    CATEGORY_NOT_SELECTED
  );
  const [selectedTagsArray, setSelectedTagsArray] = useState<any[]>([]);
  const [selectedTagsIDsArray, setSelectedTagsIDsArray] = useState<any[]>([]);
  const [subcategorysIds, setSubcategorysIds] = useState<any[]>([]);
  const [uploading, setUploading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [formError, setFormError] = useState<any>({});
  const [selectedExpirationDate, setSelectedExpirationDate] =
    useState<Dayjs | null>(null);
  const [showUsernameModal, setShowUsernameModal] = useState(false);
  const [barCode, setBarCode] = useState("");
  const [productsTotal, setProductsTotal] = useState(0);
  const [showImagePickerModal, setShowImagePickerModal] = useState(false);
  const [currencyType, setCurrencyType] = useState(
    selectedBusiness.mainCurrency?.currencyType != null
      ? selectedBusiness.mainCurrency?.currencyType
      : "currencyType not selected"
  );
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<null | {
    x: number;
    y: number;
    width: number;
    height: number;
  }>(null);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const user = getAuth().currentUser;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userIsPro && selectedBusiness.id) {
      const productsCollection = collection(
        db,
        "business",
        selectedBusiness.id,
        "products"
      );

      const suscriber = onSnapshot(
        productsCollection,
        (snapshot) => {
          setProductsTotal(snapshot.size);
        },
        (er) => {}
      );

      return () => {
        suscriber();
      };
    }
  }, [userIsPro, selectedBusiness.id]);

  const cleanFields = () => {
    setName("");
    setStock(null);
    setPrice(null);
    setSalePrice(null);
    setNote("");
    setFeatures([]);
    setVideoUrl("");
    setSelectedCategoryId(CATEGORY_NOT_SELECTED);
    setSelectedTagsArray([]);
    setSelectedTagsIDsArray([]);
    setSubcategorysIds([]);
    setSelectedImage("");
    setFormError({});
    setSelectedExpirationDate(null);
    setBarCode("");
    setCroppedAreaPixels(null);
  };

  const closeModal = () => {
    dispatch(
      setIsNewProductModalVisible({
        isNewProductModalVisible: false,
      })
    );
    cleanFields();
  };

  const uploadToFirestoreWithoutImageOWNER = () => {
    dispatch(
      setShowGeneralSnackbar({
        showSnackbar: true,
      })
    );
    dispatch(
      setGeneralSnackBarMessage({
        snackBarMessage: "Subiendo producto",
      })
    );
    setUploading(true);

    const batch = writeBatch(db);

    const newProductRef = doc(
      collection(db, "business", selectedBusiness.id, "products")
    );

    batch.set(newProductRef, {
      name: name.toLowerCase().trim(),
      features: features,
      categoryId: selectedCategoryId,
      subcategorysIds: subcategorysIds,
      tagsList: selectedTagsArray,
      tagsIDsList: selectedTagsIDsArray,
      // @ts-ignore: Unreachable code error
      stock: parseFloat(stock),
      // @ts-ignore: Unreachable code error
      price: parseFloat(price),
      salePrice:
        // @ts-ignore: Unreachable code error
        salePrice != "" && salePrice != null
          ? // @ts-ignore: Unreachable code error
            parseFloat(salePrice)
          : null,
      currencyType: currencyType,
      images: [], //temporal
      expirationDate:
        selectedExpirationDate !== null
          ? selectedExpirationDate?.toDate()
          : null,
      productNote: note,
      createAt: new Date(),
      createAtMoment: moment().format("LLL"),
      // @ts-ignore: Unreachable code error
      avaliable: stock > 0 ? true : false,
      imageId: null,
      timestamp: new Date(),
      barCode: barCode ? barCode : null,
      videoUrl: videoUrl !== "" ? videoUrl : null,
    });

    let tagReference = null;
    selectedTagsIDsArray.forEach((item, index) => {
      tagReference = doc(
        collection(db, "business", selectedBusiness.id, "tags"),
        item
      );

      batch.update(tagReference, {
        qProducts: increment(1),
      });
    });

    batch
      .commit()
      .then(() => {
        setUploading(false);
        dispatch(
          setShowGeneralSnackbar({
            showSnackbar: true,
          })
        );
        dispatch(
          setGeneralSnackBarMessage({
            snackBarMessage: "✅ Producto creado correctamente",
          })
        );
        dispatch(
          setIsNewProductModalVisible({
            isNewProductModalVisible: false,
          })
        );
        navigate({
          pathname: "/inventory",
          search: "?tab=AVAILABLE",
        });
        cleanFields();
      })
      .catch(() => {
        setUploading(false);
        dispatch(
          setShowGeneralSnackbar({
            showSnackbar: true,
          })
        );
        dispatch(
          setGeneralSnackBarMessage({
            snackBarMessage: "Ocurrió un error al intentar crear el producto",
          })
        );
      });
  };

  const uploadToFirestoreWithoutImageEMPLOYEE = () => {
    if (username == "" || username == null || username == undefined) {
      setShowUsernameModal(true);
    } else {
      dispatch(
        setShowGeneralSnackbar({
          showSnackbar: true,
        })
      );
      dispatch(
        setGeneralSnackBarMessage({
          snackBarMessage: "Subiendo producto",
        })
      );
      setUploading(true);

      const batch = writeBatch(db);

      const newProductRef = doc(
        collection(db, "business", selectedBusiness.id, "products")
      );

      batch.set(newProductRef, {
        name: name.toLowerCase().trim(),
        features: features,
        categoryId: selectedCategoryId,
        subcategorysIds: subcategorysIds,
        tagsList: selectedTagsArray,
        tagsIDsList: selectedTagsIDsArray,
        // @ts-ignore: Unreachable code error
        stock: parseFloat(stock),
        // @ts-ignore: Unreachable code error
        price: parseFloat(price),
        salePrice:
          // @ts-ignore: Unreachable code error
          salePrice != "" && salePrice != null
            ? // @ts-ignore: Unreachable code error
              parseFloat(salePrice)
            : null,
        currencyType: currencyType,
        images: [], //temporal
        expirationDate:
          selectedExpirationDate !== null
            ? selectedExpirationDate?.toDate()
            : null,
        productNote: note,
        createAt: new Date(),
        createAtMoment: moment().format("LLL"),
        // @ts-ignore: Unreachable code error
        avaliable: stock > 0 ? true : false,
        imageId: null,
        timestamp: new Date(),
        barCode: barCode ? barCode : null,
        userId: user?.uid,
        username: username,
        videoUrl: videoUrl !== "" ? videoUrl : null,
      });

      let tagReference = null;
      selectedTagsIDsArray.forEach((item, index) => {
        tagReference = doc(
          collection(db, "business", selectedBusiness.id, "tags"),
          item
        );

        batch.update(tagReference, {
          qProducts: increment(1),
        });
      });

      batch
        .commit()
        .then(() => {
          setUploading(false);
          dispatch(
            setShowGeneralSnackbar({
              showSnackbar: true,
            })
          );
          dispatch(
            setGeneralSnackBarMessage({
              snackBarMessage: "✅ Producto creado correctamente",
            })
          );
          dispatch(
            setIsNewProductModalVisible({
              isNewProductModalVisible: false,
            })
          );
          navigate({
            pathname: "/inventory",
            search: "?tab=AVAILABLE",
          });
          cleanFields();
        })
        .catch(() => {
          setUploading(false);
          dispatch(
            setShowGeneralSnackbar({
              showSnackbar: true,
            })
          );
          dispatch(
            setGeneralSnackBarMessage({
              snackBarMessage: "Ocurrió un error al intentar crear el producto",
            })
          );
        });
    }
  };

  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
    });

  const getCroppedImage = async (): Promise<Blob | null> => {
    if (!selectedImage || !croppedAreaPixels) return null;

    const canvas = document.createElement("canvas");
    const image = await createImage(selectedImage);
    const { width, height } = croppedAreaPixels;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");

    if (ctx) {
      ctx.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        width,
        height,
        0,
        0,
        width,
        height
      );
    }
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (blob) resolve(blob);
      }, "image/jpeg");
    });
  };

  const uploadToFirestoreOWNER = async () => {
    dispatch(
      setShowGeneralSnackbar({
        showSnackbar: true,
      })
    );
    dispatch(
      setGeneralSnackBarMessage({
        snackBarMessage: "Subiendo producto",
      })
    );
    setUploading(true);

    try {
      const croppedImageBlob = await getCroppedImage();
      const imageId = uuid();
      if (!croppedImageBlob) return;
      const storageRef = ref(storage, `products/${imageId}`);
      const uploadTask = uploadBytesResumable(storageRef, croppedImageBlob);

      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          setUploading(false);
          dispatch(
            setShowGeneralSnackbar({
              showSnackbar: true,
            })
          );
          dispatch(
            setGeneralSnackBarMessage({
              snackBarMessage: "Ocurrió un error al intentar crear el producto",
            })
          );
        },
        async () => {
          const downloadURL = await getDownloadURL(storageRef);

          const batch = writeBatch(db);

          const newProductRef = doc(
            collection(db, "business", selectedBusiness.id, "products")
          );

          batch.set(newProductRef, {
            name: name.toLowerCase().trim(),
            features: features,
            categoryId: selectedCategoryId,
            subcategorysIds: subcategorysIds,
            tagsList: selectedTagsArray,
            tagsIDsList: selectedTagsIDsArray,
            // @ts-ignore: Unreachable code error
            stock: parseFloat(stock),
            // @ts-ignore: Unreachable code error
            price: parseFloat(price),
            salePrice:
              // @ts-ignore: Unreachable code error
              salePrice != "" && salePrice != null
                ? // @ts-ignore: Unreachable code error
                  parseFloat(salePrice)
                : null,
            currencyType: currencyType,
            images: [downloadURL], //temporal
            expirationDate:
              selectedExpirationDate !== null
                ? selectedExpirationDate?.toDate()
                : null,
            productNote: note,
            createAt: new Date(),
            createAtMoment: moment().format("LLL"),
            // @ts-ignore: Unreachable code error
            avaliable: stock > 0 ? true : false,
            imageId,
            timestamp: new Date(),
            barCode: barCode ? barCode : null,
            videoUrl: videoUrl !== "" ? videoUrl : null,
          });

          let tagReference = null;
          selectedTagsIDsArray.forEach((item, index) => {
            tagReference = doc(
              collection(db, "business", selectedBusiness.id, "tags"),
              item
            );

            batch.update(tagReference, {
              qProducts: increment(1),
            });
          });

          batch
            .commit()
            .then(() => {
              setUploading(false);
              dispatch(
                setShowGeneralSnackbar({
                  showSnackbar: true,
                })
              );
              dispatch(
                setGeneralSnackBarMessage({
                  snackBarMessage: "✅ Producto creado correctamente",
                })
              );
              dispatch(
                setIsNewProductModalVisible({
                  isNewProductModalVisible: false,
                })
              );
              navigate({
                pathname: "/inventory",
                search: "?tab=AVAILABLE",
              });
              cleanFields();
            })
            .catch(() => {
              setUploading(false);
              dispatch(
                setShowGeneralSnackbar({
                  showSnackbar: true,
                })
              );
              dispatch(
                setGeneralSnackBarMessage({
                  snackBarMessage:
                    "Ocurrió un error al intentar crear el producto",
                })
              );
            });
        }
      );
    } catch (err) {
      setUploading(false);
      dispatch(
        setShowGeneralSnackbar({
          showSnackbar: true,
        })
      );
      dispatch(
        setGeneralSnackBarMessage({
          snackBarMessage: "Ocurrió un error al intentar crear el producto",
        })
      );
    }
  };

  const uploadToFirestoreEMPLOYEE = async () => {
    if (username == "" || username == null || username == undefined) {
      setShowUsernameModal(true);
    } else {
      dispatch(
        setShowGeneralSnackbar({
          showSnackbar: true,
        })
      );
      dispatch(
        setGeneralSnackBarMessage({
          snackBarMessage: "Subiendo producto",
        })
      );
      setUploading(true);

      try {
        const croppedImageBlob = await getCroppedImage();
        const imageId = uuid();
        if (!croppedImageBlob) return;
        const storageRef = ref(storage, `products/${imageId}`);
        const uploadTask = uploadBytesResumable(storageRef, croppedImageBlob);

        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (error) => {
            setUploading(false);
            dispatch(
              setShowGeneralSnackbar({
                showSnackbar: true,
              })
            );
            dispatch(
              setGeneralSnackBarMessage({
                snackBarMessage:
                  "Ocurrió un error al intentar crear el producto",
              })
            );
          },
          async () => {
            const downloadURL = await getDownloadURL(storageRef);

            const batch = writeBatch(db);

            const newProductRef = doc(
              collection(db, "business", selectedBusiness.id, "products")
            );

            batch.set(newProductRef, {
              name: name.toLowerCase().trim(),
              features: features,
              categoryId: selectedCategoryId,
              subcategorysIds: subcategorysIds,
              tagsList: selectedTagsArray,
              tagsIDsList: selectedTagsIDsArray,
              // @ts-ignore: Unreachable code error
              stock: parseFloat(stock),
              // @ts-ignore: Unreachable code error
              price: parseFloat(price),
              salePrice:
                // @ts-ignore: Unreachable code error
                salePrice != "" && salePrice != null
                  ? // @ts-ignore: Unreachable code error
                    parseFloat(salePrice)
                  : null,
              currencyType: currencyType,
              images: [downloadURL], //temporal
              expirationDate:
                selectedExpirationDate !== null
                  ? selectedExpirationDate?.toDate()
                  : null,
              productNote: note,
              createAt: new Date(),
              createAtMoment: moment().format("LLL"),
              // @ts-ignore: Unreachable code error
              avaliable: stock > 0 ? true : false,
              imageId,
              timestamp: new Date(),
              barCode: barCode ? barCode : null,
              userId: user?.uid,
              username: username,
              videoUrl: videoUrl !== "" ? videoUrl : null,
            });

            let tagReference = null;
            selectedTagsIDsArray.forEach((item, index) => {
              tagReference = doc(
                collection(db, "business", selectedBusiness.id, "tags"),
                item
              );

              batch.update(tagReference, {
                qProducts: increment(1),
              });
            });

            batch
              .commit()
              .then(() => {
                setUploading(false);
                dispatch(
                  setShowGeneralSnackbar({
                    showSnackbar: true,
                  })
                );
                dispatch(
                  setGeneralSnackBarMessage({
                    snackBarMessage: "✅ Producto creado correctamente",
                  })
                );
                dispatch(
                  setIsNewProductModalVisible({
                    isNewProductModalVisible: false,
                  })
                );
                navigate({
                  pathname: "/inventory",
                  search: "?tab=AVAILABLE",
                });
                cleanFields();
              })
              .catch(() => {
                setUploading(false);
                dispatch(
                  setShowGeneralSnackbar({
                    showSnackbar: true,
                  })
                );
                dispatch(
                  setGeneralSnackBarMessage({
                    snackBarMessage:
                      "Ocurrió un error al intentar crear el producto",
                  })
                );
              });
          }
        );
      } catch (err) {
        setUploading(false);
        dispatch(
          setShowGeneralSnackbar({
            showSnackbar: true,
          })
        );
        dispatch(
          setGeneralSnackBarMessage({
            snackBarMessage: "Ocurrió un error al intentar crear el producto",
          })
        );
      }
    }
  };

  const confirmUploadToFirestoreWithoutImage = () => {
    if (selectedBusiness?.userId == user?.uid) {
      uploadToFirestoreWithoutImageOWNER();
    } else {
      uploadToFirestoreWithoutImageEMPLOYEE();
    }
  };

  const confirmUploadToFirestore = () => {
    if (selectedBusiness?.userId == user?.uid) {
      uploadToFirestoreOWNER();
    } else {
      uploadToFirestoreEMPLOYEE();
    }
  };

  const uploadProduct = () => {
    let errors: any = {};
    if (!name) {
      errors.name = true;
      errors.nameMessage = "El nombre es obligatorio";
      dispatch(
        setShowGeneralSnackbar({
          showSnackbar: true,
        })
      );
      dispatch(
        setGeneralSnackBarMessage({
          snackBarMessage: "El nombre es obligatorio",
        })
      );
    }
    // @ts-ignore: Unreachable code error
    else if (price == null || price == "" || price == undefined) {
      errors.price = true;
      errors.priceMessage = "El precio de compra es obligatorio";
      dispatch(
        setShowGeneralSnackbar({
          showSnackbar: true,
        })
      );
      dispatch(
        setGeneralSnackBarMessage({
          snackBarMessage: "El precio de compra es obligatorio",
        })
      );
    }
    // @ts-ignore: Unreachable code error
    else if (stock == null || stock == "" || stock == undefined) {
      errors.stock = true;
      errors.stockMessage = "El stock es obligatorio";
      dispatch(
        setShowGeneralSnackbar({
          showSnackbar: true,
        })
      );
      dispatch(
        setGeneralSnackBarMessage({
          snackBarMessage: "El stock es obligatorio",
        })
      );
    } else if (isNaN(stock)) {
      errors.stock = true;
      errors.stockMessage = "El stock del producto es inválido";
      dispatch(
        setShowGeneralSnackbar({
          showSnackbar: true,
        })
      );
      dispatch(
        setGeneralSnackBarMessage({
          snackBarMessage: "El stock del producto es inválido",
        })
      );
    } else if (isNaN(price)) {
      errors.price = true;
      errors.priceMessage = "El precio de compra del producto es inválido";
      dispatch(
        setShowGeneralSnackbar({
          showSnackbar: true,
        })
      );
      dispatch(
        setGeneralSnackBarMessage({
          snackBarMessage: "El precio de compra producto es inválido",
        })
      );
    } else if (price <= 0) {
      errors.price = true;
      errors.priceMessage = "El precio de compra debe ser mayor a cero";
      dispatch(
        setShowGeneralSnackbar({
          showSnackbar: true,
        })
      );
      dispatch(
        setGeneralSnackBarMessage({
          snackBarMessage: "El precio de compra debe ser mayor a cero",
        })
      );
    } else if (stock < 0) {
      errors.stock = true;
      errors.stockMessage = "El stock no puede ser negativo";
      dispatch(
        setShowGeneralSnackbar({
          showSnackbar: true,
        })
      );
      dispatch(
        setGeneralSnackBarMessage({
          snackBarMessage: "El stock no puede ser negativo",
        })
      );
    } else if (
      salePrice !== null &&
      // @ts-ignore: Unreachable code error
      salePrice !== "" &&
      salePrice !== undefined
    ) {
      // SÍ HAY PRECIO DE VENTA
      if (salePrice <= 0) {
        errors.salePrice = true;
        errors.salePriceMessage = "El precio de venta debe ser mayor que cero";
        dispatch(
          setShowGeneralSnackbar({
            showSnackbar: true,
          })
        );
        dispatch(
          setGeneralSnackBarMessage({
            snackBarMessage: "El precio de venta debe ser mayor que cero",
          })
        );
      } else if (isNaN(salePrice)) {
        errors.salePrice = true;
        errors.salePriceMessage = "Precio de venta NO VÁLIDO";
        dispatch(
          setShowGeneralSnackbar({
            showSnackbar: true,
          })
        );
        dispatch(
          setGeneralSnackBarMessage({
            snackBarMessage: "Precio de venta NO VÁLIDO",
          })
        );
      } else {
        if (!selectedImage) {
          confirmUploadToFirestoreWithoutImage();
        } else {
          confirmUploadToFirestore();
        }
      }
    } else {
      if (!selectedImage) {
        confirmUploadToFirestoreWithoutImage();
      } else {
        confirmUploadToFirestore();
      }
    }
    setFormError(errors);
  };

  const confirmUploadProduct = () => {
    if (!userIsPro) {
      if (productsTotal >= PRODUCTS_MAX_QUANTITY) {
        setShowPremiumModal(true);
      } else {
        uploadProduct();
      }
    } else {
      uploadProduct();
    }
  };

  return (
    <>
      <CustomDrawer
        open={isVisible}
        onClose={closeModal}
        anchor="right"
        variant="temporary"
        ModalProps={{
          keepMounted: true,
        }}
        transitionDuration={500}
      >
        <Container>
          <Column>
            <Header>
              <IconButton onClick={closeModal}>
                <ArrowBack />
              </IconButton>
              <Title>Agregar producto</Title>
            </Header>
            <ExternalContent>
              <Mandatory>Campo obligatorio *</Mandatory>
              <Content>
                <ProductPhoto
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                  setShowModal={setShowImagePickerModal}
                  uploading={uploading}
                  croppedAreaPixels={croppedAreaPixels}
                  setCroppedAreaPixels={setCroppedAreaPixels}
                />
                <InputWithLabel
                  value={name}
                  setValue={setName}
                  placeholder="Nombre del producto"
                  error={formError?.name}
                  label="Nombre del Producto *"
                  labelIcon={<IoDocumentTextOutline />}
                />
                {formError?.name && formError?.nameMessage && (
                  <ErrorText>{formError?.nameMessage}</ErrorText>
                )}
                <CustomDivider marginTop={20} marginBottom={20} />
                <BarCode barCode={barCode} setBarCode={setBarCode} />
                <CustomDivider marginTop={20} marginBottom={20} />
                <InputWithLabel
                  value={stock}
                  setValue={setStock}
                  placeholder="Stock"
                  label="Stock *"
                  labelIcon={<MdOutlineFormatListNumbered />}
                  error={formError?.stock}
                />
                {formError?.stock && formError?.stockMessage && (
                  <ErrorText>{formError?.stockMessage}</ErrorText>
                )}
                <CustomDivider marginTop={20} marginBottom={20} />
                <SelectedCurrency />
                <CustomDivider marginTop={20} marginBottom={20} />
                <InputWithLabel
                  value={price}
                  setValue={setPrice}
                  placeholder="Precio de compra"
                  label="Precio de compra *"
                  labelIcon={<FaDollarSign />}
                  error={formError?.price}
                />
                {formError?.price && formError?.priceMessage && (
                  <ErrorText>{formError?.priceMessage}</ErrorText>
                )}
                <CustomDivider marginTop={20} marginBottom={20} />
                <InputWithLabel
                  value={salePrice}
                  setValue={setSalePrice}
                  placeholder="Precio de venta"
                  label="Precio de venta"
                  labelIcon={<BsCash />}
                  error={formError?.salePrice}
                />
                {formError?.salePrice && formError?.salePriceMessage && (
                  <ErrorText>{formError?.salePriceMessage}</ErrorText>
                )}
                <CustomDivider marginTop={20} marginBottom={20} />
                <Video videoUrl={videoUrl} setVideoUrl={setVideoUrl} />
                <CustomDivider marginTop={20} marginBottom={20} />
                <CategorySelector
                  selectedCategoryId={selectedCategoryId}
                  setSelectedCategoryId={setSelectedCategoryId}
                />
                <CustomDivider marginTop={20} marginBottom={20} />
                <SubcategorySelector
                  selectedCategoryId={selectedCategoryId}
                  setSubcategorysIds={setSubcategorysIds}
                  subcategorysIds={subcategorysIds}
                />
                <CustomDivider marginTop={20} marginBottom={20} />
                <Features features={features} setFeatures={setFeatures} />
                <CustomDivider marginTop={20} marginBottom={20} />
                <TagsList
                  selectedTagsArray={selectedTagsArray}
                  setSelectedTagsArray={setSelectedTagsArray}
                  selectedTagsIDsArray={selectedTagsIDsArray}
                  setSelectedTagsIDsArray={setSelectedTagsIDsArray}
                />
                <CustomDivider marginTop={20} marginBottom={20} />
                <ExpirationDate
                  expirationDate={selectedExpirationDate}
                  setExpirationDate={setSelectedExpirationDate}
                />
                <CustomDivider marginTop={20} marginBottom={20} />
                <InputWithLabel
                  value={note}
                  setValue={setNote}
                  placeholder="Escribe aquí..."
                  label="Agregar una nota:"
                  labelIcon={<RiFileMarkedLine />}
                />
              </Content>
            </ExternalContent>
            <Footer>
              <StyledButton disabled={uploading} onClick={confirmUploadProduct}>
                {uploading ? (
                  <CircularProgress style={{ color: "white" }} size={30} />
                ) : (
                  <StyledButtonText>Registrar producto</StyledButtonText>
                )}
              </StyledButton>
            </Footer>
          </Column>
          <Column2>
            <ImageContainer>
              <StyledImage src={require("../../images/addProduct.png")} />
            </ImageContainer>
          </Column2>
        </Container>
      </CustomDrawer>
      <CheckUsernameModal
        isVisible={showUsernameModal}
        setIsVisible={setShowUsernameModal}
      />
      <ImagePickerModal
        isVisible={showImagePickerModal}
        setIsVisible={setShowImagePickerModal}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        croppedAreaPixels={croppedAreaPixels}
        setCroppedAreaPixels={setCroppedAreaPixels}
      />
      <PremiumModal
        isVisible={showPremiumModal}
        setIsVisible={setShowPremiumModal}
        list={[
          `En la versión gratuita solamente puedes agregar hasta ${PRODUCTS_MAX_QUANTITY} productos al inventario de tu tienda online`,
          "Si deseas agregar más productos SIN LÍMITE, entonces la versión PREMIUM de Ganvent podría ayudarte a llevar tu negocio al siguiente nivel",
        ]}
      />
    </>
  );
}

const CustomDrawer = styled(Drawer)``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: grid;
  grid-template-columns: 580px 1fr;
`;

const Column = styled.div`
  height: 100vh;
  position: relative;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Column2 = styled.div`
  background-color: #00a680;
  height: 100vh;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
`;

const StyledImage = styled.img`
  object-fit: contain;
  height: 70%;
  width: 70%;
`;

const Header = styled.header`
  width: 580px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
  padding: 20px 10px;
  background-color: white;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
`;

const Mandatory = styled.p`
  margin-top: 18px;
  margin-left: 30px;
  font-weight: bold;
  font-size: 15px;
`;

const ExternalContent = styled.div`
  flex: 1;
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 70px;
`;

const Content = styled.div`
  margin-top: 20px;
  padding: 0px 30px;
`;

const Title = styled.p`
  flex: 1;
  font-size: 1.3rem;
  font-weight: 600;
`;

const Footer = styled.footer`
  padding: 20px 10px;
  background-color: white;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
  width: 580px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  &&& {
    background-color: #00a680;
    width: 100%;
    padding: 12px;
    border-radius: 20px;
  }
`;

const StyledButtonText = styled.p`
  &&& {
    text-transform: none;
    color: white;
    font-family: "Montserrat";
    font-size: 1rem;
  }
`;

const ErrorText = styled.p`
  color: red;
  font-size: 12px;
  margin-top: 10px;
`;
