import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import FeaturesCarousel from "../components/login/FeaturesCarousel";
import OnboardingFeature from "../components/onboarding/OnboardingFeature";
import Step1 from "../components/onboarding/Step1";
import Step2 from "../components/onboarding/Step2";
import Step3 from "../components/onboarding/Step3";
import Step4 from "../components/onboarding/Step4";
import { CURRENCY_TYPE_NOT_SELECTED } from "../utils/globalConstants";
import currencyData from "../utils/currencyData";
import { CircularProgress } from "@mui/material";
import {
  auth,
  collection,
  db,
  getDocs,
  onAuthStateChanged,
  query,
  where,
} from "../firebase";
import { useNavigate } from "react-router-dom";

const CATEGORY_NOT_SELECTED = "category not selected";

export default function Onboarding() {
  const [currentStep, setCurrentStep] = useState(0);
  const [currencyType, setCurrencyType] = useState(CURRENCY_TYPE_NOT_SELECTED);
  const [currencyTypeSymbol, setCurrencyTypeSymbol] = useState("");
  const [formError, setFormError] = useState<any>({});
  const [isGettingUser, setIsGettingUser] = useState(true);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [isGettingInfo, setIsGettingInfo] = useState(true);
  const [login, setLogin] = useState<boolean | null>(null);
  const getDefaultEmail = () => {
    const user = auth.currentUser;
    return user?.email || "";
  };

  const [formData, setFormData] = useState({
    owner: getDefaultEmail(),
    business: "",
    url: "",
    phoneNumber: "",
    cca2: "US",
    callingCode: ["1"],
    selectedCategory: CATEGORY_NOT_SELECTED,
  });
  const navigate = useNavigate();
  const [containerWidth, setContainerWidth] = useState(0);
  const sliderRef = useRef<HTMLDivElement | null>(null);

  const options = [
    <OnboardingFeature
      image={require("../images/onboarding/1.png")}
      description="Crear tu tienda virtual GRATIS, personalizarlo y empezar a recibir pedidos"
    />,
    <OnboardingFeature
      image={require("../images/onboarding/qrCode.png")}
      description="Compartir el link y código QR de tu tienda"
    />,
    <OnboardingFeature
      image={require("../images/onboarding/inventory.png")}
      description="Gestionar el inventario de tu negocio fácil y eficazmente. Crea categorías, subcategorías, etiquetas, exporta tus datos y mucho más..."
    />,
    <OnboardingFeature
      image={require("../images/onboarding/4.png")}
      description="Crear ofertas, agregar carruseles, banners, responder reseñas, personalizar tu paleta de colores y mucho más para convertir cada visitante de tu tienda virtual en un cliente"
    />,
    <OnboardingFeature
      image={require("../images/onboarding/5.png")}
      description="Registrar todas tus transacciones (Ventas, compras, ingresos, gastos, deudas, abonos, etc.) y enviar recibos de estos"
    />,
    <OnboardingFeature
      image={require("../images/onboarding/6.png")}
      description="Agregar a tus empleados para trabajar de manera colaborativa y gestionar sus roles"
    />,
    <OnboardingFeature
      image={require("../images/onboarding/7.png")}
      description="Visualizar tus estadísticas en el rango de fecha que desees y exportar tus datos a Excel"
    />,
    <OnboardingFeature
      image={require("../images/onboarding/8.png")}
      description="Gestionar tu lista de clientes y proveedores"
    />,
    <OnboardingFeature
      image={require("../images/onboarding/9.png")}
      description="Enviar recordatorios de pago y muchas cosas más..."
    />,
  ];

  useEffect(() => {
    const suscriber = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLogin(true);
        setCurrentUser(user);
      } else {
        setLogin(false);
        setCurrentUser(null);
      }
      setIsGettingUser(false);
    });
    return () => {
      suscriber();
    };
  }, []);

  const checkIfUserHasBusiness = () => {
    const q = query(
      collection(db, "business"),
      where("userId", "==", currentUser?.uid)
    );

    getDocs(q)
      .then((snapshot) => {
        if (!snapshot.empty) {
          navigate({
            pathname: "/",
          });
        } else {
          setIsGettingInfo(false);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (!isGettingUser) {
      if (currentUser !== null) {
        checkIfUserHasBusiness();
      } else {
        navigate({
          pathname: "/login",
        });
      }
    }
  }, [isGettingUser, currentUser]);

  useEffect(() => {
    if (sliderRef.current) {
      setContainerWidth(sliderRef?.current?.offsetWidth);
    }
    const handleResize = () => {
      if (sliderRef.current) {
        setContainerWidth(sliderRef?.current?.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const getCurrencyTypeName = () => {
      let message;
      if (
        currencyType != null &&
        currencyType != "currencyType not selected" &&
        currencyType != undefined
      ) {
        currencyData.forEach((data) => {
          if (data.code === currencyType) {
            message = `${data.symbol}`;
            setCurrencyTypeSymbol(message);
          }
        });
      }
    };
    getCurrencyTypeName();
  }, [currencyType]);

  if (isGettingInfo) {
    return (
      <LoadingContainer>
        <CircularProgress size={60} />
      </LoadingContainer>
    );
  }

  return (
    <Container>
      <LeftColumn>
        <Header>
          <LogoContainer>
            <Image src={require("../images/ganventLogo.png")} />
          </LogoContainer>
          <Ganvent>Ganvent</Ganvent>
        </Header>
        <SliderContainer>
          <Slider ref={sliderRef}>
            <Step1
              isVisible={currentStep == 0}
              stepNumber={1}
              formData={formData}
              setFormData={setFormData}
              formError={formError}
              setFormError={setFormError}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
            <Step2
              isVisible={currentStep == 1}
              stepNumber={2}
              formData={formData}
              setFormData={setFormData}
              formError={formError}
              setFormError={setFormError}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              currencyType={currencyType}
              setCurrencyType={setCurrencyType}
            />
            <Step3
              isVisible={currentStep == 2}
              stepNumber={3}
              formData={formData}
              setFormData={setFormData}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
            <Step4
              isVisible={currentStep == 3}
              stepNumber={4}
              formData={formData}
              setFormData={setFormData}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              formError={formError}
              setFormError={setFormError}
              currencyType={currencyType}
              currencyTypeSymbol={currencyTypeSymbol}
            />
          </Slider>
        </SliderContainer>
      </LeftColumn>
      <RightColumn>
        <TextContainer>
          <Title>¡Hola emprendedor/emprendedora!</Title>
          <Subtitle>Con Ganvent podrás:</Subtitle>
        </TextContainer>
        <FeaturesCarousel options={options} />
      </RightColumn>
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 600px 1fr;
`;

const LeftColumn = styled.div`
  padding-top: 55px;
  margin-bottom: 40px;
`;

const RightColumn = styled.div`
  height: 100vh;
  position: fixed;
  right: 0px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 32px;
  padding-left: 60px;
  padding-right: 60px;
`;

const SliderContainer = styled.div`
  width: 100vw;
  height: 100%;
`;

const Slider = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 600px);
  width: 600px;
  height: 100%;
  overflow-x: hidden;
  flex-direction: row;
`;

const LogoContainer = styled.div`
  height: 50px;
  width: 50px;
  position: relative;
`;

const Image = styled.img`
  object-fit: contain;
  height: 100%;
  width: 100%;
`;

const Ganvent = styled.h1`
  font-size: 1.4rem;
`;

const TextContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: 0%;
  width: 100%;
  margin-top: 35px;
`;

const Title = styled.p`
  background-color: #00a680;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 1rem;
`;

const Subtitle = styled.p`
  background-color: #00a680;
  text-align: center;
  color: #ffde59;
  font-weight: bold;
  font-size: 1.4rem;
  margin-top: 8px;
`;

const LoadingContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;
