import React from "react";
import styled from "styled-components";
import { IoFastFoodSharp } from "react-icons/io5";
import { CgSmartHomeRefrigerator } from "react-icons/cg";
import { MdLocalPharmacy } from "react-icons/md";
import { MdOutlineLaptop } from "react-icons/md";
import { FaTools } from "react-icons/fa";
import { AiFillGolden } from "react-icons/ai";
import { FaShirt } from "react-icons/fa6";
import { PiSprayBottleFill } from "react-icons/pi";
import { FaDog } from "react-icons/fa6";
import { FaWarehouse } from "react-icons/fa";
import { IoIosWine } from "react-icons/io";
import { MdLocalMall } from "react-icons/md";
import { MdStore } from "react-icons/md";
import { FaCar } from "react-icons/fa";
import { FaBookBookmark } from "react-icons/fa6";
import { FaFire } from "react-icons/fa";
import StepBar from "./StepBar";
import CategoryItem from "./CategoryItem";
import { Button } from "@mui/material";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";

interface Props {
  stepNumber: number;
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  isVisible: boolean;
}

export default function Step3({
  stepNumber,
  formData,
  setFormData,
  currentStep,
  setCurrentStep,
  isVisible,
}: Props) {
  const categories = [
    {
      name: "Bares y/o restaurantes",
      value: "restaurant",
      icon: <IoFastFoodSharp />,
    },
    {
      name: "Electrodomésticos y/o muebles",
      value: "furniture",
      icon: <CgSmartHomeRefrigerator />,
    },
    {
      name: "Farmacia",
      value: "pharmacy",
      icon: <MdLocalPharmacy />,
    },
    {
      name: "Informática y telecomunicaciones",
      value: "computing",
      icon: <MdOutlineLaptop />,
    },
    {
      name: "Ferretería",
      value: "hardware-store",
      icon: <FaTools />,
    },
    {
      name: "Joyería",
      value: "jewerly",
      icon: <AiFillGolden />,
    },
    {
      name: "Tienda de ropa",
      value: "textil",
      icon: <FaShirt />,
    },
    {
      name: "Perfumes",
      value: "perfumes",
      icon: <PiSprayBottleFill />,
    },
    {
      name: "Pet Store",
      value: "pets",
      icon: <FaDog />,
    },
    {
      name: "Hogar",
      value: "house",
      icon: <FaWarehouse />,
    },
    {
      name: "Licorería",
      value: "alcoholic",
      icon: <IoIosWine />,
    },
    {
      name: "Minimarket",
      value: "minimarket",
      icon: <MdLocalMall />,
    },
    {
      name: "Tiendas de abarrotes/Bazar",
      value: "grocery-stores",
      icon: <MdStore />,
    },
    {
      name: "Transporte",
      value: "transport",
      icon: <FaCar />,
    },
    {
      name: "Librería",
      value: "library",
      icon: <FaBookBookmark />,
    },
    {
      name: "Otros",
      value: "other",
      icon: <FaFire />,
    },
  ];

  const handleClickContinue = () => {
    setCurrentStep(3);
  };

  const goBack = () => {
    setCurrentStep(1);
  };

  if (!isVisible) {
    return <></>;
  }

  return (
    <Container>
      <StepBar stepNumber={stepNumber} />
      <div style={{ marginBottom: "38px" }} />
      <Title>Selecciona la categoría</Title>
      <Title style={{ marginBottom: "25px" }}>de tu negocio</Title>
      <Subtitle>
        Selecciona la categoría a la que pertenece tu negocio.
      </Subtitle>
      <Subtitle style={{ fontWeight: "bold" }}>Este paso es opcional</Subtitle>
      <List>
        {categories.map((category, index) => (
          <CategoryItem
            category={category}
            key={index}
            formData={formData}
            setFormData={setFormData}
          />
        ))}
      </List>
      <MainButton
        onClick={handleClickContinue}
        endIcon={<ChevronRight style={{ color: "white" }} />}
      >
        <MainButtonTitle>Continuar</MainButtonTitle>
      </MainButton>
      <Button
        onClick={goBack}
        style={{ marginLeft: "60px" }}
        startIcon={<ChevronLeft style={{ color: "black" }} />}
      >
        <ButtonText>Atrás</ButtonText>
      </Button>
      <div style={{ marginBottom: "70px" }} />
    </Container>
  );
}

const Container = styled.div`
  width: 600px;
  height: 100%;
`;

const Title = styled.p`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0px 50px;
`;

const Subtitle = styled.p`
  color: black;
  font-weight: 400;
  margin-left: 70px;
  margin-right: 70px;
`;

const List = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 70px;
  margin-right: 70px;
  margin-top: 14px;
  gap: 6px;
`;

const MainButton = styled(Button)`
  &&& {
    background-color: #00a680;
    width: calc(100% - 140px);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 25px;
    margin-left: 70px;
    margin-right: 70px;
  }
`;

const MainButtonTitle = styled.p`
  text-transform: none;
  color: white;
  &&& {
    font-size: 15px;
    font-family: "Montserrat";
  }
`;

const ButtonText = styled.p`
  &&& {
    text-transform: none;
    color: black;
    font-family: "Montserrat";
    font-weight: 400;
  }
`;
