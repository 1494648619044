import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import React from "react";
import styled from "styled-components";
import { FaCrown } from "react-icons/fa";
import Close from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsNewProductModalVisible } from "../../context/reducers/layout";
import { setIsNewCategoryModalVisible } from "../../context/reducers/categories";

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  bgColor?: string;
  list: any[];
}

export default function PremiumModal({
  isVisible,
  setIsVisible,
  bgColor = "#00a680",
  list,
}: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = () => {
    setIsVisible(false);
  };

  const watchMore = () => {
    setIsVisible(false);
    navigate({
      pathname: "/paywall",
    });
    dispatch(
      setIsNewProductModalVisible({
        isNewProductModalVisible: false,
      })
    );
    dispatch(
      setIsNewCategoryModalVisible({
        isNewCategoryModalVisible: false,
      })
    );
  };

  return (
    <StyledModal open={isVisible} onClose={handleClose}>
      <Container>
        <Header>
          <IconContainer>
            <FaCrown style={{ fontSize: "20px", color: "#fee140" }} />
          </IconContainer>
          <Title>Ganvent PREMIUM</Title>
          <IconButton onClick={handleClose}>
            <Close style={{ color: "white" }} />
          </IconButton>
        </Header>
        {list.map((item, index) => (
          <Item key={index}>
            <Circle />
            <ItemText>{item}</ItemText>
          </Item>
        ))}
        <Footer>
          <StyledButton
            onClick={handleClose}
            style={{
              borderColor: "#00a680",
            }}
          >
            <StyledButtonText style={{ color: bgColor }}>
              Cancelar
            </StyledButtonText>
          </StyledButton>
          <StyledButton
            onClick={watchMore}
            style={{ backgroundColor: bgColor, borderColor: bgColor }}
          >
            <StyledButtonText style={{ color: "white" }}>
              Ver más
            </StyledButtonText>
          </StyledButton>
        </Footer>
      </Container>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
  max-width: 620px;
  min-width: 300px;
  padding: 5px 0px;
  padding-top: 0px;
`;

const Header = styled.header`
  background-color: #00a680;
  padding-top: 30px;
  padding-bottom: 10px;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 10px;
  gap: 10px;
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 2px solid #fee140;
  border-radius: 100%;
  height: 45px;
  width: 45px;
  border-radius: 100%;
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 18px;
  flex: 1;
  color: white;
  font-weight: bold;
`;

const Description = styled.p`
  font-size: 15px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 14px;
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 12px;
  padding-bottom: 20px;
  padding-top: 12px;
  gap: 6px;
`;

const StyledButton = styled(Button)`
  &&& {
    height: 40px;
    border-radius: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid;
  }
`;

const StyledButtonText = styled.p`
  text-transform: none;
  &&& {
    font-family: "Montserrat";
    font-size: 13px;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 12px;
  padding: 0px 15px;
`;

const Circle = styled.div`
  height: 7px;
  width: 7px;
  background-color: #00a680;
  border-radius: 100%;
`;

const ItemText = styled.p`
  font-size: 15px;
  flex: 1;
`;
