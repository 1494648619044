import React, { useEffect, useState } from "react";
import styled from "styled-components";

interface Props {
  category: any;
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  setUnsavedChanges?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CATEGORY_NOT_SELECTED = "category not selected";

export default function CategoryItem({
  category,
  formData,
  setFormData,
  setUnsavedChanges,
}: Props) {
  const [isSelected, setIsSelected] = useState(
    formData?.selectedCategory === category.value
  );

  useEffect(() => {
    setIsSelected(formData?.selectedCategory === category.value);
  }, [formData]);

  const handleClick = () => {
    if (setUnsavedChanges !== undefined) {
      setUnsavedChanges(true);
    }
    setFormData({
      ...formData,
      selectedCategory: isSelected ? CATEGORY_NOT_SELECTED : category.value,
    });
  };

  return (
    <Container
      onClick={handleClick}
      style={{
        borderColor: isSelected ? "#00a680" : "#c1c1c1",
        borderWidth: isSelected ? 2 : 1,
      }}
    >
      <IconContainer>{category?.icon && category?.icon}</IconContainer>
      <Text
        style={{
          color: isSelected ? "#00a680" : "black",
          fontWeight: isSelected ? "bold" : "normal",
        }}
      >
        {category?.name}
      </Text>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #c1c1c1;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;

  :hover {
    background-color: rgba(155, 155, 155, 0.1);
  }
`;

const IconContainer = styled.div``;

const Text = styled.p`
  margin-left: 4px;
  font-size: 13px;
  font-weight: normal;
`;
