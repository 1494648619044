import React from "react";
import styled from "styled-components";

export default function GanventComponent() {
  return (
    <Container>
      <ImageContainer>
        <Image src={require("../../../images/ganventLogo.png")} />
      </ImageContainer>
      <Title>Ganvent</Title>
      <Text>
        Crea tu <Highlight>tienda online</Highlight> y lleva tu negocio al{" "}
        <Highlight>siguiente nivel</Highlight>
      </Text>
      <Text>
        <Highlight>¿listo/a para comenzar con esta nueva aventura?</Highlight>
      </Text>
    </Container>
  );
}

const Container = styled.div`
  width: calc(100vw - 600px);
  height: 100%;
  background-color: #00a680;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 220px;
  width: 220px;
  background-color: #00a680;
  border-radius: 100%;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.45);
  -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.45);
`;

const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

const Title = styled.p`
  color: white;
  font-size: 2.1rem;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Text = styled.p`
  margin: 0px 35px;
  color: white;
  font-weight: bold;
  font-size: 1.4rem;
  text-align: center;
`;

const Highlight = styled.span`
  color: #ffde59;
  font-weight: bold;
  font-size: 1.4rem;
`;
