import { Suspense, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CircularProgress, Snackbar } from "@mui/material";
import {
  auth,
  collection,
  db,
  doc,
  getDoc,
  getDocs,
  onAuthStateChanged,
  query,
  where,
} from "../firebase";
import Sidebar from "../components/layout/Sidebar";
import SupportButton from "../components/layout/SupportButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../context/store";
import { SELECTED_BUSINESS_ID } from "../utils/globalConstants";
import {
  setSelectedBusiness,
  setIsBusinessesModalVisible,
  setUsername,
  setShowGeneralSnackbar,
} from "../context/reducers/layout";
import ChangingBusiness from "../components/global/ChangingBusiness";
import EmployeeBusiness from "../components/global/EmployeeBusiness";
import MainHeader from "../components/layout/MainHeader";
import NewCategoryModal from "../components/inventory/NewCategoryModal";
import NewProductModal from "../components/inventory/NewProductModal";

interface ContainerI {
  isCollapsed: boolean;
}

export default function Layout() {
  const selectedBusiness = useSelector(
    (state: RootState) => state.layout.selectedBusiness
  );
  const changingBusiness = useSelector(
    (state: RootState) => state.layout.changingBusiness
  );
  const isCollapsed = useSelector(
    (state: RootState) => state.layout.isSidebarCollapsed
  );
  const showSnackbar = useSelector(
    (state: RootState) => state.layout.showSnackbar
  );
  const snackBarMessage = useSelector(
    (state: RootState) => state.layout.snackBarMessage
  );
  const [isGettingInfo, setIsGettingInfo] = useState(true);
  const [isGettingUser, setIsGettingUser] = useState(true);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [login, setLogin] = useState<boolean | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const suscriber = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLogin(true);
        setCurrentUser(user);
        dispatch(
          setUsername({
            username: user?.displayName ?? "",
          })
        );
      } else {
        setLogin(false);
        setCurrentUser(null);
      }
      setIsGettingUser(false);
    });
    return () => {
      suscriber();
    };
  }, []);

  const checkIfThereIsSelectedBusiness = () => {
    const businessId = localStorage.getItem(SELECTED_BUSINESS_ID);
    if (businessId) {
      const q = doc(db, "business", businessId);
      getDoc(q)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const id = snapshot.id;
            const snapshotData = snapshot.data();
            const business = { id, ...snapshotData };
            dispatch(
              setSelectedBusiness({
                selectedBusiness: business,
              })
            );
            setIsGettingInfo(false);
          } else {
            dispatch(
              setIsBusinessesModalVisible({
                isBusinessesModalVisible: true,
              })
            );
            setIsGettingInfo(false);
          }
        })
        .catch((err) => {
          console.log("Err: ", err);
        });
    } else {
      dispatch(
        setIsBusinessesModalVisible({
          isBusinessesModalVisible: true,
        })
      );
      setIsGettingInfo(false);
    }
  };

  useEffect(() => {
    if (!isGettingUser) {
      if (currentUser !== null) {
        navigate({
          pathname: "/",
        });
        const q = query(
          collection(db, "business"),
          where("userId", "==", currentUser?.uid)
        );
        getDocs(q)
          .then((snapshot) => {
            if (!snapshot.empty) {
              navigate({
                pathname: "/",
              });
              checkIfThereIsSelectedBusiness();
            } else {
              navigate({
                pathname: "/onboarding",
              });
              setIsGettingInfo(false);
            }
          })
          .catch((err) => {});
      } else {
        navigate({
          pathname: "/login",
        });
      }
    }
  }, [isGettingUser, currentUser]);

  if (isGettingInfo) {
    return (
      <LoadingContainer>
        <CircularProgress size={60} />
      </LoadingContainer>
    );
  }

  return (
    <Container isCollapsed={isCollapsed}>
      <Sidebar />
      <MainContainer>
        <MainHeader />
        {changingBusiness ? (
          <ChangingBusiness />
        ) : (
          <Suspense
            fallback={
              <div
                style={{
                  display: "flex",
                  width: "100vw",
                  height: "100vh",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Cargando...
              </div>
            }
          >
            <Outlet />
          </Suspense>
        )}
        <SupportButton />
        {selectedBusiness?.id && <EmployeeBusiness />}
      </MainContainer>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3200}
        onClose={() => {
          dispatch(
            setShowGeneralSnackbar({
              showSnackbar: false,
            })
          );
        }}
        message={snackBarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
      <NewProductModal />
      <NewCategoryModal />
    </Container>
  );
}

const Container = styled.div<ContainerI>`
  display: grid;
  grid-template-columns: ${(props) =>
    !props.isCollapsed ? "300px 1fr" : "80px 1fr"};
  background-color: #f5f7f9;
`;

const MainContainer = styled.div`
  height: calc(100vh - 30px);
  background-color: white;
  margin-top: 15px;
  margin-bottom: 15x;
  margin-right: 10px;
  border-radius: 15px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.35);
  overflow: hidden;
  overflow-y: scroll;
`;

const LoadingContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;
