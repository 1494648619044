import { createSlice } from "@reduxjs/toolkit";

interface InitialStateI {
  incomesReceivable: any[] | null;
  canLoadMore: boolean;
  isLoadingMoreData: boolean;
  lastIncomeReceivable: any | null;
  isCreating: boolean;
}

const initialState: InitialStateI = {
  incomesReceivable: null,
  canLoadMore: true,
  isLoadingMoreData: false,
  lastIncomeReceivable: null,
  isCreating: false,
};

const incomesReceivableSlice = createSlice({
  name: "incomesReceivable",
  initialState,
  reducers: {
    setIncomesReceivable: (state, action) => {
      state.incomesReceivable = action.payload.incomesReceivable;
    },
    setCanLoadMore: (state, action) => {
      state.canLoadMore = action.payload.canLoadMore;
    },
    setIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
    setIsCreating: (state, action) => {
      state.isCreating = action.payload.isCreating;
    },
    setLastIncomeReceivable: (state, action) => {
      state.lastIncomeReceivable = action.payload.lastIncomeReceivable;
    },
  },
});

export const {
  setCanLoadMore,
  setIncomesReceivable,
  setIsCreating,
  setIsLoadingMoreData,
  setLastIncomeReceivable,
} = incomesReceivableSlice.actions;
export default incomesReceivableSlice.reducer;
