import { createSlice } from "@reduxjs/toolkit";

interface InitialStateI {
  products: any[] | null;
  canLoadMore: boolean;
  isLoadingMoreData: boolean;
  lastProduct: any | null;
}

const initialState: InitialStateI = {
  products: null,
  canLoadMore: true,
  isLoadingMoreData: false,
  lastProduct: null,
};

const availableProductsByNameSlice = createSlice({
  name: "availableProductsByName",
  initialState,
  reducers: {
    setAvailableProductsByName: (state, action) => {
      state.products = action.payload.products;
    },
    setLastProduct: (state, action) => {
      state.lastProduct = action.payload.lastProduct;
    },
    setCanLoadMore: (state, action) => {
      state.canLoadMore = action.payload.canLoadMore;
    },
    setIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
  },
});

export const {
  setAvailableProductsByName,
  setCanLoadMore,
  setIsLoadingMoreData,
  setLastProduct,
} = availableProductsByNameSlice.actions;
export default availableProductsByNameSlice.reducer;
