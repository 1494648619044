import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../../../../context/store";
import { FaCrown } from "react-icons/fa";

export default function CustomDomain() {
  const userIsPro = useSelector((state: RootState) => state.layout.userIsPro);

  return (
    <Container>
      <Title>Configurar dominio personalizado:</Title>
      {!userIsPro ? (
        <>
          <PremiumContainer>
            <Premium>Esta es una función de Ganvent PREMIUM</Premium>
            <FaCrown style={{ fontSize: "20px", color: "#fee140" }} />
          </PremiumContainer>
        </>
      ) : (
        <>
          <LinkContainer>
            <Link>
              Nota: La vinculación de tu propio dominio personalizado a tu
              tienda virtual solamente se puede realizar desde la app móvil
              Ganvent, descárgalo en PlayStore o AppStore
            </Link>
          </LinkContainer>
          {/* <ToggleLink title="Utilizar tu dominio personalizado como el link principal de tu tienda virtual" /> */}
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 20px;
`;

const Title = styled.p`
  font-size: 15px;
  font-weight: 500;
`;

const LinkContainer = styled.div`
  border: 1px solid #00a680;
  border-radius: 10px;
  padding: 15px 20px;
  margin-top: 15px;
`;

const Link = styled.p`
  text-align: center;
  font-size: 13px;
  font-weight: 600;
`;

const PremiumContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  margin: 0px 20px;
  border-radius: 12px;
  border: 3px solid #fee140;
  margin-top: 12px;
`;

const Premium = styled.p`
  flex: 1;
  font-size: 15px;
  font-weight: 500;
`;
