import { createSlice } from "@reduxjs/toolkit";

interface InitialStateI {
  tags: any[] | null;
  canLoadMore: boolean;
  isLoadingMoreData: boolean;
  lastTag: any | null;
}

const initialState: InitialStateI = {
  tags: null,
  canLoadMore: true,
  isLoadingMoreData: false,
  lastTag: null,
};

const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    setTags: (state, action) => {
      state.tags = action.payload.tags;
    },
    setLastTag: (state, action) => {
      state.lastTag = action.payload.lastTag;
    },
    setCanLoadMore: (state, action) => {
      state.canLoadMore = action.payload.canLoadMore;
    },
    setIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
  },
});

export const { setCanLoadMore, setIsLoadingMoreData, setLastTag, setTags } =
  tagsSlice.actions;
export default tagsSlice.reducer;
