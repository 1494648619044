import { useState } from "react";
import styled from "styled-components";
import { BsLightningFill } from "react-icons/bs";
import ChevronRight from "@mui/icons-material/ChevronRight";
import QuickOptionsModal from "./QuickOptionsModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../../context/store";

interface ContainerI {
  isCollapsed: boolean;
}

export default function QuickOptions() {
  const isCollapsed = useSelector(
    (state: RootState) => state.layout.isSidebarCollapsed
  );
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(true);
  };

  return (
    <>
      <Container onClick={handleClick} isCollapsed={isCollapsed}>
        <BsLightningFill style={{ color: "#ffe600" }} />
        {!isCollapsed && (
          <>
            <Text>Opciones rápidas</Text>
            <ChevronRight />
          </>
        )}
      </Container>
      <QuickOptionsModal isVisible={isVisible} setIsVisible={setIsVisible} />
    </>
  );
}

const Container = styled.div<ContainerI>`
  border: 1px solid #c1c1c1;
  border-radius: 8px;
  padding-left: ${(props) => (!props.isCollapsed ? "12px" : "0px")};
  padding-right: ${(props) => (!props.isCollapsed ? "8px" : "0px")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (!props.isCollapsed ? "flex-start" : "center")};
  background-color: white;
  margin-left: 15px;
  margin-right: ${(props) => (!props.isCollapsed ? "8px" : "15px")};
  margin-top: 14px;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
  :hover {
    background-color: rgba(155, 155, 155, 0.1);
  }
  gap: 4px;
`;

const Text = styled.p`
  flex: 1;
  font-size: 14px;
  font-weight: 500;
`;
