import { createSlice } from "@reduxjs/toolkit";

interface InitialStateI {
  userIsPro: boolean;
  currentPlan: null | any;
  selectedBusiness:
    | {
        id: string;
        ownerId: string;
        ownerName: string;
        url: string;
      }
    | any;
  isBusinessesModalVisible: boolean;
  isSidebarCollapsed: boolean;
  isNewProductModalVisible: boolean;
  isNewTagModalVisible: boolean;
  username: string | null;
  snackBarMessage: string;
  showSnackbar: boolean;
  salesCart: any[];
  purchasesCart: any[];
  changingBusiness: boolean;
  employee_business: any;
  collaboration: boolean;
}

const initialState: InitialStateI = {
  userIsPro: false,
  currentPlan: null,
  selectedBusiness: {
    id: "",
    ownerId: "",
    ownerName: "",
    url: "",
  },
  isBusinessesModalVisible: false,
  isSidebarCollapsed: false,
  isNewProductModalVisible: false,
  isNewTagModalVisible: false,
  username: null,
  showSnackbar: false,
  snackBarMessage: "",
  salesCart: [],
  purchasesCart: [],
  changingBusiness: false,
  employee_business: {
    userId: "",
    businessId: "",
    role: "",
    access: true,
  },
  collaboration: false,
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload.username;
    },
    setUserIsPro: (state, action) => {
      state.userIsPro = action.payload.userIsPro;
    },
    setCurrentPlan: (state, action) => {
      state.currentPlan = action.payload.currentPlan;
    },
    setSelectedBusiness: (state, action) => {
      state.selectedBusiness = action.payload.selectedBusiness;
    },
    setIsBusinessesModalVisible: (state, action) => {
      state.isBusinessesModalVisible = action.payload.isBusinessesModalVisible;
    },
    setIsSidebarCollapsed: (state, action) => {
      state.isSidebarCollapsed = action.payload.isSidebarCollapsed;
    },
    setIsNewProductModalVisible: (state, action) => {
      state.isNewProductModalVisible = action.payload.isNewProductModalVisible;
    },
    setIsNewTagModalVisible: (state, action) => {
      state.isNewTagModalVisible = action.payload.isNewTagModalVisible;
    },
    setShowGeneralSnackbar: (state, action) => {
      state.showSnackbar = action.payload.showSnackbar;
    },
    setGeneralSnackBarMessage: (state, action) => {
      state.snackBarMessage = action.payload.snackBarMessage;
    },
    setEmptySalesCart: (state, action) => {
      state.salesCart = [];
    },
    setProductsInTheSalesCart: (state, action) => {
      state.salesCart = action.payload.salesCart;
    },
    setAddProductInTheSalesCart: (state, action) => {
      state.salesCart = [...state.salesCart, action.payload.newProduct];
    },
    setEmptyPurchasesCart: (state, action) => {
      state.purchasesCart = [];
    },
    setProductsInThePurchasesCart: (state, action) => {
      state.purchasesCart = action.payload.purchasesCart;
    },
    setAddProductInThePurchasesCart: (state, action) => {
      state.purchasesCart = [...state.purchasesCart, action.payload.newProduct];
    },
    setChangingBusiness: (state, action) => {
      state.changingBusiness = action.payload.changingBusiness;
    },
    setEmployeeBusiness: (state, action) => {
      state.employee_business = action.payload.employee_business;
    },
    setCollaboration: (state, action) => {
      state.collaboration = action.payload.collaboration;
    },
  },
});

export const {
  setSelectedBusiness,
  setIsBusinessesModalVisible,
  setIsSidebarCollapsed,
  setIsNewProductModalVisible,
  setIsNewTagModalVisible,
  setUsername,
  setGeneralSnackBarMessage,
  setShowGeneralSnackbar,
  setAddProductInTheSalesCart,
  setEmptySalesCart,
  setProductsInTheSalesCart,
  setChangingBusiness,
  setAddProductInThePurchasesCart,
  setEmptyPurchasesCart,
  setProductsInThePurchasesCart,
  setEmployeeBusiness,
  setCurrentPlan,
  setUserIsPro,
  setCollaboration,
} = layoutSlice.actions;
export default layoutSlice.reducer;
