import React, { Ref, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { IconButton } from "@mui/material";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";

interface Props {
  options: any[];
}

export default function FeaturesCarousel({ options }: Props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [isUserInteracting, setIsUserInteracting] = useState(false);
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const autoScrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const autoScrollIntervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (sliderRef.current) {
      setContainerWidth(sliderRef?.current?.offsetWidth);
    }
    const handleResize = () => {
      if (sliderRef.current) {
        setContainerWidth(sliderRef?.current?.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (sliderRef?.current) {
      sliderRef?.current?.scrollTo({
        left: containerWidth * currentIndex,
        behavior: "smooth",
      });
    }
  }, [currentIndex]);

  const startAutoScroll = () => {
    autoScrollIntervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === options.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);
  };

  const pauseAutoScroll = () => {
    if (autoScrollIntervalRef.current) {
      clearInterval(autoScrollIntervalRef.current);
    }
    if (autoScrollTimeoutRef.current) {
      clearTimeout(autoScrollTimeoutRef.current);
    }
    setIsUserInteracting(true);

    autoScrollTimeoutRef.current = setTimeout(() => {
      setIsUserInteracting(false);
      startAutoScroll();
    }, 5000);
  };

  useEffect(() => {
    if (!isUserInteracting) {
      startAutoScroll();
    }
    return () => {
      if (autoScrollIntervalRef.current) {
        clearInterval(autoScrollIntervalRef.current);
      }
      if (autoScrollTimeoutRef.current) {
        clearTimeout(autoScrollTimeoutRef.current);
      }
    };
  }, [isUserInteracting]);

  const scrollToLeft = () => {
    if (sliderRef.current) {
      pauseAutoScroll();
      const newIndex =
        currentIndex === 0 ? options.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
    }
  };

  const scrollToRight = () => {
    if (sliderRef.current) {
      pauseAutoScroll();
      const newIndex =
        currentIndex === options.length - 1 ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    }
  };

  return (
    <SliderContainer>
      <Slider ref={sliderRef}>
        {options.map((item, index) => (
          <div key={index} style={{ flex: 1 }}>
            {item}
          </div>
        ))}
        <ArrowContainer>
          <StyledIconButton onClick={scrollToLeft}>
            <ChevronLeft style={{ color: "white" }} fontSize="large" />
          </StyledIconButton>
          <StyledIconButton onClick={scrollToRight}>
            <ChevronRight style={{ color: "white" }} fontSize="large" />
          </StyledIconButton>
        </ArrowContainer>
        <Pagination>
          {options.map((item, index) => (
            <PaginationItem
              key={index}
              currentIndex={currentIndex}
              index={index}
              setCurrentIndex={setCurrentIndex}
              pauseAutoScroll={pauseAutoScroll}
            />
          ))}
        </Pagination>
      </Slider>
    </SliderContainer>
  );
}

const PaginationItem = ({
  currentIndex,
  index,
  setCurrentIndex,
  pauseAutoScroll,
}: {
  currentIndex: number;
  index: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  pauseAutoScroll: () => void;
}) => {
  const [isSelected, setIsSelected] = useState(currentIndex === index);

  useEffect(() => {
    setIsSelected(currentIndex === index);
  }, [currentIndex]);

  const handleClick = () => {
    pauseAutoScroll();
    setCurrentIndex(index);
  };

  return (
    <Item
      onClick={handleClick}
      style={{ backgroundColor: isSelected ? "#FFDE59" : "#dedede" }}
    />
  );
};

const SliderContainer = styled.div`
  position: relative;
  width: calc(100vw - 600px);
  height: 100%;
`;

const Slider = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  cursor: pointer;
`;

const Pagination = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 11px;
  width: 100%;
  margin-bottom: 35px;
  z-index: 100;
`;

const ArrowContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: calc(50%);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  opacity: 0;
  transition: all 0.4s ease;

  ${Slider}:hover & {
    opacity: 1;
  }
`;

const StyledIconButton = styled(IconButton)`
  border: 1px solid white;
`;

const Item = styled.div`
  height: 9px;
  width: 40px;
  background-color: #dedede;
  border-radius: 10px;
  cursor: pointer;
`;
