import { MAIN_COLOR } from "./colors";

const employeesRoles = [
  {
    name: "Administrador general",
    content:
      "Este es el mayor rol (por debajo del rol propietario). El rol Admin puede acceder a cualquier sección de la app (transacciones, estadísticas, inventario, tienda virtual, etc). Puede ver la lista de trabajadores, eliminarlos y editar su información. La diferencia entre un Admin General y el Propietario del negocio es que el propietario puede eliminar a un Admin General, pero un Admin General no puede eliminar al propietario.",
    role: "ROLE_ADMIN",
    color: MAIN_COLOR,
  },
  {
    name: "Empleado",
    content:
      "El rol Empleado o Colaborador es un rol básico, pero a la vez general, ya que, este rol tiene acceso a cualquier sección de la app (transacciones, estadísticas, inventario, tienda virtual, etc), pero no podrá ver la lista de trabajadores ni tampoco podrá eliminarlos o editarlos.",
    role: "ROLE_EMPLOYEE",
    color: "#FF6D60",
  },
  {
    name: "Community manager",
    content:
      "El rol Community Manager solamente podrá acceder a la sección de Tienda Virtual. El objetivo de este rol es que pueda edtiar el enlace de la tienda virtual, elegir la paleta de colores, editar horario de atención, responder reseñas, agregar las redes sociales del negocio, es decir, enfocarse solamente en la tienda virtual. Este rol no puede ver la lista de trabajadores ni eliminarlos, tampoco podrá acceder a las secciones de INVENTARIO, ESTADÍSTICAS Y TRANSACCIONES.",
    role: "ROLE_CM",
    color: "#F7D060",
  },
  {
    name: "Administrador del Inventario",
    content:
      "El rol Administrador del Invetario se encarga de administrador todo lo relacionado al inventario. Por ejemplo, podrá agregar, editar o eliminar productos, agregar categorías, subcategorías, etiquetas, exportar los datos a excel, visualizar la información del inventrario, etc. Este rol no puede ver la lista de trabajadores ni eliminarlos, tampoco podrá acceder a las secciones de TRANSACCIONES, ESTADÍSTICAS y TIENDA VIRTUAL.",
    role: "ROLE_INVENTORY",
    color: "#009FBD",
  },
  {
    name: "Vendedor",
    content:
      "El rol Vendedor tendrá acceso a la sección de transacciones para poder justamente registrar cualquier tipo de transacción (ventas, compras, ingresos, gastos, deudas y abonos). Este rol no puede ver la lista de trabajadores ni eliminarlos, tampoco podrá acceder a las secciones de INVENTARIO, TIENDA VIRTUAL y ESTADÍSTICAS",
    role: "ROLE_SELLER",
    color: "#7149C6",
  },
  {
    name: "Analista de las Estadísticas",
    content:
      "El rol Analista de Estadísticas podrá visualizar la sección de estadísticas, este rol tiene por objetivo poder visualizar las estadísticas (ingresos, ganancias, etc) de un negocio en cualquier periodo de tiempo que se seleccione. Este rol no puede ver la lista de trabajadores ni eliminarlos, tampoco podrá acceder a las secciones de INVENTARIO, TIENDA VIRTUAL Y TRANSACCIONES.",
    role: "ROLE_STATISTICS",
    color: "#362FD9",
  },
];

export default employeesRoles;
