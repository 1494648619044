import { useState } from "react";
import styled from "styled-components";
import { IoMdLink } from "react-icons/io";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import BusinessLinkModal from "./BusinessLinkModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../context/store";

const DOMAIN = "https://ganvent.shop/";

export default function BusinessLink() {
  const selectedBusiness = useSelector(
    (state: RootState) => state.layout.selectedBusiness
  );
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Container onClick={handleClick}>
        <IoMdLink style={{ color: "white", fontSize: "20px" }} />
        <InformationContainer>
          <Label>Dominio o Link de tu tienda online:</Label>
          <Domain>
            {DOMAIN}
            {selectedBusiness?.url}
          </Domain>
        </InformationContainer>
        <ArrowDropDown style={{ color: "white" }} />
      </Container>
      <BusinessLinkModal isVisible={isOpen} setIsVisible={setIsOpen} />
    </>
  );
}

const Container = styled.div`
  background-color: #00a680;
  padding-left: 15px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  height: 50px;
  transition: all 0.1 ease;
  :hover {
    box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.16);
  }
`;

const InformationContainer = styled.div`
  margin-left: 12px;
  margin-right: 5px;
`;

const Label = styled.p`
  color: white;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 3px;
`;

const Domain = styled.p`
  color: white;
  font-weight: 600;
  font-size: 13px;
`;
