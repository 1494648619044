import Close from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../../../context/store";
import InputWithLabel from "../../../login/InputWithLabel";
import CustomDivider from "../../../global/CustomDivider";
import {
  setGeneralSnackBarMessage,
  setSelectedBusiness,
  setShowGeneralSnackbar,
} from "../../../../context/reducers/layout";
import ganvent_urls from "../../../../utils/ganvent_urls";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../../firebase";

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const DOMAIN = "https://ganvent.shop/";

export default function EditLinkModal({ isVisible, setIsVisible }: Props) {
  const selectedBusiness = useSelector(
    (state: RootState) => state.layout.selectedBusiness
  );
  const [updating, setUpdating] = useState(false);
  const [newLink, setNewLink] = useState<string>(selectedBusiness?.url ?? "");
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [formError, setFormError] = useState<any>({});
  const dispatch = useDispatch();

  const closeModal = () => {
    setIsVisible(false);
    setNewLink(selectedBusiness?.url ?? "");
    setUnsavedChanges(false);
    setFormError({});
  };

  const updateBusinessInFirestore = () => {
    const businessesCollection = collection(db, "business");

    const businessDoc = doc(db, "business", selectedBusiness?.id);

    updateDoc(businessDoc, {
      url: newLink?.toLowerCase()?.trim(),
    })
      .then(() => {
        const newSelectedBusiness = {
          ...selectedBusiness,
          url: newLink?.toLowerCase()?.trim(),
        };
        setUpdating(false);
        setIsVisible(false);
        dispatch(
          setShowGeneralSnackbar({
            showSnackbar: true,
          })
        );
        dispatch(
          setGeneralSnackBarMessage({
            snackBarMessage: "Link actualizado correctamente",
          })
        );
        dispatch(
          setSelectedBusiness({
            selectedBusiness: newSelectedBusiness,
          })
        );
      })
      .catch((err) => {
        setUpdating(false);
        dispatch(
          setShowGeneralSnackbar({
            showSnackbar: true,
          })
        );
        dispatch(
          setGeneralSnackBarMessage({
            snackBarMessage: "Ocurrió un error",
          })
        );
      });
  };

  const handleSave = () => {
    let errors: any = {};
    if (!newLink) {
      errors.link = true;
      errors.linkMessage = "El campo está vacío";
    } else {
      if (newLink?.length >= 3) {
        dispatch(
          setShowGeneralSnackbar({
            showSnackbar: true,
          })
        );
        dispatch(
          setGeneralSnackBarMessage({
            snackBarMessage: "Comprobando disponibilidad del enlace",
          })
        );
        if (ganvent_urls.includes(newLink?.toLowerCase()?.trim())) {
          dispatch(
            setShowGeneralSnackbar({
              showSnackbar: true,
            })
          );
          dispatch(
            setGeneralSnackBarMessage({
              snackBarMessage: "Enlace NO DISPONIBLE, intenta con otro",
            })
          );
        } else {
          setUpdating(true);
          const businessesCollection = collection(db, "business");

          const q = query(
            businessesCollection,
            where("url", "==", newLink?.toLowerCase()?.trim())
          );

          getDocs(q)
            .then((response) => {
              if (!response.empty) {
                let tempArray: any[] = [];
                response.forEach((doc) => {
                  let object = doc.data();
                  object.id = doc.id;
                  tempArray.push(object);
                });
                dispatch(
                  setShowGeneralSnackbar({
                    showSnackbar: true,
                  })
                );
                dispatch(
                  setGeneralSnackBarMessage({
                    snackBarMessage: "Enlace NO DISPONIBLE, intenta con otro",
                  })
                );
                setUpdating(false);
              } else {
                updateBusinessInFirestore();
              }
            })
            .catch(() => {
              setUpdating(false);
              dispatch(
                setShowGeneralSnackbar({
                  showSnackbar: true,
                })
              );
              dispatch(
                setGeneralSnackBarMessage({
                  snackBarMessage: "Ocurrió un error",
                })
              );
            });
        }
      } else {
        errors.link = true;
        errors.linkMessage = "El enlace debe tener como MÍNIMO 3 CARACTERES";
      }
    }
    setFormError(errors);
  };

  return (
    <CustomDrawer
      open={isVisible}
      onClose={closeModal}
      anchor="right"
      variant="temporary"
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Container>
        <Header>
          <Title>Nuevo enlace del negocio</Title>
          <IconButton onClick={closeModal}>
            <Close />
          </IconButton>
        </Header>
        <Content>
          <InputWithLabel
            label="Enlace del negocio"
            placeholder="Escribe aquí el nuevo enlace"
            error={formError?.link}
            value={newLink}
            setValue={setNewLink}
            setUnsavedChanges={setUnsavedChanges}
          />
          {formError?.link && formError?.linkMessage && (
            <ErrorText>{formError?.linkMessage}</ErrorText>
          )}
          <Row>
            <Circle />
            <Rule>El enlace NO puede tener espacios en blanco</Rule>
          </Row>
          <Row>
            <Circle />
            <Rule>
              Si el enlace consta de varias palabras, entonces puedes separarlos
              mediante guiones "-" o colocar todas las palabras juntas
            </Rule>
          </Row>
          <Row>
            <Circle />
            <Rule>Por ejemplo: lamejortienda o la-mejor-tienda</Rule>
          </Row>
          <CustomDivider marginTop={15} />
          <PreviewMessage>
            👉 El enlace de tu negocio se verá así:
          </PreviewMessage>
          <PreviewContainer>
            <PreviewLink>
              {DOMAIN}
              {newLink !== "" ? newLink : "nuevoenlace"}
            </PreviewLink>
          </PreviewContainer>
        </Content>
        <CustomDivider marginTop={15} />
        <Footer>
          <StyledButton
            disabled={updating || !unsavedChanges}
            style={{ background: !unsavedChanges ? "#c1c1c1" : "#00a680" }}
            onClick={handleSave}
          >
            {updating ? (
              <CircularProgress style={{ color: "white" }} size={30} />
            ) : (
              <StyledButtonText>Guardar cambios</StyledButtonText>
            )}
          </StyledButton>
        </Footer>
      </Container>
    </CustomDrawer>
  );
}

const CustomDrawer = styled(Drawer)``;

const Container = styled.div`
  width: 400px;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 18px;
  padding-top: 18px;
  padding-right: 10px;
`;

const Title = styled.p`
  flex: 1;
  font-weight: 600;
`;

const Content = styled.div`
  padding-left: 18px;
  padding-right: 18px;
  margin-top: 15px;
  flex: 1;
`;

const ErrorText = styled.p`
  color: red;
  font-size: 12px;
  margin-top: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  margin-top: 15px;
`;

const Rule = styled.p`
  flex: 1;
  font-size: 14px;
`;

const Circle = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 100%;
  background-color: #00a680;
`;

const Footer = styled.div`
  display: flex;
  padding: 20px 20px;
`;

const StyledButton = styled(Button)`
  background-color: #00a680;
  padding: 10px 30px;
  width: 100%;
`;

const StyledButtonText = styled.p`
  text-transform: none;
  &&& {
    font-family: "Montserrat";
    color: white;
  }
`;

const PreviewMessage = styled.p`
  margin-top: 10px;
  font-size: 14px;
`;

const PreviewContainer = styled.div`
  margin-top: 15px;
  background-color: #00a680;
  padding: 15px 20px;
  border-radius: 5px;
`;

const PreviewLink = styled.div`
  color: white;
  font-size: 14px;
`;
