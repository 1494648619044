export const base = {
  colors: {
    primary: "#00a680",
  },
  fonts: {
    primary: "Figtree, Segoe UI, Roboto, system-ui, sans-serif",
    bold: "Figtree-Bold, Segoe UI, Roboto, system-ui, sans-serif",
    semiBold: "Figtree-SemiBold, Segoe UI, Roboto, system-ui, sans-serif",
    medium: "Figtree-Medium, Segoe UI, Roboto, system-ui, sans-serif",
    regular: "Figtree-Regular, system-ui, sans-serif",
    light: "Figtree-Regular, system-ui, sans-serif",
    saeLogo: "Georgia, Times New Roman, Times, serif",
    microsoftBtn: "Segoe-UI, Tahoma, Geneva, Verdana, sans-serif",
  },
  breakpoints: {
    xs: "screen and (max-width: 330px)",
    sd: "screen and (max-width: 400px)",
    xm: "screen and (max-width: 540px)",
    sm: "screen and (max-width: 640px)",
    md: "screen and (max-width: 768px)",
    ml: "screen and (max-width: 860px)",
    lg: "screen and (max-width: 1024px)",
    xl: "screen and (max-width: 1280px)",
    xxl: "screen and (max-width: 1400px)",
  },
};
