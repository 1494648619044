export const checkUserRoles = (
  userRoles: any[],
  allowedRoles: any[]
): boolean => {
  if (Array.isArray(userRoles)) {
    let band = false;
    allowedRoles?.forEach((allowedRol, i) => {
      userRoles?.forEach((userRol, j) => {
        if (allowedRol === userRol) {
          band = true;
        }
      });
    });
    return band;
  }
  return true;
};
