const ganvent_urls = [
  "pricing",
  "home",
  "about",
  "index",
  "features",
  "politica-de-privacidad",
  "política-de-privacidad",
  "términos-y-condiciones",
  "privacy-policy",
  "terms-and-conditions",
  "blog",
  "contact",
  "about-us",
  "aboutus",
  "ganvent",
  "ganvent1",
  "ganvent123",
  "ganventshop",
  "treinta",
  "treintashop",
  "treinta30",
  "tiendada",
  "tiendada2",
  "preguntas-y-respuestas",
  "guia",
  "tutoriales",
  "sobre-nosotros",
  "ganvent.shop",
  "ganvent.com",
];

export default ganvent_urls;
