import React from "react";
import styled from "styled-components";

interface Props {
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
}

const DEFAULT_LINK = "DEFAULT_LINK";
const CUSTOM_DOMAIN = "CUSTOM_DOMAIN";

export default function TopTab({ selectedTab, setSelectedTab }: Props) {
  const list = [
    {
      title: "Link proporcionado",
      title2: "por Ganvent",
      id: DEFAULT_LINK,
    },
    {
      title: "Configurar dominio",
      title2: "personalizado",
      id: CUSTOM_DOMAIN,
    },
  ];

  const handleClick = (id: string) => {
    setSelectedTab(id);
  };

  return (
    <Container>
      {list.map((item, index) => (
        <Tab
          onClick={() => handleClick(item.id)}
          key={index}
          style={{
            backgroundColor: selectedTab === item.id ? "#00a680" : "white",
          }}
        >
          <Title
            style={{
              color: selectedTab === item.id ? "white" : "black",
            }}
          >
            {item.title}
          </Title>
          {item?.title2 && (
            <Title
              style={{
                color: selectedTab === item.id ? "white" : "black",
              }}
            >
              {item.title2}
            </Title>
          )}
        </Tab>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  padding-left: 18px;
  padding-right: 18px;
`;

const Tab = styled.div`
  flex: 1;
  border: 1px solid #00a680;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: rgba(155, 155, 155, 0.4);
  }
`;

const Title = styled.p`
  text-align: center;
  font-size: 13px;
  font-weight: 600;
`;
