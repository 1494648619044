import { Button } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { FaWhatsapp } from "react-icons/fa";

export default function SupportButton() {
  const openWhatsapp = () => {
    const url = `https://wa.me/+51912745576/?text=Hola%20Soy%20usuario/a%20de%20Ganvent,%20vengo%20desde%20la%20versión%20web%20de%20Ganvent%20y%20tengo%20una%20duda%20sobre%20cómo%20utilizar%20Ganvent`;
    window.open(url, "_blank");
  };

  return (
    <Container onClick={openWhatsapp}>
      <IconContainer>
        <FaWhatsapp style={{ color: "white", fontSize: "20px" }} />
      </IconContainer>
      <InformationContainer>
        <Label>Soporte en vivo 🚀</Label>
        <Label2>¿En qué podemos ayudarte?</Label2>
      </InformationContainer>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  cursor: pointer;
  background-color: #00a680;
  bottom: 30px;
  right: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 12px;
  box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.16);
`;

const IconContainer = styled.div`
  margin-right: 10px;
  margin-left: 15px;
`;

const InformationContainer = styled.div``;

const Label = styled.p`
  color: white;
  font-size: 12px;
  font-weight: 400;
`;

const Label2 = styled.p`
  color: white;
  font-size: 14px;
  font-weight: 600;
`;
