import React from "react";
import Close from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import styled from "styled-components";

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function BarCodeModal({ isVisible, setIsVisible }: Props) {
  const handleClose = () => {
    setIsVisible(false);
  };

  const navigateTo = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <StyledModal open={isVisible} onClose={handleClose}>
      <Container>
        <Header>
          <Title>Escáner de código de barras</Title>
          <IconButton onClick={handleClose}>
            <Close style={{ color: "white" }} />
          </IconButton>
        </Header>
        <Description>
          El Escáner de código de barras solamente está disponible en la App
          móvil de Ganvent. Puedes descargarlo en PlayStore o AppStore
        </Description>
        <Footer>
          <ImageContainer
            onClick={() =>
              navigateTo(
                "https://play.google.com/store/apps/details?id=com.olivaresventuraricardo.minventapp"
              )
            }
          >
            <Image src={require("../../../images/playstoreLogo.png")} />
          </ImageContainer>
          <ImageContainer
            onClick={() =>
              navigateTo(
                "https://apps.apple.com/pe/app/ganvent-crea-tu-tienda-online/id1664995370"
              )
            }
          >
            <Image src={require("../../../images/appstoreLogo.png")} />
          </ImageContainer>
        </Footer>
      </Container>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
`;

const Header = styled.header`
  background-color: #00a680;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 20px;
`;

const Title = styled.p`
  font-weight: 600;
  color: white;
  flex: 1;
`;

const Description = styled.p`
  margin-top: 18px;
  padding: 0px 12px;
  font-size: 14px;
  font-weight: 500;
  max-width: 500px;
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  margin-top: 12px;
  padding-bottom: 15px;
`;

const StyledButton = styled(Button)`
  &&& {
    height: 40px;
    border-radius: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const StyledButtonText = styled.p`
  text-transform: none;
  &&& {
    font-family: "Montserrat";
    font-size: 13px;
  }
`;

const ImageContainer = styled.div`
  width: 200px;
  height: 80px;
  cursor: pointer;
`;

const Image = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;
