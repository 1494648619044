import React from "react";
import styled from "styled-components";

interface Props {
  stepNumber: number;
}

export default function StepBar({ stepNumber }: Props) {
  return (
    <Container>
      <Bar />
      <NumberContainer>
        <Number>{stepNumber}</Number>
      </NumberContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-top: 20px;
`;

const Bar = styled.div`
  height: 10px;
  background-color: #00a680;
  width: 100%;
`;

const NumberContainer = styled.div`
  position: absolute;
  height: 45px;
  width: 45px;
  background-color: #00a680;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 70px;
`;

const Number = styled.p`
  color: white;
  font-weight: bold;
  font-size: 1.1rem;
`;
