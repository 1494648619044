import { useState } from "react";
import styled from "styled-components";
import ButtonLogin from "./ButtonLogin";
import googleLogo from "../../images/googleLogo.png";
import InputWithLabel from "./InputWithLabel";
import { Button, CircularProgress, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { auth, provider } from "../../firebase";
import { signInWithPopup, createUserWithEmailAndPassword } from "firebase/auth";
import { validationEmail } from "../../utils/validationEmail";

export default function SignUp() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [formError, setFormError] = useState<any>({});
  const navigate = useNavigate();

  const loginWithGoogle = async () => {
    setIsLoading(true);
    provider.setCustomParameters({ prompt: "select_account" });
    try {
      await signInWithPopup(auth, provider).then(() => {});
    } catch (err) {
      setIsLoading(false);
      console.log("Error: ", err);
    }
  };

  const handleClickSignIn = () => {
    navigate("/login");
  };

  const handleClickSignUp = () => {
    if (isLoading) return;
    let errors: any = {};
    if (!email || !password || !repeatPassword) {
      if (!email) {
        errors.email = true;
        errors.emailMessage = "El correo es obligatorio";
      }
      if (!password) {
        errors.password = true;
        errors.passwordMessage = "La contraseña es obligatoria";
      }
      if (!password) {
        errors.repeatPassword = true;
        errors.repeatPasswordMessage = "La contraseña es obligatoria";
      }
    } else if (!validationEmail(email)) {
      errors.email = true;
      errors.emailMessage = "Correo inválido, intenta con otro";
    } else if (password !== repeatPassword) {
      errors.password = true;
      errors.passwordMessage = "Las contraseñas no coinciden";
      errors.repeatPassword = true;
      errors.repeatPasswordMessage = "Las contraseñas no coinciden";
    } else if (password?.length < 6) {
      errors.password = true;
      errors.passwordMessage = "La contraseña debe tener mínimo 6 caracteres";
      errors.repeatPassword = true;
      errors.repeatPasswordMessage =
        "La contraseña debe tener mínimo 6 caracteres";
    } else {
      setIsLoading(true);
      createUserWithEmailAndPassword(auth, email?.trim(), password)
        .then(() => {})
        .catch((err) => {
          let message = "Ocurrió un error, inténtalo otra vez";
          switch (err.code) {
            case "auth/email-already-in-use":
              message = "El correo ya está siendo utilizado";
              break;
            // @ts-ignore: Unreachable code error
            case "auth/internal-error":
              message = "Ocurrió un error, inténtalo otra vez";
            default:
              break;
          }
          setSnackBarMessage(message);
          setShowSnackbar(true);
          setIsLoading(false);
        });
    }
    setFormError(errors);
  };

  return (
    <Container>
      <Header>
        <LogoContainer>
          <Image src={require("../../images/ganventLogo.png")} />
        </LogoContainer>
        <Ganvent>Ganvent</Ganvent>
      </Header>
      <Title>Create una</Title>
      <Title>cuenta</Title>
      <ButtonsContainer>
        <ButtonLogin
          title="Continuar con Google"
          image={googleLogo}
          handleClick={loginWithGoogle}
        />
      </ButtonsContainer>
      <Or>O</Or>
      <InputsContainer>
        <InputWithLabel
          label="Correo"
          placeholder="Correo"
          type="text"
          value={email}
          setValue={setEmail}
          error={formError?.email}
        />
        {formError?.email && formError?.emailMessage && (
          <ErrorText>{formError?.emailMessage}</ErrorText>
        )}
        <InputWithLabel
          label="Contraseña"
          placeholder="Contraseña"
          type={showPassword ? "text" : "password"}
          value={password}
          error={formError?.password}
          setValue={setPassword}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
        />
        {formError?.password && formError?.passwordMessage && (
          <ErrorText>{formError?.passwordMessage}</ErrorText>
        )}
        <InputWithLabel
          label="Repetir contraseña"
          placeholder="Repetir contraseña"
          type={showRepeatPassword ? "text" : "password"}
          value={repeatPassword}
          error={formError?.repeatPassword}
          setValue={setRepeatPassword}
          showPassword={showRepeatPassword}
          setShowPassword={setShowRepeatPassword}
        />
        {formError?.repeatPassword && formError?.repeatPasswordMessage && (
          <ErrorText>{formError?.repeatPasswordMessage}</ErrorText>
        )}
      </InputsContainer>
      <MainButton onClick={handleClickSignUp} disabled={isLoading}>
        {isLoading ? (
          <CircularProgress style={{ color: "white" }} size={20} />
        ) : (
          <MainButtonTitle>Registrarse</MainButtonTitle>
        )}
      </MainButton>
      <Row>
        <Text>Ya tengo una cuenta</Text>
        <Button onClick={handleClickSignIn}>
          <ButtonTitle>Inicia Sesión</ButtonTitle>
        </Button>
      </Row>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3200}
        onClose={() => setShowSnackbar(false)}
        message={snackBarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </Container>
  );
}

const Container = styled.div`
  padding-top: 55px;
  padding-left: 60px;
  padding-right: 60px;
  margin-bottom: 40px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 32px;
`;

const LogoContainer = styled.div`
  height: 50px;
  width: 50px;
  position: relative;
`;

const Image = styled.img`
  object-fit: contain;
  height: 100%;
  width: 100%;
`;

const Ganvent = styled.h1`
  font-size: 1.4rem;
`;

const Title = styled.p`
  font-size: 2.1rem;
  font-weight: 500;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 8px;
`;

const Or = styled.p`
  text-align: center;
  font-size: 1rem;
  margin-top: 20px;
  margin-bottom: 12px;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1px;
`;

const Text = styled.p`
  font-size: 14px;
  font-family: "Montserrat";
`;

const ButtonTitle = styled.p`
  text-transform: none;
  &&& {
    font-size: 14px;
    font-family: "Montserrat";
  }
`;

const MainButton = styled(Button)`
  &&& {
    background-color: #00a680;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
`;

const MainButtonTitle = styled.p`
  text-transform: none;
  color: white;
  &&& {
    font-size: 15px;
    font-family: "Montserrat";
  }
`;

const ErrorText = styled.p`
  color: red;
  font-size: 12px;
`;
