import React, { useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { useNavigate, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import SubOptionItem from "./SubOptionItem";
import { useSelector } from "react-redux";
import { RootState } from "../../../../context/store";

interface Props {
  item: any;
}

interface BarI {
  isSelected: boolean;
}

interface ContainerI {
  isCollapsed: boolean;
}

export default function OptionItem({ item }: Props) {
  const isCollapsed = useSelector(
    (state: RootState) => state.layout.isSidebarCollapsed
  );
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const checkIfOptionIsSelected = (): boolean => {
    const pathName = location?.pathname ?? "";
    return item?.path === pathName;
  };

  const handleClick = () => {
    if (item?.type === "SECTION") {
      setIsOpen(!isOpen);
    } else if (item?.type !== "SECTION" && item?.type !== "COMPONENT") {
      let navigateObject: any = {
        pathname: item?.path,
      };
      if (item?.search) {
        navigateObject.search = item?.search;
      }
      navigate(navigateObject);
    }
  };

  if (item?.type === "COMPONENT" && item?.component) {
    return item?.component;
  }

  if (item?.type === "SECTION" && isCollapsed) {
    return <></>;
  }

  return (
    <>
      <Container
        isCollapsed={isCollapsed}
        onClick={handleClick}
        style={{
          borderBottomWidth: item?.type === "SECTION" && !isCollapsed ? 1 : 0,
          borderTopWidth: item?.type === "SECTION" && !isCollapsed ? 1 : 0,
        }}
      >
        <Bar isSelected={checkIfOptionIsSelected()} />
        {item?.icon && (
          <IconContainer isCollapsed={isCollapsed}>{item.icon}</IconContainer>
        )}
        {!isCollapsed && (
          <>
            <Title
              style={{
                color: checkIfOptionIsSelected() ? "#00a680" : "black",
                fontWeight: checkIfOptionIsSelected() ? "bold" : "500",
              }}
            >
              {item.title}
            </Title>
            {item?.type === "SECTION" &&
              (isOpen ? <BiChevronUp /> : <BiChevronDown />)}
          </>
        )}
      </Container>
      {!isCollapsed && (
        <>
          {item?.subtitle && isOpen && <Subtitle>{item.subtitle}</Subtitle>}
          {item?.type === "SECTION" &&
            isOpen &&
            item?.options?.map((opt: any, index: number) => (
              <SubOptionItem option={opt} key={index} />
            ))}
        </>
      )}
    </>
  );
}

const Container = styled.div<ContainerI>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding-right: 18px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid rgba(155, 155, 155, 0.2);
  border-bottom: 1px solid rgba(155, 155, 155, 0.2);
  cursor: pointer;
  :hover {
    background-color: rgba(155, 155, 155, 0.2);
  }
`;

const Bar = styled.div<BarI>`
  width: 5px;
  height: 20px;
  background-color: ${(props) =>
    props.isSelected ? "#00a680" : "transparent"};
  border-radius: 5px;
  margin-right: 2px;
`;

const IconContainer = styled.div<ContainerI>`
  ${(props) =>
    props.isCollapsed &&
    css`
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

const Title = styled.p`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 3px;
  flex: 1;
  padding-left: 5px;
`;

const Subtitle = styled.p`
  font-size: 12px;
  padding-left: 18px;
  padding-right: 18px;
  margin-bottom: 10px;
  margin-top: 10px;
`;
