import ChevronRight from "@mui/icons-material/ChevronRight";
import React from "react";
import styled from "styled-components";
import { FaCrown } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState } from "../../../../context/store";
import {
  PREMIUM_ANNUAL,
  PREMIUM_ANNUAL_2,
  PREMIUM_BIANNUAL,
  PREMIUM_BIANNUAL_2,
  PREMIUM_MENSUAL,
  PREMIUM_MENSUAL_2,
} from "../../../../utils/globalConstants";

interface ContainerI {
  isCollapsed: boolean;
}

export default function CurrentPlan() {
  const isCollapsed = useSelector(
    (state: RootState) => state.layout.isSidebarCollapsed
  );
  const collaboration = useSelector(
    (state: RootState) => state.layout.collaboration
  );
  const userIsPro = useSelector((state: RootState) => state.layout.userIsPro);
  const currentPlan = useSelector(
    (state: RootState) => state.layout.currentPlan
  );

  const getCurrentPlan = () => {
    if (collaboration && userIsPro) return "Premium";
    if (!userIsPro || currentPlan == null) return "Plan gratuito";
    if (
      currentPlan?.plan == PREMIUM_ANNUAL ||
      currentPlan?.plan == PREMIUM_ANNUAL_2
    ) {
      return "Premium ANUAL";
    } else if (
      currentPlan?.plan == PREMIUM_MENSUAL ||
      currentPlan?.plan == PREMIUM_MENSUAL_2
    ) {
      return "Premium MENSUAL";
    } else if (
      currentPlan?.plan == PREMIUM_BIANNUAL ||
      currentPlan?.plan == PREMIUM_BIANNUAL_2
    ) {
      return "Premium SEMESTRAL";
    }
    return "Plan gratuito";
  };

  return (
    <Container
      isCollapsed={isCollapsed}
      style={{
        justifyContent: !isCollapsed ? "flex-start" : "center",
      }}
    >
      <FaCrown style={{ color: "#F5DE10", fontSize: "20px" }} />
      {!isCollapsed && (
        <>
          <Information>
            <Label>Tu plan actual es:</Label>
            <Title>{getCurrentPlan()}</Title>
          </Information>
          {/* <ChevronRight /> */}
        </>
      )}
    </Container>
  );
}

const Container = styled.div<ContainerI>`
  border: 1px solid #c1c1c1;
  border-radius: 8px;
  padding-left: ${(props) => (!props.isCollapsed ? "12px" : "0px")};
  padding-right: ${(props) => (!props.isCollapsed ? "8px" : "0px")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  margin-left: 15px;
  margin-right: ${(props) => (!props.isCollapsed ? "8px" : "15px")};
  margin-top: 14px;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
  :hover {
    background-color: rgba(155, 155, 155, 0.1);
  }
`;

const Information = styled.div`
  flex: 1;
  margin-left: 10px;
`;

const Label = styled.p`
  font-size: 10px;
  margin-bottom: 2px;
`;

const Title = styled.p`
  font-size: 13px;
  font-weight: 600;
`;
