import logo from "./logo.svg";
import { Suspense, lazy } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Login from "./pages/Login";
import Onboarding from "./pages/Onboarding";
import Layout from "./pages/Layout";

const OnlineStore = lazy(() => import("./pages/OnlineStore"));
const Inventory = lazy(() => import("./pages/Inventory"));
const CategoryPage = lazy(() => import("./pages/CategoryPage"));
const TagPage = lazy(() => import("./pages/TagPage"));
const ProductPage = lazy(() => import("./pages/ProductPage"));
const MyAccount = lazy(() => import("./pages/MyAccount"));
const Clients = lazy(() => import("./pages/Clients"));
const Providers = lazy(() => import("./pages/Providers"));
const Transactions = lazy(() => import("./pages/Transactions"));
const Statistics = lazy(() => import("./pages/Statistics"));
const JoinAbusiness = lazy(() => import("./pages/JoinAbusiness"));
const EmployeesList = lazy(() => import("./pages/EmployeesList"));
const Paywall = lazy(() => import("./pages/Paywall"));

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <Login />,
  },
  {
    path: "/forgot-password",
    element: <Login />,
  },
  {
    path: "/onboarding",
    element: <Onboarding />,
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <OnlineStore />,
      },
      {
        path: "/online-store",
        element: <OnlineStore />,
      },
      {
        path: "/inventory",
        element: <Inventory />,
      },
      {
        path: "/category/:categoryId",
        element: <CategoryPage />,
      },
      {
        path: "/tag/:tagId",
        element: <TagPage />,
      },
      {
        path: "/product/:productId",
        element: <ProductPage />,
      },
      {
        path: "/my-account",
        element: <MyAccount />,
      },
      {
        path: "/clients",
        element: <Clients />,
      },
      {
        path: "/providers",
        element: <Providers />,
      },
      {
        path: "/transactions",
        element: <Transactions />,
      },
      {
        path: "/statistics",
        element: <Statistics />,
      },
      {
        path: "/join-a-business",
        element: <JoinAbusiness />,
      },
      {
        path: "/employees-list",
        element: <EmployeesList />,
      },
      {
        path: "/paywall",
        element: <Paywall />,
      },
    ],
  },
]);

export default function App(): JSX.Element {
  return <RouterProvider router={router} />;
}
