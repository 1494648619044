import React, { useEffect, useState } from "react";
import CameraAlt from "@mui/icons-material/CameraAlt";
import Close from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Cropper from "react-easy-crop";
import styled from "styled-components";

interface Props {
  selectedImage: string;
  setSelectedImage: React.Dispatch<React.SetStateAction<string>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  uploading: boolean;
  croppedAreaPixels: any;
  setCroppedAreaPixels: React.Dispatch<React.SetStateAction<any>>;
}

export default function ProductPhoto({
  selectedImage,
  setSelectedImage,
  setShowModal,
  uploading,
  croppedAreaPixels,
  setCroppedAreaPixels,
}: Props) {
  const [blobUrl, setBlobUrl] = useState("");

  const callApiToGetCroppedImage = async () => {
    try {
      const newUrl = await getCroppedImgUrl();
      setBlobUrl(newUrl as string);
    } catch (err) {}
  };

  useEffect(() => {
    if (selectedImage) {
      callApiToGetCroppedImage();
    } else {
      setBlobUrl("");
    }
  }, [selectedImage]);

  const deleteImage = () => {
    setSelectedImage("");
  };

  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
    });

  const getCroppedImage = async (): Promise<Blob | null> => {
    if (!selectedImage || !croppedAreaPixels) return null;

    const canvas = document.createElement("canvas");
    const image = await createImage(selectedImage);
    const { width, height } = croppedAreaPixels;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");

    if (ctx) {
      ctx.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        width,
        height,
        0,
        0,
        width,
        height
      );
    }
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (blob) resolve(blob);
      }, "image/jpeg");
    });
  };

  const getCroppedImgUrl = async (): Promise<string | null> => {
    if (!selectedImage || !croppedAreaPixels) return null;

    const canvas = document.createElement("canvas");
    const image = await createImage(selectedImage);
    const { width, height } = croppedAreaPixels;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");

    if (ctx) {
      ctx.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        width,
        height,
        0,
        0,
        width,
        height
      );
    }

    // Cambiar la implementación de toDataURL para devolver el resultado directamente
    return canvas.toDataURL("image/jpeg");
  };

  return (
    <>
      <Container
        onClick={() => {
          if (uploading) return;
          setShowModal(true);
        }}
      >
        <ImageContainer
          style={{
            backgroundColor: !selectedImage ? "#c1c1c1" : "transparent",
            borderWidth: !selectedImage ? "1px" : "0px",
          }}
        >
          {selectedImage && blobUrl !== "" ? (
            <StyledImage src={blobUrl} />
          ) : (
            <CameraAlt style={{ fontSize: "30px" }} />
          )}
        </ImageContainer>
      </Container>
      {selectedImage && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "20px",
            justifyContent: "flex-end",
          }}
        >
          <StyledIconButton
            onClick={deleteImage}
            startIcon={<Close style={{ color: "red", fontSize: "10px" }} />}
          >
            <ButtonText>Eliminar imagen seleccionada</ButtonText>
          </StyledIconButton>
        </div>
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 150px;
  width: 150px;
  background-color: #c1c1c1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  border-radius: 100%;
  border: 1px dashed black;
  cursor: pointer;
  transition: all 0.3s ease;
  :hover {
    scale: 1.01;
  }
`;

const StyledImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const StyledIconButton = styled(Button)`
  &&& {
    border: 1px solid red;
  }
`;

const ButtonText = styled.div`
  &&& {
    text-transform: none;
    font-family: "Montserrat";
    font-size: 11px;
    color: red;
  }
`;
