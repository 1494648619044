import { createSlice } from "@reduxjs/toolkit";

interface InitialStateI {
  products: any[] | null;
  canLoadMore: boolean;
  isLoadingMoreData: boolean;
  lastProduct: any | null;
}

const initialState: InitialStateI = {
  products: null,
  canLoadMore: true,
  isLoadingMoreData: false,
  lastProduct: null,
};

const availableProductsSlice = createSlice({
  name: "availableProducts",
  initialState,
  reducers: {
    setAvailableProducts: (state, action) => {
      state.products = action.payload.products;
    },
    setLastProduct: (state, action) => {
      state.lastProduct = action.payload.lastProduct;
    },
    setCanLoadMore: (state, action) => {
      state.canLoadMore = action.payload.canLoadMore;
    },
    setIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
  },
});

export const {
  setAvailableProducts,
  setCanLoadMore,
  setIsLoadingMoreData,
  setLastProduct,
} = availableProductsSlice.actions;
export default availableProductsSlice.reducer;
