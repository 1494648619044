import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../context/store";
import styled from "styled-components";
import {
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Snackbar,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import { LuShapes } from "react-icons/lu";
import InputWithLabel from "../login/InputWithLabel";
import {
  addDoc,
  collection,
  db,
  onSnapshot,
  serverTimestamp,
} from "../../firebase";
import { getAuth } from "firebase/auth";
import {
  setIsCreating,
  setIsNewCategoryModalVisible,
} from "../../context/reducers/categories";
import PremiumModal from "../global/PremiumModal";

const MAX_QUANTITY_OF_CATEGORYS = 10;

export default function NewCategoryModal() {
  const selectedBusiness = useSelector(
    (state: RootState) => state.layout.selectedBusiness
  );
  const isVisible = useSelector(
    (state: RootState) => state.categories.isNewCategoryModalVisible
  );
  const userIsPro = useSelector((state: RootState) => state.layout.userIsPro);
  const creating = useSelector(
    (state: RootState) => state.categories.isCreating
  );
  const [name, setName] = useState("");
  const [formError, setFormError] = useState<any>({});
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [categorysTotal, setCategorysTotal] = useState(0);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const user = getAuth().currentUser;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userIsPro && selectedBusiness.id) {
      const categoriesCollection = collection(
        db,
        "business",
        selectedBusiness.id,
        "categorys"
      );

      const suscriber = onSnapshot(
        categoriesCollection,
        (snapshot) => {
          setCategorysTotal(snapshot.size);
        },
        (err) => {}
      );
      return () => {
        suscriber();
      };
    }
  }, [userIsPro, selectedBusiness.id]);

  const cleanFields = () => {
    setName("");
    setFormError({});
  };

  const closeModal = () => {
    dispatch(
      setIsNewCategoryModalVisible({
        isNewCategoryModalVisible: false,
      })
    );
    cleanFields();
  };

  const handleSave = () => {
    if (creating) return;
    let errors: any = {};
    if (!name) {
      errors.name = true;
      errors.nameMessage = "El nombre es obligatorio";
    } else {
      dispatch(
        setIsCreating({
          isCreating: true,
        })
      );
      dispatch(
        setIsNewCategoryModalVisible({
          isNewCategoryModalVisible: false,
        })
      );
      const categoriesRef = collection(
        db,
        "business",
        selectedBusiness.id,
        "categorys"
      );
      addDoc(categoriesRef, {
        name: name.toLowerCase().trim(),
        idUser: user?.uid,
        timestamp: serverTimestamp(),
      })
        .then(() => {
          dispatch(
            setIsCreating({
              isCreating: false,
            })
          );
          setShowSnackbar(true);
          setSnackBarMessage("Categoría creada correctamente ✅");
          cleanFields();
        })
        .catch(() => {
          dispatch(
            setIsCreating({
              isCreating: false,
            })
          );
          setShowSnackbar(true);
          setSnackBarMessage("Ocurrió un error");
          cleanFields();
        });
    }
    setFormError(errors);
  };

  const confirmHandleSave = () => {
    const auxCategorysTotal = categorysTotal;
    if (userIsPro) {
      handleSave();
    } else {
      if (auxCategorysTotal < MAX_QUANTITY_OF_CATEGORYS) {
        handleSave();
      } else {
        setShowPremiumModal(true);
      }
    }
  };

  return (
    <>
      <CustomDrawer
        open={isVisible}
        onClose={closeModal}
        anchor="right"
        variant="temporary"
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Container>
          <Header>
            <Title>Nueva categoría</Title>
            <IconButton onClick={closeModal}>
              <Close />
            </IconButton>
          </Header>
          <Content>
            <InputWithLabel
              label="Nombre"
              placeholder="Nombre de la categoría (Obligatorio)"
              labelIcon={<LuShapes style={{ fontSize: "13px" }} />}
              error={formError?.name}
              value={name}
              setValue={setName}
            />
            {formError?.name && formError?.nameMessage && (
              <ErrorText>{formError?.nameMessage}</ErrorText>
            )}
          </Content>
          <Footer>
            <StyledButton
              disabled={creating}
              style={{ background: "#00a680" }}
              onClick={confirmHandleSave}
            >
              {creating ? (
                <CircularProgress style={{ color: "white" }} size={30} />
              ) : (
                <StyledButtonText>Crear categoría</StyledButtonText>
              )}
            </StyledButton>
          </Footer>
        </Container>
      </CustomDrawer>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3200}
        onClose={() => setShowSnackbar(false)}
        message={snackBarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
      <PremiumModal
        isVisible={showPremiumModal}
        setIsVisible={setShowPremiumModal}
        list={[
          `En la versión gratuita solamente puedes agregar hasta ${MAX_QUANTITY_OF_CATEGORYS} categorías al inventario de tu tienda online`,
          "Si deseas agregar categorías SIN LÍMITE, entonces la versión PREMIUM de Ganvent podría ayudarte a llevar tu negocio al siguiente nivel",
        ]}
      />
    </>
  );
}

const CustomDrawer = styled(Drawer)``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  height: 100%;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 18px;
  padding-top: 18px;
  padding-right: 10px;
`;

const Title = styled.p`
  flex: 1;
  font-weight: 600;
`;

const Content = styled.div`
  padding-left: 18px;
  padding-right: 18px;
  margin-top: 15px;
  flex: 1;
`;

const Footer = styled.div`
  display: flex;
  border-top: 1px solid #c1c1c1;
  padding: 20px 20px;
`;

const StyledButton = styled(Button)`
  background-color: #00a680;
  padding: 10px 30px;
  width: 100%;
`;

const StyledButtonText = styled.p`
  text-transform: none;
  &&& {
    font-family: "Montserrat";
    color: white;
  }
`;

const ErrorText = styled.p`
  color: red;
  font-size: 12px;
  margin-top: 12px;
`;
