const CURRENCY_TYPE_NOT_SELECTED = "currencyType not selected";
const CATEGORY_NOT_SELECTED = "category not selected";
const SUBCATEGORY_NOT_SELECTED = "subcategory not selected";
const SELECTED_BUSINESS_ID = "SELECTED_BUSINESS_ID";
const TAGS_MAX_QUANTITY_PER_PRODUCT = 4;
const FEATURES_MAX_QUANTITY_PER_PRODUCT = 4;
const MAX_QUANTITY_OF_IMAGES_PER_PRODUCT_PREMIUM = 6;
const ENTITLEMENT_ID = "Pro";
const PREMIUM_MENSUAL = "ganvent_1m_ios";
const PREMIUM_MENSUAL_2 = "ganvent_999_1m";
const PREMIUM_BIANNUAL = "ganvent_6m_ios";
const PREMIUM_BIANNUAL_2 = "ganvent_4999_6m";
const PREMIUM_ANNUAL = "ganvent_year_ios";
const PREMIUM_ANNUAL_2 = "ganvent_9699_year";

export {
  CURRENCY_TYPE_NOT_SELECTED,
  SELECTED_BUSINESS_ID,
  TAGS_MAX_QUANTITY_PER_PRODUCT,
  FEATURES_MAX_QUANTITY_PER_PRODUCT,
  CATEGORY_NOT_SELECTED,
  SUBCATEGORY_NOT_SELECTED,
  MAX_QUANTITY_OF_IMAGES_PER_PRODUCT_PREMIUM,
  ENTITLEMENT_ID,
  PREMIUM_ANNUAL,
  PREMIUM_ANNUAL_2,
  PREMIUM_BIANNUAL,
  PREMIUM_BIANNUAL_2,
  PREMIUM_MENSUAL,
  PREMIUM_MENSUAL_2,
};
