import { createSlice } from "@reduxjs/toolkit";

interface InitialStateI {
  sales: any[] | null;
  canLoadMore: boolean;
  isLoadingMoreData: boolean;
  lastSale: any | null;
  isNewSaleModalVisible: boolean;
  isCreating: boolean;
}

const initialState: InitialStateI = {
  sales: null,
  canLoadMore: true,
  isLoadingMoreData: false,
  lastSale: null,
  isCreating: false,
  isNewSaleModalVisible: false,
};

const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    setSales: (state, action) => {
      state.sales = action.payload.sales;
    },
    setLastSale: (state, action) => {
      state.lastSale = action.payload.lastSale;
    },
    setCanLoadMore: (state, action) => {
      state.canLoadMore = action.payload.canLoadMore;
    },
    setIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
    setIsNewSaleModalVisible: (state, action) => {
      state.isNewSaleModalVisible = action.payload.isNewSaleModalVisible;
    },
    setIsCreating: (state, action) => {
      state.isCreating = action.payload.isCreating;
    },
  },
});

export const {
  setCanLoadMore,
  setIsCreating,
  setIsLoadingMoreData,
  setIsNewSaleModalVisible,
  setLastSale,
  setSales,
} = salesSlice.actions;
export default salesSlice.reducer;
