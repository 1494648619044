import React, { useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import styled from "styled-components";
import { FaWhatsapp } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa6";
import { SiGoogleforms } from "react-icons/si";
import { useSelector } from "react-redux";
import { RootState } from "../../../../context/store";

export default function Community() {
  const isCollapsed = useSelector(
    (state: RootState) => state.layout.isSidebarCollapsed
  );
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleRow = (url: string) => {
    window.open(url, "_blank");
  };

  if (isCollapsed) return <></>;

  return (
    <>
      <Container
        onClick={handleClick}
        style={{
          borderBottomWidth: 1,
          borderTopWidth: 1,
        }}
      >
        <Title>Comunidad</Title>
        {isOpen ? <BiChevronUp /> : <BiChevronDown />}
      </Container>
      {isOpen && (
        <>
          <Row
            onClick={() =>
              handleRow("https://whatsapp.com/channel/0029VaDgescA89MgPB79uK2M")
            }
          >
            <IconContainer
              style={{
                backgroundColor: "#00a680",
              }}
            >
              <FaWhatsapp style={{ color: "white" }} />
            </IconContainer>
            <Text>
              Únete a nuestro <HighlightText>canal de WhatsApp</HighlightText>y
              entérate de todas las novedades de Ganvent y del mundo del
              emprendimiento
            </Text>
          </Row>
          <Row
            onClick={() =>
              handleRow("https://chat.whatsapp.com/BWyILtw6zpeFUnpRxA3PyY")
            }
          >
            <IconContainer
              style={{
                backgroundColor: "#00a680",
              }}
            >
              <FaWhatsapp style={{ color: "white" }} />
            </IconContainer>
            <Text>
              Únete a nuestro <HighlightText>grupo de WhatsApp</HighlightText>
            </Text>
          </Row>
          <Row
            onClick={() => handleRow("https://t.me/joinchat/WL9T5e8jwThjNWVh")}
          >
            <IconContainer
              style={{
                backgroundColor: "#24A1DE",
              }}
            >
              <FaTelegram style={{ color: "white" }} />
            </IconContainer>
            <Text>Únete a nuestra comunidad de Telegram</Text>
          </Row>
          <Row onClick={() => handleRow("https://forms.gle/dRbr6rR9wLTcXK6AA")}>
            <IconContainer
              style={{
                backgroundColor: "#7246ba",
              }}
            >
              <SiGoogleforms style={{ color: "white" }} />
            </IconContainer>
            <Text>
              <HighlightText>Google Form: ¡Te escuchamos!</HighlightText> ¿Te
              gustaría una nueva funcionalidad o tienes alguna sugerencia para
              Ganvent? Entonces, llena este formulario
            </Text>
          </Row>
        </>
      )}
    </>
  );
}
const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid rgba(155, 155, 155, 0.2);
  border-bottom: 1px solid rgba(155, 155, 155, 0.2);
  cursor: pointer;
  :hover {
    background-color: rgba(155, 155, 155, 0.2);
  }
`;

const Title = styled.p`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 3px;
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  :hover {
    background-color: rgba(155, 155, 155, 0.2);
  }
`;

const IconContainer = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Text = styled.p`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 3px;
  flex: 1;
`;

const HighlightText = styled.p`
  font-weight: bold;
`;
