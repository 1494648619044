import ChevronRight from "@mui/icons-material/ChevronRight";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { RootState } from "../../context/store";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import { MdStore } from "react-icons/md";
import { getAuth } from "firebase/auth";
import { checkUserRoles } from "../../utils/checkUserRoles";
import {
  setGeneralSnackBarMessage,
  setShowGeneralSnackbar,
} from "../../context/reducers/layout";
import employeesRoles from "../../utils/employeesRoles";

interface ContainerI {
  isCollapsed: boolean;
}

export default function SelectedBusiness() {
  const isCollapsed = useSelector(
    (state: RootState) => state.layout.isSidebarCollapsed
  );
  const selectedBusiness = useSelector(
    (state: RootState) => state.layout.selectedBusiness
  );
  const employee_business = useSelector(
    (state: RootState) => state.layout.employee_business
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = getAuth().currentUser;

  const handleClick = () => {
    if (
      selectedBusiness?.userId == user?.uid ||
      checkUserRoles(employee_business?.role, ["OWNER", "ROLE_ADMIN"])
    ) {
      navigate({
        pathname: "/employees-list",
      });
    } else {
      dispatch(
        setShowGeneralSnackbar({
          showSnackbar: true,
        })
      );
      dispatch(
        setGeneralSnackBarMessage({
          snackBarMessage: "No puedes acceder a esta sección",
        })
      );
    }
  };

  const getRole = () => {
    if (user?.uid?.trim() == selectedBusiness?.userId) {
      return "Propietario";
    }
    let roles = employee_business?.role;
    let auxRoles = "";
    if (roles === "employee") {
      return "Empleado";
    }
    if (roles === "") {
      return "Sin rol";
    }
    if (Array.isArray(roles)) {
      roles.forEach((rolItem, i) => {
        employeesRoles.forEach((employee, j) => {
          if (i > 0 && rolItem === employee.role) {
            auxRoles += `, ${employee.name}`;
          } else if (rolItem == employee.role) {
            auxRoles += employee.name;
          }
        });
      });
      return auxRoles;
    }
    return "Sin rol";
  };

  return (
    <Container
      isCollapsed={isCollapsed}
      onClick={handleClick}
      style={{
        paddingLeft: !isCollapsed ? "15px" : "0px",
        paddingRight: !isCollapsed ? "15px" : "0px",
        justifyContent: !isCollapsed ? "inherit" : "center",
      }}
    >
      <ImageContainer>
        {selectedBusiness?.images !== undefined &&
        selectedBusiness?.images[0] ? (
          <Image src={selectedBusiness?.images[0]} />
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#00a680",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "15px",
            }}
          >
            <MdStore style={{ color: "white", fontSize: "24px" }} />
          </div>
        )}
      </ImageContainer>
      {!isCollapsed && (
        <>
          <InformationContainer>
            <Label>Negocio seleccionado:</Label>
            <Name>
              {capitalizeFirstLetter(
                selectedBusiness?.shopName
                  ? selectedBusiness?.shopName
                  : selectedBusiness?.localName
                  ? selectedBusiness?.localName
                  : selectedBusiness?.name?.local
                  ? selectedBusiness?.name?.local
                  : ""
              )}
            </Name>
            <Role>{getRole()}</Role>
          </InformationContainer>
          <ChevronRight />
        </>
      )}
    </Container>
  );
}

const Container = styled.div<ContainerI>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: ${(props) => (!props.isCollapsed ? "20px" : "8px")};
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  transition: all 0.2s ease;
  :hover {
    background-color: rgba(155, 155, 155, 0.2);
  }
`;

const ImageContainer = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50px;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
`;

const InformationContainer = styled.div`
  flex: 1;
  margin-left: 6px;
`;

const Label = styled.p`
  font-size: 11px;
  font-weight: 400;
`;

const Name = styled.p`
  font-weight: 500;
  margin-top: 1px;
  font-size: 14px;
`;

const Role = styled.p`
  font-size: 11px;
  font-weight: 400;
  margin-top: 2px;
`;
