import { createSlice } from "@reduxjs/toolkit";

interface InitialStateI {
  incomes: any[] | null;
  canLoadMore: boolean;
  isLoadingMoreData: boolean;
  lastIncome: any | null;
  isCreating: boolean;
}

const initialState: InitialStateI = {
  incomes: null,
  canLoadMore: true,
  isLoadingMoreData: false,
  lastIncome: null,
  isCreating: false,
};

const incomesSlice = createSlice({
  name: "incomes",
  initialState,
  reducers: {
    setIncomes: (state, action) => {
      state.incomes = action.payload.incomes;
    },
    setCanLoadMore: (state, action) => {
      state.canLoadMore = action.payload.canLoadMore;
    },
    setIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
    setIsCreating: (state, action) => {
      state.isCreating = action.payload.isCreating;
    },
    setLastIncome: (state, action) => {
      state.lastIncome = action.payload.lastIncome;
    },
  },
});

export const {
  setCanLoadMore,
  setIncomes,
  setIsCreating,
  setIsLoadingMoreData,
  setLastIncome,
} = incomesSlice.actions;
export default incomesSlice.reducer;
