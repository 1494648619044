import React, { useState } from "react";
import styled from "styled-components";
import { BiBarcodeReader } from "react-icons/bi";
import IconButton from "@mui/material/IconButton";
import BarCodeModal from "./BarCodeModal";

interface Props {
  barCode: any;
  setBarCode: React.Dispatch<React.SetStateAction<any>>;
}

interface InputI {
  error?: boolean;
}

export default function BarCode({ barCode, setBarCode }: Props) {
  const [isVisible, setIsVisible] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBarCode(e.target.value);
  };

  const handleClick = () => {
    setIsVisible(true);
  };

  return (
    <>
      <Container>
        <Row>
          <BiBarcodeReader />
          <Label>Código de barra</Label>
        </Row>
        <Row>
          <InputContainer style={{ flex: 1 }}>
            <Input
              maxLength={90}
              value={barCode}
              type={"text"}
              placeholder={"Escribe el código de barra aquí..."}
              onChange={handleChange}
            />
          </InputContainer>
          <StyledIconButton onClick={handleClick}>
            <BiBarcodeReader style={{ color: "white" }} />
          </StyledIconButton>
        </Row>
      </Container>
      <BarCodeModal isVisible={isVisible} setIsVisible={setIsVisible} />
    </>
  );
}

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin-bottom: 4px;
`;

const Label = styled.p`
  font-size: 13px;
  color: #000000;
  flex: 1;
`;

const InputContainer = styled.div<InputI>`
  border: 1px solid ${(props) => (!props.error ? "#c1c1c1" : "red")};
  width: 100%;
  border-radius: 10px;
  margin-top: 7px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Input = styled.input`
  outline: none;
  border: none;
  width: 100%;
  padding: 15px 12px;
  font-family: "Montserrat";
  font-size: 13px;
`;

const StyledIconButton = styled(IconButton)`
  &&& {
    background-color: #00a680;
    border-radius: 100%;
    margin-left: 8px;
    padding: 10px;
  }
`;
