import { createSlice } from "@reduxjs/toolkit";

interface InitialStateI {
  purchases: any[] | null;
  canLoadMore: boolean;
  isLoadingMoreData: boolean;
  lastPurchase: any | null;
  isCreating: boolean;
}

const initialState: InitialStateI = {
  purchases: null,
  canLoadMore: true,
  isLoadingMoreData: false,
  lastPurchase: null,
  isCreating: false,
};

const purchasesSlice = createSlice({
  name: "purchases",
  initialState,
  reducers: {
    setPurchases: (state, action) => {
      state.purchases = action.payload.purchases;
    },
    setLastPurchase: (state, action) => {
      state.lastPurchase = action.payload.lastPurchase;
    },
    setCanLoadMore: (state, action) => {
      state.canLoadMore = action.payload.canLoadMore;
    },
    setIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
    setIsCreating: (state, action) => {
      state.isCreating = action.payload.isCreating;
    },
  },
});

export const {
  setCanLoadMore,
  setIsCreating,
  setIsLoadingMoreData,
  setLastPurchase,
  setPurchases,
} = purchasesSlice.actions;
export default purchasesSlice.reducer;
