import React from "react";
import styled from "styled-components";
import Close from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CheckUsernameModal({ isVisible, setIsVisible }: Props) {
  const navigate = useNavigate();

  const handleClose = () => {
    setIsVisible(false);
  };

  const navigateToAccount = () => {
    navigate({
      pathname: "/my-account",
    });
  };

  return (
    <StyledDrawer
      anchor="bottom"
      open={isVisible}
      onClose={handleClose}
      SlideProps={{
        style: {
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        },
      }}
    >
      <Container>
        <Row>
          <Title>¡IMPORTANTE!</Title>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Row>
        <Text>
          -Para poder registrar transacciones y productos en un negocio
          asociado, primero debes establecer tu nombre de usuario.
        </Text>
        <Text>
          -Esto servirá para identificar las transacciones y productos que cada
          trabajador del negocio haya registrado.
        </Text>
        <Footer>
          <CustomButton onClick={navigateToAccount}>
            <CustomButtonTitle>Actualizar nombre de usuario</CustomButtonTitle>
          </CustomButton>
        </Footer>
      </Container>
    </StyledDrawer>
  );
}

const StyledDrawer = styled(Drawer)`
  &&& {
  }
`;

const Row = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 15px;
`;

const Title = styled.p`
  flex: 1;
  font-weight: 600;
  font-size: 1.1rem;
`;

const Container = styled.div`
  width: 50vw;
  background-color: white;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  padding: 8px 15px;
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
`;

const CustomButton = styled(Button)`
  &&& {
    background-color: #00a680;
    color: white;
    border-radius: 15px;
    padding: 8px 15px;
  }
`;

const CustomButtonTitle = styled.p`
  &&& {
    color: white;
    font-size: 15px;
    font-family: "Montserrat";
    text-transform: none;
  }
`;
