import { useState } from "react";
import styled from "styled-components";
import BusinessLink from "./mainHeader/BusinessLink";
import UserInfo from "./mainHeader/UserInfo";
import BusinessesModal from "../global/BusinessesModal";
import BusinessSelector from "./mainHeader/BusinessSelector";

export default function MainHeader() {
  const [isBusinessModalVisible, setIsBusinessModalVisible] = useState(false);

  return (
    <>
      <Container>
        <Row>
          <BusinessLink />
          <BusinessSelector
            setIsBusinessModalVisible={setIsBusinessModalVisible}
          />
        </Row>
        <Row style={{ gap: "8px" }}>
          {/*   <IconButton>
            <GoVideo style={{ color: "black", fontSize: "22px" }} />
          </IconButton> */}
          <UserInfo />
        </Row>
      </Container>
      <BusinessesModal
        isVisible={isBusinessModalVisible}
        setIsVisible={setIsBusinessModalVisible}
      />
    </>
  );
}

const Container = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-top: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
