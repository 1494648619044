import { useState } from "react";
import styled from "styled-components";
import ToggleLink from "./ToggleLink";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { RiPencilFill } from "react-icons/ri";
import { MdOutlineContentCopy } from "react-icons/md";
import { TbWorld } from "react-icons/tb";
import { useSelector } from "react-redux";
import { RootState } from "../../../../context/store";
import Snackbar from "@mui/material/Snackbar";
import EditLinkModal from "./EditLinkModal";

const DOMAIN = "https://ganvent.shop/";

export default function DefaultLink() {
  const selectedBusiness = useSelector(
    (state: RootState) => state.layout.selectedBusiness
  );
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const list = [
    {
      title: "Editar link",
      icon: <RiPencilFill />,
      onPressMethod: () => {
        setShowEditModal(true);
      },
    },
    /* {
      title: "Compartir link",
      icon: <IoIosShareAlt />,
      onPressMethod: () => {},
    }, */
    {
      title: "Copiar link",
      icon: <MdOutlineContentCopy />,
      onPressMethod: async () => {
        const businessUrl = `${DOMAIN}${selectedBusiness?.url}`;
        try {
          await navigator.clipboard.writeText(businessUrl);
          setShowSnackbar(true);
          setSnackBarMessage("✅ Link copiado!!");
        } catch (err) {
          console.log("Err: ", err);
        }
      },
    },
    {
      title: "Abrir link en el navegador",
      icon: <TbWorld />,
      onPressMethod: () => {
        const businessUrl = `${DOMAIN}${selectedBusiness?.url}`;
        const url = businessUrl;
        window.open(url, "_blank");
      },
    },
  ];

  return (
    <>
      <Container>
        <Title>Link proporcionado por Ganvent:</Title>
        <LinkContainer>
          <Link>
            {" "}
            {DOMAIN}
            {selectedBusiness?.url}
          </Link>
        </LinkContainer>
        <ToggleLink title="Utilizar este link como el link principal de tu tienda virtual" />
        <List>
          {list.map((item, index) => (
            <Option key={index} onClick={item.onPressMethod}>
              {item.icon}
              <OptionTitle>{item.title}</OptionTitle>
              <ChevronRight style={{ color: "#c1c1c1" }} />
            </Option>
          ))}
        </List>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={3200}
          onClose={() => setShowSnackbar(false)}
          message={snackBarMessage}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        />
      </Container>
      <EditLinkModal
        isVisible={showEditModal}
        setIsVisible={setShowEditModal}
      />
    </>
  );
}

const Container = styled.div`
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 20px;
`;

const Title = styled.p`
  font-size: 15px;
  font-weight: 500;
`;

const LinkContainer = styled.div`
  border: 1px solid #00a680;
  border-radius: 10px;
  padding: 15px 20px;
  margin-top: 15px;
`;

const Link = styled.p`
  text-align: center;
  font-size: 13px;
`;

const List = styled.div``;

const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 0px;
  padding-left: 10px;
  cursor: pointer;
  :hover {
    background-color: rgba(155, 155, 155, 0.3);
  }
`;

const OptionTitle = styled.p`
  flex: 1;
  font-size: 13px;
  margin-left: 6px;
`;
