import React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";

export default function ImproveSales() {
  return (
    <Container>
      <Lottie
        options={{
          animationData: require("../../../images/improveSales.json"),
        }}
        height={410}
        width={410}
      />
      <Text>¿Estás listo/a para llevar tu </Text>
      <Text>
        <Highlight>negocio/emprendimiento al máximo nivel?</Highlight>
      </Text>
    </Container>
  );
}

const Container = styled.div`
  width: calc(100vw - 600px);
  height: 100%;
  background-color: #246bfd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.p`
  color: white;
  font-size: 2.1rem;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Text = styled.p`
  margin: 0px 55px;
  color: white;
  font-weight: bold;
  font-size: 1.4rem;
  text-align: center;
`;

const Highlight = styled.span`
  color: #ffde59;
  font-weight: bold;
  font-size: 1.4rem;
`;
