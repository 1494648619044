import Close from "@mui/icons-material/Close";
import {
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Radio,
} from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import InputWithLabel from "../../login/InputWithLabel";
import CustomDivider from "../../global/CustomDivider";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../context/store";
import { validationLinkEmbeddedYouTube } from "../../../utils/validationLinkEmbeddedYouTube";
import { validationVimeoLink } from "../../../utils/validationVimeoLink";
import { collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import {
  setGeneralSnackBarMessage,
  setShowGeneralSnackbar,
} from "../../../context/reducers/layout";
import LockIcon from "@mui/icons-material/Lock";

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  videoUrl: string;
  setVideoUrl?: React.Dispatch<React.SetStateAction<string>>;
  productId?: string;
}

const YOUTUBE = "YOUTUBE";
const VIMEO = "VIMEO";

export default function VideoModal({
  isVisible,
  setIsVisible,
  setVideoUrl,
  videoUrl,
  productId,
}: Props) {
  const selectedBusiness = useSelector(
    (state: RootState) => state.layout.selectedBusiness
  );
  const userIsPro = useSelector((state: RootState) => state.layout.userIsPro);
  const [formError, setFormError] = useState<any>({});
  const [newUrl, setNewUrl] = useState(productId == undefined ? "" : videoUrl);
  const [selectedPlatform, setSelectedPlatform] = useState(YOUTUBE);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const closeModal = () => {
    setIsVisible(false);
    if (productId !== undefined) {
      setUnsavedChanges(false);
    }
  };

  const updateProductVideo = () => {
    setLoading(true);
    const productsCollection = collection(
      db,
      "business",
      selectedBusiness?.id,
      "products"
    );
    const productDoc = doc(productsCollection, productId);

    updateDoc(productDoc, {
      videoUrl: newUrl !== "" ? newUrl : null,
    })
      .then(() => {
        dispatch(
          setShowGeneralSnackbar({
            showSnackbar: true,
          })
        );
        dispatch(
          setGeneralSnackBarMessage({
            snackBarMessage: "Video actualizado correctamente",
          })
        );
        setLoading(false);
        setIsVisible(false);
        setUnsavedChanges(false);
      })
      .catch(() => {
        dispatch(
          setShowGeneralSnackbar({
            showSnackbar: true,
          })
        );
        dispatch(
          setGeneralSnackBarMessage({
            snackBarMessage: "Ocurrió un error al intentar actualizar el video",
          })
        );
        setLoading(false);
      });
  };

  const handleSave = () => {
    if (!userIsPro) return;
    let errors: any = {};
    if (newUrl?.trim() === "") {
      setUnsavedChanges(false);
      setIsVisible(false);
      if (setVideoUrl !== undefined) {
        setVideoUrl("");
      }
    } else if (
      !validationLinkEmbeddedYouTube(newUrl.trim()) &&
      !validationVimeoLink(newUrl.trim())
    ) {
      errors.url = true;
      errors.urlMessage =
        "Coloca un link embebido válido de Youtube o Vimeo. Tip: Sigue los pasos que están abajo";
    } else {
      if (productId !== undefined) {
        updateProductVideo();
      } else {
        setUnsavedChanges(false);
        setIsVisible(false);
        if (setVideoUrl !== undefined) {
          setVideoUrl(newUrl?.trim());
        }
      }
    }
    setFormError(errors);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPlatform(event.target.value);
  };

  return (
    <CustomDrawer
      open={isVisible}
      onClose={closeModal}
      anchor="right"
      variant="temporary"
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Container>
        <Header>
          <Title>Añade un link de Youtube o Vimeo para este producto</Title>
          <IconButton onClick={closeModal}>
            <Close />
          </IconButton>
        </Header>
        <Description>
          📹 Añade videos a tus productos de tu tienda virtual y cautiva a tus
          clientes con demostraciones increíbles! 🌟
        </Description>
        <Content>
          <div style={{ marginTop: "5px" }} />
          <InputWithLabel
            label=""
            placeholder="Link de Youtube o Vimeo"
            error={formError?.url}
            value={newUrl}
            setValue={setNewUrl}
            setUnsavedChanges={setUnsavedChanges}
          />
          {formError?.url && formError?.urlMessage && (
            <ErrorText>{formError?.urlMessage}</ErrorText>
          )}
          <StyledButton
            disabled={!unsavedChanges || !userIsPro}
            style={{
              background: !unsavedChanges || !userIsPro ? "#c1c1c1" : "#00a680",
              marginTop: "18px",
              borderRadius: "10px",
            }}
            onClick={handleSave}
            startIcon={
              !userIsPro ? <LockIcon style={{ color: "#00a680" }} /> : <></>
            }
          >
            {loading ? (
              <CircularProgress style={{ color: "white" }} size={22} />
            ) : (
              <StyledButtonText
                style={{ color: !userIsPro ? "gray" : "white" }}
              >
                {productId == undefined ? "Aceptar" : "Guardar cambios"}
              </StyledButtonText>
            )}
          </StyledButton>
          <CustomDivider marginTop={14} marginBottom={15} />
        </Content>
        <Description>
          A continuación puedes ver los pasos a seguir para copiar el link de tu
          video de Youtube o de Vimeo:
        </Description>
        <Content>
          <Row
            style={{
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Row>
              <PlatformText>Youtube</PlatformText>
              <Radio
                checked={selectedPlatform === YOUTUBE}
                onChange={handleChange}
                value={YOUTUBE}
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
                style={{
                  color: "#00a680",
                }}
              />
            </Row>
            <Row>
              <PlatformText>Vimeo</PlatformText>
              <Radio
                checked={selectedPlatform === VIMEO}
                onChange={handleChange}
                value={VIMEO}
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
                style={{
                  color: "#00a680",
                }}
              />
            </Row>
          </Row>
          {selectedPlatform == YOUTUBE ? (
            <Steps>
              <Image src={require("../../../images/stepsYoutube/step1.png")} />
              <Image src={require("../../../images/stepsYoutube/step2.png")} />
              <Image src={require("../../../images/stepsYoutube/step3.png")} />
            </Steps>
          ) : (
            <Steps>
              <Image src={require("../../../images/stepsVimeo/step1.png")} />
              <Image src={require("../../../images/stepsVimeo/step2.png")} />
            </Steps>
          )}
        </Content>
      </Container>
    </CustomDrawer>
  );
}

const CustomDrawer = styled(Drawer)``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  height: 100%;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 20px;
  margin-top: 20px;
  gap: 10px;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 16px;
  flex: 1;
`;

const Description = styled.p`
  font-size: 14px;
  margin-top: 2px;
  padding: 0px 20px;
`;

const Content = styled.div`
  padding: 0px 20px;
`;

const StyledButton = styled(Button)`
  background-color: #00a680;
  padding: 10px 30px;
  width: 100%;
`;

const StyledButtonText = styled.p`
  text-transform: none;
  &&& {
    font-family: "Montserrat";
    color: white;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const PlatformText = styled.p``;

const Steps = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const Image = styled.img``;

const ErrorText = styled.p`
  color: red;
  font-size: 12px;
  margin-top: 12px;
`;
